<template>
  <div id="assets-pie"></div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "AssetsPie",
  props: {
    assets: Object,
    width: {
      type: Number,
      default: 500,
    },
  },
  computed: {
    data: function() {
      return Object.keys(this.assets).map((key) => {
        return { asset: key, amount: parseFloat(this.assets[key]) };
      });
    },
  },
  methods: {
    drawPie: function() {
      const height = Math.min(this.width, 500);

      const radius = (Math.min(this.width, height) / 2) * 0.8;
      const arcLabel = d3
        .arc()
        .innerRadius(radius)
        .outerRadius(radius);

      const arc = d3
        .arc()
        .innerRadius(0)
        .outerRadius(Math.min(this.width, height) / 2 - 1);

      const color = d3
        .scaleOrdinal()
        .domain(this.data.map((d) => d.asset))
        .range(
          d3
            .quantize(
              (t) => d3.interpolateSpectral(t * 0.8 + 0.1),
              this.data.length
            )
            .reverse()
        );

      const pie = d3
        .pie()
        .sort(null)
        .value((d) => d.amount);

      const arcs = pie(this.data);

      const svg = d3
        .select("#assets-pie")
        .append("svg")
        .attr("width", "200px")
        .attr("height", "200px")
        .attr("viewBox", [-this.width / 2, -height / 2, this.width, height]);

      svg
        .append("g")
        .attr("stroke", "white")
        .selectAll("path")
        .data(arcs)
        .join("path")
        .attr("fill", (d) => color(d.data.asset))
        .attr("d", arc)
        .append("title")
        .text((d) => `${d.data.asset}: ${d.data.amount}`);

      svg
        .append("g")
        .attr("font-family", "sans-serif")
        .attr("font-size", 14)
        .attr("text-anchor", "middle")
        .selectAll("text")
        .data(arcs)
        .join("text")
        .attr("transform", (d) => `translate(${arcLabel.centroid(d)})`)
        .call((text) =>
          text
            .append("tspan")
            .attr("y", "-0.4em")
            .attr("font-weight", "bold")
            .text((d) => d.data.asset)
        )
        .call((text) =>
          text
            .filter((d) => d.endAngle - d.startAngle > 0.25)
            .append("tspan")
            .attr("x", 0)
            .attr("y", "0.7em")
            .attr("fill-opacity", 0.7)
            .text((d) => d.data.amount)
        );
    },
  },
  mounted: function() {
    this.drawPie();
  },
};
</script>

<style></style>
