<template>
  <div class="e-selector">
    <div>
      <span
        v-for="item in options"
        v-bind:key="item.key"
        v-on:click="setSelected(item.slug)"
        :class="{ selected: value == item.slug, disabled: item.disabled }"
        >{{ item.title }}</span
      >
    </div>
    <small v-if="description" class="description">{{ description }}</small>
  </div>
</template>

<script>
export default {
  name: "PlainSelector",
  props: {
    options: Array,
    value: String /* [{slug, title, description, disabled}] */,
  },
  computed: {
    description: function() {
      let res = null;
      this.options.forEach(
        function(v) {
          if (v.slug == this.value) {
            res = v.description;
          }
        }.bind(this)
      );
      return res;
    },
  },
  methods: {
    setSelected: function(slug) {
      const selectedItem = this.options.find((i) => i.slug == slug);
      if (selectedItem.disabled) {
        return;
      }
      if (slug == this.value) {
        return;
      }
      this.$emit("input", slug);
    },
  },
};
</script>

<style></style>
