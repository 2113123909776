<template>
  <div>
    Trade
    <b-link :to="`/trades/${data.trade_token}`">{{ data.trade_token }}</b-link>
    on symbol
    <b-link :to="`/symbols/${data.symbol_slug}`"
      >{{ data.symbol_base }}{{ data.symbol_quote }}</b-link
    >
    completed
  </div>
</template>

<script>
export default {
  name: "TradeDoneActivity",
  props: {
    data: Object,
  },
};
</script>

<style></style>
