<template>
  <div class="trade-status">
    <status-indicator :trade="trade" />
    <timestamp :ts="trade.created" class="ts indicator">
      <template v-slot:default="slotProps">
        <span>Created</span>
        {{ slotProps.val.ago }}
        <div class="dt">{{ slotProps.val.dt }}</div>
      </template>
    </timestamp>
    <profit-indicator :state="trade.state" />
    <trade-summary :state="trade.state" :symbol="symbol" :is-long="isLong" />
  </div>
</template>

<script>
import Timestamp from "../lib/Timestamp";
import StatusIndicator from "../lib/StatusIndicator";
import ProfitIndicator from "../lib/ProfitIndicator";
import TradeSummary from "./TradeSummary";

export default {
  name: "TradeStatus",
  props: {
    trade: Object,
    symbol: Object,
  },
  components: {
    Timestamp,
    StatusIndicator,
    ProfitIndicator,
    TradeSummary,
  },
  computed: {
    isLong: function() {
      return this.trade.state.meta["is_long"];
    },
  },
};
</script>

<style></style>
