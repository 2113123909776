<template>
  <div :id="'trade-line-' + trade.trade_token" class="trade-line"></div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "TradeLine",
  props: {
    trade: Object,
    lineWidth: {
      type: Number,
      default: 0,
    },
  },
  data: function() {
    return {
      width: 200,
      height: 10,
      paddingY: 5,
      paddingX: 5,
    };
  },
  watch: {
    points: function() {
      this.drawLine();
    },
    ask: function() {
      this.drawLine();
    },
    lineWidth: function(newValue, oldValue) {
      if (newValue) {
        this.width = newValue - this.paddingX * 2;
      } else {
        this.width = 200;
      }
      this.drawLine();
    },
  },
  computed: {
    ask: function() {
      return this.trade.state.meta && parseFloat(this.trade.state.meta.ask);
    },
    bid: function() {
      return this.trade.state.meta && parseFloat(this.trade.state.meta.bid);
    },
    minPrice: function() {
      return this.trade.state.meta && parseFloat(this.trade.state.meta.min_bid);
    },
    maxPrice: function() {
      return this.trade.state.meta && parseFloat(this.trade.state.meta.max_ask);
    },
    points: function() {
      const state = this.trade.state;
      let points = [];
      if (state.entries && state.entries.length) {
        state.entries.forEach(function(section) {
          if (section.type == "use") {
            points.push([parseFloat(section.price), "ENF"]);
          } else if (section.order && section.order.status == "FILLED") {
            points.push([
              parseFloat(section.order.filled_quote) /
                parseFloat(section.order.filled_base),
              "ENF",
            ]);
          } else {
            if (section.price) {
              points.push([parseFloat(section.price), "EN"]);
            } else if (state.options && state.options.start_price) {
              points.push([parseFloat(state.options.start_price), "EN"]);
            }
          }
        });
      }
      if (state.entry) {
        if (state.entry.order && state.entry.order.status == "FILLED") {
          points.push([
            parseFloat(state.entry.order.filled_quote) /
              parseFloat(state.entry.order.filled_base),
            "ENF",
          ]);
        } else {
          points.push([parseFloat(state.entry.price), "EN"]);
        }
      }
      if (state.exits && state.exits.length) {
        state.exits.forEach(function(section) {
          if (section.type != "inf") {
            if (section.order && section.order.status == "FILLED") {
              points.push([
                parseFloat(section.order.filled_quote) /
                  parseFloat(section.order.filled_base),
                "EXF",
              ]);
            } else {
              points.push([parseFloat(section.price), "EX"]);
            }
          }
        });
      }
      if (state.targets && state.targets.length) {
        state.targets.forEach(function(section) {
          if (section.done) {
            points.push([parseFloat(section.price), "EXF"]);
          } else {
            points.push([parseFloat(section.price), "EX"]);
          }
        });
      }
      if (state.meta && state.meta.stop) {
        points.push([parseFloat(state.meta.stop), "S"]);
      }
      if (state.options && state.options.start_price) {
        points.push([parseFloat(state.options.start_price), "ST"]);
      }
      if (state.options && state.options.cancel_price) {
        points.push([parseFloat(state.options.cancel_price), "CL"]);
      }
      [state.rescue, state.cancel, state.stop].forEach(
        function(section) {
          if (section && section.order && section.order.status == "FILLED") {
            points.push([
              parseFloat(section.order.filled_quote) /
                parseFloat(section.order.filled_base),
              "CF",
            ]);
          }
        }.bind(this)
      );
      return points;
    },

    prices: function() {
      var result = [];
      if (this.bid) {
        result.push(this.bid);
      }
      if (this.ask) {
        result.push(this.ask);
      }
      if (this.minPrice) {
        result.push(this.minPrice);
      }
      if (this.maxPrice) {
        result.push(this.maxPrice);
      }
      this.points.forEach((i) => {
        result.push(i[0]);
      });
      return result;
    },
  },
  methods: {
    drawLine: function() {
      d3.select(`#trade-line-${this.trade.trade_token}`)
        .select("svg")
        .remove();
      const svg = d3
        .select(`#trade-line-${this.trade.trade_token}`)
        .append("svg")
        .attr("width", this.lineWidth ? this.lineWidth + "px" : "100%")
        .attr("height", this.height + this.paddingY * 2 + "px")
        .attr(
          "viewBox",
          `0 0 ${this.width + this.paddingX * 2} ${this.height +
            this.paddingY * 2}`
        );

      var scale = d3
        .scaleLinear()
        .domain([d3.min(this.prices), d3.max(this.prices)])
        .range([0, this.width]);

      svg
        .append("rect")
        .attr("x", this.paddingX)
        .attr("y", this.paddingY)
        .attr("width", this.width)
        .attr("height", this.height)
        .attr("fill", "#444");

      if (this.minPrice && this.maxPrice) {
        svg
          .append("rect")
          .attr("x", this.paddingX + scale(this.minPrice))
          .attr("y", this.paddingY)
          .attr("width", scale(this.maxPrice) - scale(this.minPrice))
          .attr("height", this.height)
          .attr("fill", "#888");
      }

      const g = svg.append("g");

      this.points.forEach(
        function(i) {
          if (i[1] == "S") {
            svg
              .append("line")
              .attr("x1", this.paddingX + scale(i[0]))
              .attr("y1", this.paddingY)
              .attr("x2", this.paddingX + scale(i[0]))
              .attr("y2", this.paddingY + this.height)
              .attr("stroke-width", 2)
              .attr("stroke", "#f94144ff");
          } else if (i[1] == "ENF") {
            svg
              .append("line")
              .attr("x1", this.paddingX + scale(i[0]))
              .attr("y1", this.paddingY)
              .attr("x2", this.paddingX + scale(i[0]))
              .attr("y2", this.paddingY + this.height)
              .attr("stroke-width", 3)
              .attr("stroke", "#f8961eff");
          } else if (i[1] == "ENL") {
            svg
              .append("line")
              .attr("x1", this.paddingX + scale(i[0]))
              .attr("y1", this.paddingY)
              .attr("x2", this.paddingX + scale(i[0]))
              .attr("y2", this.paddingY + this.height)
              .attr("stroke-width", 1)
              .attr("stroke", "#f8961eff");
          } else if (i[1] == "EN") {
            svg
              .append("line")
              .attr("x1", this.paddingX + scale(i[0]))
              .attr("y1", this.paddingY)
              .attr("x2", this.paddingX + scale(i[0]))
              .attr("y2", this.paddingY + this.height)
              .attr("stroke-width", 2)
              .attr("stroke", "#f8961eff");
          } else if (i[1] == "EXF") {
            /* exit filled */
            svg
              .append("line")
              .attr("x1", this.paddingX + scale(i[0]))
              .attr("y1", this.paddingY)
              .attr("x2", this.paddingX + scale(i[0]))
              .attr("y2", this.paddingY + this.height)
              .attr("stroke-width", 3)
              .attr("stroke", "#43aa8bff");
          } else if (i[1] == "EXL") {
            /* exit line */
            svg
              .append("line")
              .attr("x1", this.paddingX + scale(i[0]))
              .attr("y1", this.paddingY)
              .attr("x2", this.paddingX + scale(i[0]))
              .attr("y2", this.paddingY + this.height)
              .attr("stroke-width", 1)
              .attr("stroke", "#43aa8bff");
          } else if (i[1] == "EX") {
            /* exit */
            svg
              .append("line")
              .attr("x1", this.paddingX + scale(i[0]))
              .attr("y1", this.paddingY)
              .attr("x2", this.paddingX + scale(i[0]))
              .attr("y2", this.paddingY + this.height)
              .attr("stroke-width", 2)
              .attr("stroke", "#43aa8bff");
          } else if (i[1] == "CF") {
            /* cancel filled */
            svg
              .append("line")
              .attr("x1", this.paddingX + scale(i[0]))
              .attr("y1", this.paddingY)
              .attr("x2", this.paddingX + scale(i[0]))
              .attr("y2", this.paddingY + this.height)
              .attr("stroke-width", 3)
              .attr("stroke", "#f94144ff");
          } else if (i[1] == "ST") {
            /* Start */
            svg
              .append("line")
              .attr("x1", this.paddingX + scale(i[0]))
              .attr("y1", this.paddingY)
              .attr("x2", this.paddingX + scale(i[0]))
              .attr("y2", this.paddingY + this.height)
              .attr("stroke-width", 1)
              .attr("stroke", "#1111ffdd");
          } else if (i[1] == "CL") {
            /* Cancel */
            svg
              .append("line")
              .attr("x1", this.paddingX + scale(i[0]))
              .attr("y1", this.paddingY)
              .attr("x2", this.paddingX + scale(i[0]))
              .attr("y2", this.paddingY + this.height)
              .attr("stroke-width", 1)
              .attr("stroke", "#1111ffdd");
          }
        }.bind(this)
      );

      if (this.bid) {
        svg
          .append("line")
          .attr("x1", this.paddingX + scale(this.bid))
          .attr("y1", this.paddingY)
          .attr("x2", this.paddingX + scale(this.bid))
          .attr("y2", this.paddingY + this.height)
          .attr("stroke-width", 1)
          .attr("stroke", "white");
      }
      if (this.ask) {
        svg
          .append("line")
          .attr("x1", this.paddingX + scale(this.ask))
          .attr("y1", this.paddingY)
          .attr("x2", this.paddingX + scale(this.ask))
          .attr("y2", this.paddingY + this.height)
          .attr("stroke-width", 1)
          .attr("stroke", "white");
      }
    },
  },
  mounted: function() {
    if (this.lineWidth) {
      this.width = this.lineWidth - this.paddingX * 2;
    } else {
      this.width = 200;
    }
    this.drawLine();
  },
};
</script>

<style></style>
