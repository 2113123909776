<template>
  <div class="indicator profit" v-if="state.meta">
    <span>PnL</span>
    {{ state.meta.profit ? state.meta.profit + ' ' + state.quote.toUpperCase() : "N/A" }}
  </div>
</template>

<script>
export default {
  name: "ProfitIndicator",
  props: {
    state: Object,
  },
  computed: {},
};
</script>

<style></style>
