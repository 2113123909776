<template>
  <b-form-textarea
    id="textarea"
    :value="value"
    v-on:input="onUpdate"
    placeholder="Your Markdown here"
  />
</template>

<script>
import { BFormTextarea } from "bootstrap-vue";
export default {
  name: "MdEditor",
  props: {
    value: String,
  },
  components: {
    BFormTextarea,
  },
  methods: {
    onUpdate: function(val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    this._keyListener = function(e) {
      if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault(); // present "Save Page" from getting triggered.

        this.$emit("save");
      }
    };

    document.addEventListener("keydown", this._keyListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this._keyListener);
  },
};
</script>

<style></style>
