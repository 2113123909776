<template>
  <div>
    <nothing-found v-if="!data.length" />
    <template v-else>
      <div id="historical-balance"></div>
    </template>
  </div>
</template>

<script>
import * as d3 from "d3";
import { BFormRadioGroup } from "bootstrap-vue";
import NothingFound from "./NothingFound";

export default {
  name: "HistoricalBalanceChart",
  components: { NothingFound, BFormRadioGroup },
  props: {
    days: Array,
    width: {
      default: 500,
    },
    height: {
      default: 200,
    },
  },
  data: function() {
    return {};
  },
  computed: {
    data: function() {
      const parseDate = d3.utcParse("%Y-%m-%d");
      return this.days
        .filter((i) => i[1])
        .map((i) => [
          parseDate(i[0]),
          parseInt(i[1]),
          parseInt(i[2]),
        ]);
    },
  },
  methods: {
    drawChart: function() {
      if (!this.data.length) {
        return;
      }

      const width = this.width;
      const height = this.height;

      const margin = { top: 20, right: 20, bottom: 20, left: 40 };

      const x = d3
        .scaleBand()
        .domain(
          d3.utcDay.range(
            this.data[0][0],
            +this.data[this.data.length - 1][0] + 1
          )
        )
        .range([margin.left, width - margin.right])
        .padding(1);

      const y = d3
        .scaleLinear()
        .domain([
          d3.min(this.data, (d) => d[1]),
          d3.max(this.data, (d) => d[2]),
        ])
        .rangeRound([height - margin.bottom, margin.top]);

      const yAxis = (g) =>
        g
          .attr("transform", `translate(${margin.left},0)`)
          .call(
            d3
              .axisLeft(y)
              .tickFormat(d3.format("~f"))
              .tickValues(
                d3
                  .scaleLinear()
                  .domain(y.domain())
                  .ticks()
              )
          )
          .call((g) =>
            g
              .selectAll(".tick line")
              .clone()
              .attr("stroke-opacity", 0.2)
              .attr("x2", width - margin.left - margin.right)
          )
          .call((g) => g.select(".domain").remove());

      const xAxis = (g) =>
        g
          .attr("transform", `translate(0,${height - margin.bottom})`)
          .call(
            d3
              .axisBottom(x)
              .tickFormat(d3.utcFormat("%-m/%-d"))
              .tickValues(
                x.domain().filter(function(d, i) {
                  return !(i % 7);
                })
              )
          )
          .call((g) => g.select(".domain").remove());

      d3.select("#historical-balance")
        .select("svg")
        .remove();

      const svg = d3
        .select("#historical-balance")
        .append("svg")
        .attr("width", `${width}px`)
        .attr("height", `${height}px`)
        .attr("viewBox", [0, 0, this.width, height]);

      svg.append("g").call(xAxis);

      svg.append("g").call(yAxis);

      const g = svg
        .append("g")
        .selectAll("g")
        .data(this.data)
        .join("g")
        .attr("transform", (d) => `translate(${x(d[0])},0)`);

      g.append("line")
        .attr("y1", (d) => y(d[1]))
        .attr("y2", (d) => y(d[2]))
        .attr("stroke-linecap", "square")
        .attr("stroke-width", 4)
        .attr("stroke", "white");
    },
  },
  mounted: function() {
    this.drawChart();
  },
};
</script>

<style></style>
