<template>
  <div class="forms-row">
    <b-card header="Balance">
      <spinner v-if="!loaded" />
      <template v-else>
        <h3>{{ total_usd }} USD</h3>
      </template>
    </b-card>
    <b-card header="Balance daily">
      <spinner v-if="!loaded" />
      <historical-balance-chart :days="history" v-else />
    </b-card>
    <b-card header="PnL daily">
      <spinner v-if="!loaded" />
      <historical-pnl-chart :days="history" v-else />
    </b-card>
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { Decimal } from "decimal.js";
import AssetsPie from "../components/lib/AssetsPie";
import HistoricalBalanceChart from "../components/lib/HistoricalBalanceChart";
import HistoricalPnlChart from "../components/lib/HistoricalPnlChart";
import Spinner from "../components/lib/Spinner";

export default {
  name: "Account",
  components: {
    BCard,
    AssetsPie,
    HistoricalBalanceChart,
    HistoricalPnlChart,
    Spinner,
  },
  data: function() {
    return {
      total_usd: 0,
      assets: null,
      history: null,
      loaded: false,
    };
  },
  mounted: function() {
    this.$http
      .get("/api/balances")
      .then(
        function(response) {
          this.total_usd = new Decimal(response.data.total_usd)
            .toSD(6)
            .toFixed();
          this.assets = response.data.assets;
          this.history = response.data.history;
          this.loaded = true;
        }.bind(this)
      )
      .catch(
        function(reason) {
          console.log(reason);
        }.bind(this)
      );
  },
};
</script>

<style></style>
