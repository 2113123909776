import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/Login";
import SymbolsView from "../views/Symbols";
import SettingsView from "../views/Settings";
import AccountView from "../views/Account";
import SymbolView from "../views/Symbol";
import TradeView from "../views/Trade";
import TradesView from "../views/Trades";
import ActivityView from "../views/Activity";
import PagesView from "../views/Pages";
import PageView from "../views/Page";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "activity",
    component: ActivityView,
    meta: {
      private: true,
    },
  },
  {
    path: "/symbols",
    name: "symbols",
    component: SymbolsView,
    meta: {
      private: true,
    },
  },
  {
    path: "/symbols/:slug?",
    name: "symbol",
    component: SymbolView,
    meta: {
      private: true,
    },
  },
  {
    path: "/trades",
    name: "trades",
    component: TradesView,
    meta: {
      private: true,
    },
  },
  {
    path: "/trades/:slug?",
    name: "trade",
    component: TradeView,
    meta: {
      private: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
    meta: {
      private: true,
    },
  },
  {
    path: "/account",
    name: "account",
    component: AccountView,
    meta: {
      private: true,
    },
  },
  {
    path: "/pages",
    name: "pages",
    component: PagesView,
    meta: {
      private: true,
    },
  },
  {
    path: "/pages/:pageId?",
    name: "page",
    component: PageView,
    meta: {
      private: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      guest: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.private)) {
    if (localStorage.getItem("accessToken") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("accessToken") == null) {
      next();
    } else {
      next({ name: "/" });
    }
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  NProgress.done();
});

export default router;
