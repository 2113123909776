<template>
  <div>
    <div v-if="data.trade_token">
      Trade
      <b-link :to="`/trades/${data.trade_token}`" v-if="data.trade_token">{{
        data.trade_token
      }}</b-link>
      <b-icon-pencil-fill
        style="margin-left: 1em; cursor: pointer;"
        v-on:click="onEditNote({ tradeToken: data.trade_token })"
        :scale="1"
      />
    </div>
    <div v-else>
      Symbol
      <b-link :to="`/symbols/${data.symbol_slug}`" v-if="data.symbol_slug">{{
        data.symbol_slug.toUpperCase()
      }}</b-link>
      <b-icon-pencil-fill
        style="margin-left: 1em; cursor: pointer;"
        v-on:click="onEditNote({ symbolSlug: data.symbol_slug })"
        :scale="1"
      />
    </div>
    <div class="activity-note">{{ data.text }}</div>
  </div>
</template>

<script>
import NoteEditor from "../lib/NoteEditor";

export default {
  name: "NoteActivity",
  props: {
    data: Object,
  },
  components: {
    NoteEditor,
  },
  methods: {
    onEditNote: function(q) {
      let newNote = null;
      this.$bvModal
        .msgBoxConfirm(
          [
            this.$createElement("note-editor", {
              props: q,
              on: {
                change: function(value) {
                  newNote = value;
                },
              },
            }),
          ],
          {
            title: `Edit notes for ${
              q.symbolSlug ? q.symbolSlug.toUpperCase() : q.tradeToken
            }`,
            okTitle: "Save",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
            id: `note-edit-${q.symbolSlug || q.tradeToken}`,
          }
        )
        .then((value) => {
          if (value && newNote) {
            NProgress.start();
            this.$http
              .put("/api/notes", newNote)
              .then(
                function(response) {
                  this.$emit("updateNote", newNote);
                }.bind(this)
              )
              .catch(
                function(reason) {
                  let errors = "Internal server error.";
                  if (
                    reason.response.status == 422 ||
                    reason.response.status == 400
                  ) {
                    errors = JSON.stringify(reason.response.data, null, 2);
                  }
                  const h = this.$createElement;
                  const $errors = h("pre", {}, errors);
                  this.$bvToast.toast([$errors], {
                    title: "Error saving note",
                    variant: "danger",
                    autoHideDelay: 6000,
                    toastClass: "mt-4",
                  });
                }.bind(this)
              )
              .finally(
                function() {
                  NProgress.done();
                }.bind(this)
              );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
