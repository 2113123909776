<template>
  <div>
    <b-link :to="`/symbols/${data.symbol_slug}`">{{
      data.symbol_slug.toUpperCase()
    }}</b-link>
    at {{ data.price }}
    <b-icon-trash
      v-on:click="onRemoveTarget(data.alert_id)"
      style="cursor: pointer;"
    />
    <div class="activity-note" v-if="data.message">{{ data.message }}</div>
  </div>
</template>

<script>
export default {
  name: "ActiveAlert",
  props: {
    data: Object,
  },
  methods: {
    onRemoveTarget: function(alertId) {
      NProgress.start();
      this.$http
        .delete(`/api/alerts/${alertId}`)
        .then(
          function(response) {
            this.$emit("removeAlert", alertId);
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        )
        .finally(
          function() {
            NProgress.done();
          }.bind(this)
        );
    },
  },
};
</script>

<style></style>
