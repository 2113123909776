<template>
  <span>
    <svg viewBox="0 0 36 36" width="1em" style="position: relative; top: -2px;">
      <path
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke="#444"
        stroke-width="2"
        stroke-dasharray="100 100"
      />
      <path
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke="#aaa"
        stroke-width="4"
        :stroke-dasharray="percents + ' 100'"
      />
    </svg>
    <span class="ml-1">{{ percents.toFixed() }}%</span>
  </span>
</template>

<script>
import { Decimal } from "decimal.js";

export default {
  name: "PercentCircle",
  props: {
    percents: Decimal,
  },
};
</script>

<style></style>
