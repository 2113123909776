<template>
  <div>
    Order filled for
    <b-link :to="`/trades/${data.trade_token}`">{{ data.trade_token }}</b-link>
    on symbol
    <b-link :to="`/symbols/${data.symbol_slug}`"
      >{{ data.symbol_base }}{{ data.symbol_quote }}</b-link
    >
    at {{ data.data.section }}:
    <div class="detail-row">
      {{ data.data.type }} {{ data.data.side }} {{ data.data.quantity }} @
      {{ data.data.price }}
    </div>
    <div class="detail-row">
      Filled {{ data.data.filled_quote }} {{ data.symbol_quote }} /
      {{ data.data.filled_base }} {{ data.symbol_base }}
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderFilledActivity",
  props: {
    data: Object,
  }
};
</script>

<style></style>
