<template>
  <div class="trade-form" v-if="targets">
    <h2>Targets</h2>
    <div
      class="trade-row"
      v-for="(item, index) in targets"
      v-bind:key="'tg' + index"
    >
      <span class="left-action"
        ><b-icon-trash v-on:click="onRemoveTarget(index)"
      /></span>
      <span class="number">#{{ index + 1 }}</span>
      <span class="content"
        >{{ getComparator(item.cmp) }} {{ item.price }}</span
      >
      <small class="ml-1">{{ item.message }}</small>
    </div>
    <div class="trade-row" v-if="!showEditor">
      <span class="left-action"></span>
      <span class="number"
        ><b-icon-plus-circle v-on:click="onShowEditor"
      /></span>
    </div>
    <div class="form-row-edit mt-2" v-if="showEditor">
      <div class="edit-header">
        <span v-on:click="onHideEditor">New Target</span>
        <b-icon-chevron-up v-on:click="onHideEditor" />
      </div>
      <decimal-input
        title="Price"
        :value="price"
        v-on:input="onChange"
        :unit="`${symbol.quote} / ${symbol.base}`"
        :step="priceStep"
      />
      <small class="mt-1" style="color: #aaa;">Message</small>
      <b-form-input v-model="message" size="sm" />
      <b-button size="sm" class="mt-2" v-on:click="onSave">Add</b-button>
    </div>
  </div>
</template>

<script>
import DecimalInput from "./lib/DecimalInput";
import { BButton, BFormInput } from "bootstrap-vue";
import PlainSelector from "./lib/PlainSelector";
import Decimal from "decimal.js";

export default {
  name: "AlertsCreator",
  props: {
    symbol: Object,
    value: Object,
  },
  components: {
    DecimalInput,
    PlainSelector,
    BFormInput,
    BButton,
  },
  data: function() {
    return {
      targets: null,
      price: null,
      showEditor: false,
      message: "",
    };
  },
  watch: {
    value: function(newValue, oldValue) {
      if (!newValue || !newValue.targets || !newValue.targets.length) {
        return;
      }
      if (this.showEditor) {
        this.price = newValue.targets[newValue.targets.length - 1].price
          .div(this.priceStep)
          .round()
          .mul(this.priceStep);
      }
    },
  },
  computed: {
    priceStep: function() {
      return this.symbol.pf_tick_size;
    },
    currentTargets: function() {
      if (!this.showEditor) {
        return {
          targets: [...this.targets],
        };
      }
      return {
        targets: [
          ...this.targets.map((i) => {
            return {
              ...i,
              price: new Decimal(i.price),
            };
          }),
          {
            price: this.price,
            editing: true,
          },
        ],
      };
    },
  },
  methods: {
    getComparator: function(cmp) {
      if (cmp == "gt") {
        return "ask >";
      } else if (cmp == "gte") {
        return "ask >=";
      } else if (cmp == "lt") {
        return "bid <";
      } else if (cmp == "lte") {
        return "bid <=";
      }
    },
    onRemoveTarget: function(index) {
      const alertId = this.targets[index].alert_id;
      this.$http
        .delete(`/api/alerts/${alertId}`)
        .then(
          function(response) {
            this.targets = [
              ...this.targets.filter((i) => i.alert_id != alertId),
            ];
            this.$emit("input", this.currentTargets);
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        );
    },
    onShowEditor: function() {
      this.price = this.symbol.ticker.ask;
      this.showEditor = true;
      this.$emit("input", this.currentTargets);
    },
    onHideEditor: function() {
      this.showEditor = false;
      this.$emit("input", this.currentTargets);
    },
    onSave: function() {
      this.$http
        .post(`/api/symbols/${this.symbol.slug}/alerts`, {
          price: this.price.toFixed(),
          cmp: this.price.gt(this.symbol.ticker.ask) ? "gte" : "lte",
          message: this.message,
        })
        .then(
          function(response) {
            this.showEditor = false;
            this.message = "";
            this.targets = response.data.results;
            this.$emit("input", this.currentTargets);
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        );
    },
    onChange: function(price) {
      this.price = price;
      this.$emit("input", this.currentTargets);
    },
  },
  mounted: function() {
    this.$http
      .get(`/api/symbols/${this.symbol.slug}/alerts`)
      .then(
        function(response) {
          this.targets = response.data.results;
          this.$emit("input", this.currentTargets);
        }.bind(this)
      )
      .catch(
        function(reason) {
          console.log(reason);
        }.bind(this)
      );
  },
};
</script>

<style></style>
