<template>
  <div>
    <vue-tags-input
      v-model="tag"
      :tags="tags"
      v-on:tags-changed="onChange"
      :autocomplete-items="autocompleteItems"
    />
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  name: "SymbolTags",
  props: {
    currentTags: Array,
    existingTags: Array,
  },
  data: function() {
    return {
      tag: "",
      tags: [],
    };
  },
  components: {
    VueTagsInput,
  },
  computed: {
    autocompleteItems: function() {
      return this.existingTags
        .filter((i) => !this.tags.includes(i))
        .map((i) => {
          return {
            text: i,
          };
        });
    },
  },
  methods: {
    onChange: function(value) {
      this.tags = value;
      this.$emit("change", this.tags.map((i) => i.text).sort());
    },
  },
  mounted: function() {
    this.tags = (this.currentTags || []).map((i) => {
      return {
        text: i,
      };
    });
  },
};
</script>

<style></style>
