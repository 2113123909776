import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import {
  BootstrapVue,
  IconsPlugin,
  ToastPlugin,
  ModalPlugin,
} from "bootstrap-vue";
import VueNativeSocket from "vue-native-websocket";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./app.scss";

Vue.config.productionTip = false;

let Axios = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

Axios.interceptors.request.use(function(config) {
  const token = localStorage.getItem("accessToken");
  config.headers.Authorization = token ? token : "";
  return config;
});

Axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      localStorage.removeItem("accessToken");
      window.location.href = "/";
      return;
    } else {
      return Promise.reject(error);
    }
  }
);

Vue.prototype.$http = Axios;
Vuex.Store.prototype.$http = Axios;

router.beforeEach((to, from, next) => {
  store.commit("URL_CHANGE", to);
  next();
});

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

const loc = window.location;
let wsProtocol = "ws:";
if (loc.protocol === "https:") {
  wsProtocol = "wss:";
}
Vue.use(VueNativeSocket, `${wsProtocol}//${loc.host}/ws/trader`, {
  store: store,
  format: "json",
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 10000,
  connectManually: true,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    const token = localStorage.getItem("accessToken");
    if (token) {
      this.$store.dispatch("LOGIN");
    }
    setInterval(() => this.$store.dispatch("MINUTE"), 60000);
  },
}).$mount("#app");
