<template>
  <div class="spinner">
    <b-icon
      icon="three-dots"
      animation="fade"
      font-scale="1"
      variant="warning"
    ></b-icon>
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    text: {
      type: String,
      default: "Loading ...",
    },
  },
};
</script>

<style></style>
