<template>
  <div class="decimal-input">
    <div v-if="title">
      <small>{{ title }}</small>
    </div>
    <b-input-group size="sm" :append="unit">
      <b-form-input
        v-model="result_str"
        v-on:keypress="isDigit"
        :state="valid ? null : false"
      ></b-form-input>
    </b-input-group>
    <div class="di-range">
      <span>{{ (min && min.toFixed()) || "" }}</span
      ><span class="di-range-divider"></span
      ><span>{{ (max && max.toFixed()) || "" }}</span>
    </div>
  </div>
</template>

<script>
import { BInputGroup, BFormInput } from "bootstrap-vue";
import { Decimal } from "decimal.js";

export default {
  name: "DecimalInput",
  components: {
    BInputGroup,
    BFormInput,
  },
  props: {
    title: String,
    value: Decimal,
    unit: String,
    min: Decimal,
    max: Decimal,
    step: Decimal,
    percents: Array,
  },
  data: function() {
    return {
      valid: true,
      result: null,
      result_str: null,
    };
  },
  computed: {
    step_result: function() {
      return this.step;
    },
    min_result: function() {
      return this.min && this.applyStep(this.min);
    },
    max_result: function() {
      return this.max && this.applyStep(this.max);
    },
  },
  watch: {
    value: function(newVal, oldVal) {
      if (oldVal.eq(newVal)) {
        return;
      }
      try {
        const res = this.cleanupDecimal(newVal);
        if (!res.eq(this.result)) {
          this.result_str = res.toFixed();
        }
      } catch (e) {
        console.log(e);
      }
    },
    result_str: function(newVal, oldVal) {
      this.validate(newVal);
    },
    min: function(newVal, oldVal) {
      if (oldVal.eq(newVal)) {
        return;
      }
      this.validate(this.result_str);
    },
    max: function(newVal, oldVal) {
      if (oldVal.eq(newVal)) {
        return;
      }
      this.validate(this.result_str);
    },
  },
  methods: {
    isDigit: function(e) {
      e = e ? e : window.event;
      var charCode = e.which ? e.which : e.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 45
      ) {
        e.preventDefault();
      } else {
        if (
          (charCode == 46 && this.result_str.includes(".")) ||
          (charCode == 45 && this.result_str.length)
        ) {
          e.preventDefault();
        } else {
          return true;
        }
      }
    },
    applyStep: function(val) {
      if (this.step_result) {
        return val
          .div(this.step_result)
          .floor()
          .mul(this.step_result);
      }
      return val;
    },
    cleanupDecimal: function(val) {
      /* step / min / max */
      let res = val;
      if (this.min_result && val.lt(this.min_result)) {
        res = this.min_result;
      }
      if (this.max_result && val.gt(this.max_result)) {
        res = this.max_result;
      }
      return this.applyStep(res);
    },
    setResult: function(val) {
      this.result = this.cleanupDecimal(val);
      this.result_str = this.result.toFixed();
    },
    validate: function(val) {
      this.valid = true;
      if (!val.length || val == "-") {
        this.valid = false;
        this.$emit("valid", false);
        return;
      }
      let res = null;
      try {
        res = this.cleanupDecimal(new Decimal(val));
      } catch (err) {
        this.valid = false;
        this.$emit("valid", false);
        return;
      }
      if (!res.eq(new Decimal(val))) {
        this.valid = false;
        this.$emit("valid", false);
        return;
      }
      this.result = res;
      this.$emit("input", res);
      this.$emit("valid", true);
    },
  },
  mounted: function() {
    this.result = this.cleanupDecimal(this.value || new Decimal(0));
    this.result_str = this.result.toFixed();
  },
};
</script>

<style></style>
