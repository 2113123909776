export default {
  auth: {
    loggedIn: false,
  },
  pages: {
    name: null,
  },
  socket: {
    isConnected: false,
    reconnectError: false,
  },
  trader: {
    trades: {},
    logs: {},
  },
  minute: {
    now: new Date(),
  },
};
