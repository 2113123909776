<template>
  <div>
    <svg class="mchart" :width="width" :height="height">
      <g
        v-for="line in chartData"
        :key="line.i"
        :transform="`translate(${line.x},0)`"
      >
        <rect
          :width="1"
          :height="line.yv"
          :x="0"
          :y="height - line.yv"
          class="volume"
        ></rect>
        <rect
          :width="1"
          :height="line.y2 - line.y1"
          :x="0"
          :y="height - line.y2"
        ></rect>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "MiniChart",
  props: {
    lines: { type: Array, default: () => [] },
    height: { type: Number, default: 25 },
    width: { type: Number, default: 100 },
  },
  computed: {
    chartData() {
      let result = [];
      const maxVol = Math.max(...this.lines.map((i) => parseFloat(i[2])));
      const minPrice = Math.min(...this.lines.map((i) => parseFloat(i[0])));
      const maxPrice = Math.max(...this.lines.map((i) => parseFloat(i[1])));
      for (const [index, line] of this.lines.entries()) {
        result.push({
          i: index,
          x: (this.width / 30) * index,
          y1: parseFloat(
            ((line[0] - minPrice) / (maxPrice - minPrice)) * this.height
          ),
          y2: parseFloat(
            ((line[1] - minPrice) / (maxPrice - minPrice)) * this.height
          ),
          yv: parseFloat((line[2] / maxVol) * this.height),
        });
      }
      return result;
    },
  },
};
</script>

<style lang="css">
.mchart rect {
  fill: #42b883;
}
.mchart rect.volume {
  fill: #f1fd8444;
}
</style>
