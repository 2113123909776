<template>
  <div class="nothing-found">
    <b-icon-binoculars />
    <span>
      Nothing found
    </span>
  </div>
</template>

<script>
export default {
  name: "NothingFound",
};
</script>

<style></style>
