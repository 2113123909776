<template>
  <span>
    <slot
      v-bind:val="{
        dt: dateTimeStr,
        ago: agoStr,
        d: dynamicStr,
        ts: timeSince,
      }"
      >{{ dateTimeStr }} ({{ agoStr }})</slot
    >
  </span>
</template>

<script>
export default {
  name: "Timestamp",
  props: {
    ts: Number,
    endTs: Number,
  },
  computed: {
    date: function() {
      return new Date(this.ts);
    },
    now: function() {
      return (
        (this.endTs && new Date(this.endTs)) || this.$store.state.minute.now
      );
    },
    timeSince: function() {
      let seconds = Math.floor((this.now - this.date) / 1000);
      if (seconds < 0) {
        seconds = 0;
      }
      const days = Math.floor(seconds / 86400);
      if (days > 2) {
        return days + (days >= 2 ? " days" : " day");
      }

      const hours = Math.floor((seconds % 86400) / 3600);
      if (days) {
        return (
          days +
          (days >= 2 ? " days" : " day") +
          (hours > 0
            ? ", " + hours + (hours >= 2 || !hours ? " hours" : " hour")
            : "")
        );
      }
      if (hours > 6) {
        return hours + (hours >= 2 ? " hours" : " hour");
      }

      const minutes = Math.floor((seconds % 3600) / 60);
      if (hours) {
        return (
          hours +
          (hours >= 2 ? " hours" : " hour") +
          (minutes > 0
            ? ", " +
              minutes +
              (minutes >= 2 || !minutes ? " minutes" : " minute")
            : "")
        );
      }
      if (minutes) {
        return minutes + (minutes >= 2 ? " minutes" : " minute");
      }

      return "seconds";
    },
    agoStr: function() {
      return `${this.timeSince} ago`;
    },
    dynamicStr: function() {
      if ((this.now - this.date) / 1000 > 3600 * 24) {
        return this.agoStr;
      }
      return this.dateTimeStr;
    },
    dateTimeStr: function() {
      if (this.timeSince) {
        return this.date.toLocaleString("en-US", {
          timeZone: localStorage.getItem("timezoneStr") || "Etc/UTC",
        });
      }
    },
  },
};
</script>

<style></style>
