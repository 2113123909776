<template>
  <div class="indicator">
    <span>Status</span>
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "StatusIndicator",
  props: {
    trade: Object,
  },
  computed: {
    text: function() {
      return (
        this.trade &&
        this.trade.status &&
        this.trade.status.length &&
        this.trade.status.toUpperCase()
      );
    },
  },
};
</script>

<style></style>
