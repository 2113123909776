<template>
  <div v-if="loaded" class="note-editor">
    <b-form-textarea
      placeholder=""
      rows="4"
      max-rows="10"
      no-resize
      v-model="text"
      v-on:change="onChange"
    ></b-form-textarea>
    <div v-if="ts" class="dt">
      Last edit:
      <timestamp :ts="ts">
        <template v-slot:default="slotProps">
          {{ slotProps.val.ago }}
        </template>
      </timestamp>
    </div>
  </div>
</template>

<script>
import { BFormTextarea } from "bootstrap-vue";
import Timestamp from "./Timestamp";

export default {
  name: "NoteEditor",
  props: {
    tradeToken: String,
    symbolSlug: String,
  },
  components: {
    BFormTextarea,
    Timestamp,
  },
  data: function() {
    return {
      text: "",
      version: null,
      ts: null,
      loaded: false,
    };
  },
  methods: {
    onChange: function(v) {
      this.$emit("change", {
        trade_token: this.tradeToken,
        symbol_slug: this.symbolSlug,
        version: this.version,
        text: v,
      });
    },
  },
  mounted: function() {
    this.$http
      .post("/api/notes", {
        trade_token: this.tradeToken,
        symbol_slug: this.symbolSlug,
      })
      .then(
        function(response) {
          this.text = response.data.text || "";
          this.version = response.data.version || 0;
          this.ts = response.data.ts;
          this.loaded = true;
        }.bind(this)
      )
      .catch(
        function(reason) {
          console.log(reason);
        }.bind(this)
      );
  },
};
</script>

<style></style>
