<template>
  <div class="trade-form" v-if="trade">
    <div class="trade-row header">
      Teplate:
      <span
        size="sm"
        :class="{ saved: templateSaved }"
        v-on:click="onSaveTemplate"
        >Save</span
      >
      <span size="sm" v-on:click="onResetTemplate">Reset</span>
    </div>
    <div class="trade-row header">
      Available: {{ symbol.quote_balance.available.toSD(2).toFixed() }}
      {{ symbol.quote }} x {{ symbol.leverage }} +
      {{ symbol.bnb_balance.available.toSD(2).toFixed() }} BNB
    </div>
    <h2>Entries</h2>
    <div
      class="trade-row"
      v-for="(item, index) in trade.entries"
      v-bind:key="'en' + index"
    >
      <div v-if="currentEditor == `entries${index}`" class="form-row-edit">
        <div class="edit-header">
          <span v-on:click="onHideEditor">#{{ index + 1 }} Entry</span>
          <b-icon-chevron-up v-on:click="onHideEditor" />
        </div>
        <plain-selector
          :value="trade.entries[index].type"
          :options="findEntryOptions(index)"
          class="edit-row"
          v-on:input="onTradeEdit('entries', index, 'type', $event)"
        />
        <decimal-input
          title="Quantity"
          :value="trade.entries[index].quantity"
          v-on:input="onTradeEdit('entries', index, 'quantity', $event)"
          :unit="symbol.base"
          :min="findEntryMinQuantity(index)"
          :max="findEntryMaxQuantity(index)"
          :step="quantityStep"
        />
        <decimal-input
          v-if="item.type != 'market'"
          title="Price"
          :value="trade.entries[index].price"
          v-on:input="onTradeEdit('entries', index, 'price', $event)"
          :unit="`${symbol.quote} / ${symbol.base}`"
          :min="findEntryMinPrice(index)"
          :max="findEntryMaxPrice(index)"
          :step="priceStep"
        />
      </div>
      <template v-else>
        <span class="left-action"
          ><b-icon-trash v-if="index > 0" v-on:click="onRemoveEntry(index)"
        /></span>
        <span class="number">#{{ index + 1 }}</span>
        <span class="content" v-on:click="onShowEditor('entries', index)"
          >{{ item.type.toUpperCase() }} {{ item.quantity }} @
          {{ item.type == "market" ? "market" : item.price }}</span
        >
      </template>
    </div>
    <div class="trade-row" v-if="canAddEntry">
      <span class="left-action"></span>
      <span class="number"
        ><b-icon-plus-circle v-on:click="onInsertEntry"
      /></span>
    </div>
    <h2>Exits</h2>
    <div
      class="trade-row"
      v-for="(item, index) in trade.exits"
      v-bind:key="'ex' + index"
    >
      <div v-if="currentEditor == `exits${index}`" class="form-row-edit">
        <div class="edit-header">
          <span v-on:click="onHideEditor">#{{ index + 1 }} Exit</span>
          <b-icon-chevron-up v-on:click="onHideEditor" />
        </div>
        <plain-selector
          :value="trade.exits[index].type"
          :options="findExitOptions(index)"
          class="edit-row"
          v-on:input="onTradeEdit('exits', index, 'type', $event)"
        />
        <decimal-input
          title="Quantity (% from filled entries)"
          :value="trade.exits[index].quantity"
          v-on:input="onTradeEdit('exits', index, 'quantity', $event)"
          unit="%"
          :min="findExitMinQuantity(index)"
          :max="findExitMaxQuantity(index)"
          :step="percentStep"
        />
        <decimal-input
          title="Price"
          :value="trade.exits[index].price"
          v-on:input="onTradeEdit('exits', index, 'price', $event)"
          :unit="`${symbol.quote} / ${symbol.base}`"
          :min="findExitMinPrice(index)"
          :max="findExitMaxPrice(index)"
          :step="priceStep"
        />
      </div>
      <template v-else>
        <span class="left-action"
          ><b-icon-trash v-if="index > 0" v-on:click="onRemoveExit(index)"
        /></span>
        <span class="number">#{{ index + 1 }}</span>
        <span class="content" v-on:click="onShowEditor('exits', index)"
          >{{ item.type.toUpperCase() }}
          <percent-circle :percents="item.quantity" /> @ {{ item.price }}</span
        >
      </template>
    </div>
    <div class="trade-row" v-if="canAddExit">
      <span class="left-action"></span>
      <span class="number"
        ><b-icon-plus-circle v-on:click="onInsertExit"
      /></span>
    </div>
    <h2 v-if="trade.exits.length">Stop</h2>
    <div class="trade-row" v-if="trade.exits.length">
      <div v-if="currentEditor == 'stop'" class="form-row-edit">
        <div class="edit-header">
          <span v-on:click="onHideEditor">Stop</span>
          <b-icon-chevron-up v-on:click="onHideEditor" />
        </div>
        <plain-selector
          :value="trade.stop.type"
          :options="stopOptions"
          class="edit-row"
          v-on:input="onTradeEdit('stop', null, 'type', $event)"
        />
        <decimal-input
          title="Price"
          :value="trade.stop.price"
          v-on:input="onTradeEdit('stop', null, 'price', $event)"
          :unit="`${symbol.quote} / ${symbol.base}`"
          :min="findStopMinPrice()"
          :max="findStopMaxPrice()"
          :step="priceStep"
        />
        <decimal-input
          v-if="trade.stop.type == 'trailing'"
          title="Distance"
          :value="trade.stop.distance"
          v-on:input="onTradeEdit('stop', null, 'distance', $event)"
          :unit="`${symbol.quote} / ${symbol.base}`"
          :min="findStopMinDistance()"
          :max="findStopMaxDistance()"
          :step="priceStep"
        />
      </div>
      <template v-else>
        <span class="left-action"></span>
        <span class="content" v-on:click="onShowEditor('stop', null)"
          >{{ trade.stop.type.toUpperCase() }} @ {{ trade.stop.price }}</span
        >
      </template>
    </div>
    <h2>Options</h2>
    <div class="trade-row" v-if="trade.exits.length">
      <span class="left-action"></span>
      <span class="content" v-on:click="onSwitchCloseOnFailure">
        Close on failure: {{ trade.options.close_on_failure ? "Yes" : "No" }}
      </span>
    </div>
    <div class="trade-row">
      <div v-if="currentEditor == `optionsstartprice`" class="form-row-edit">
        <div class="edit-header">
          <span v-on:click="onHideEditor">Start price</span>
          <b-icon-chevron-up v-on:click="onHideEditor" />
        </div>
        <b-form-checkbox
          :checked="(trade.options.start_price && true) || false"
          :switch="true"
          v-on:change="onEnableStartPrice"
          class="mt-2"
          >Enable</b-form-checkbox
        >
        <decimal-input
          title="Price"
          :value="trade.options.start_price"
          v-on:input="onTradeEdit('options', null, 'start_price', $event)"
          :unit="`${symbol.quote} / ${symbol.base}`"
          :step="priceStep"
          v-if="trade.options.start_price"
        />
      </div>
      <template v-else>
        <span class="left-action"></span>
        <span
          class="content"
          v-on:click="onShowEditor('optionsstartprice', null)"
        >
          Start price: {{ trade.options.start_price || "N/A" }}
        </span>
      </template>
    </div>
    <div class="trade-row">
      <div v-if="currentEditor == 'optionscancelprice'" class="form-row-edit">
        <div class="edit-header">
          <span v-on:click="onHideEditor">Cancel price</span>
          <b-icon-chevron-up v-on:click="onHideEditor" />
        </div>
        <b-form-checkbox
          :checked="(trade.options.cancel_price && true) || false"
          :switch="true"
          v-on:change="onEnableCancelPrice"
          class="mt-2"
          >Enable</b-form-checkbox
        >
        <decimal-input
          title="Price"
          :value="trade.options.cancel_price"
          v-on:input="onTradeEdit('options', null, 'cancel_price', $event)"
          :unit="`${symbol.quote} / ${symbol.base}`"
          :step="priceStep"
          v-if="trade.options.cancel_price"
        />
      </div>
      <template v-else>
        <span class="left-action"></span>
        <span
          class="content"
          v-on:click="onShowEditor('optionscancelprice', null)"
        >
          Cancel price: {{ trade.options.cancel_price || "N/A" }}
        </span>
      </template>
    </div>
    <trade-summary :symbol="symbol" :state="trade" :is-long="isLong" />
    <b-button
      variant="primary"
      class="mt-4 btn-block"
      v-on:click.prevent="onSubmit"
      :disabled="isSubmitting"
      >Submit</b-button
    >
  </div>
</template>

<script>
import Decimal from "decimal.js";
import PercentCircle from "../lib/PercentCircle";
import DecimalInput from "../lib/DecimalInput";
import { BButton, BFormCheckbox } from "bootstrap-vue";
import PlainSelector from "../lib/PlainSelector";
import TradeSummary from "./TradeSummary";

export default {
  name: "TradeCreator",
  props: {
    symbol: Object,
    value: Object,
  },
  components: {
    PercentCircle,
    DecimalInput,
    BFormCheckbox,
    PlainSelector,
    BButton,
    TradeSummary,
  },
  data: function() {
    return {
      stopOptions: [
        {
          slug: "simple",
          title: "Simple",
        },
        {
          slug: "ladder",
          title: "Ladder",
        },
        {
          slug: "trailing",
          title: "Trailing",
        },
      ],
      isSubmitting: false,
      trade: null,
      currentEditor: null,
      templateSaved: null,
    };
  },
  watch: {
    value: function(newValue, oldValue) {
      if (!newValue) {
        return;
      }
      this.templateSaved = false;
      let changed = false;
      newValue.entries.forEach((i, n) => {
        const minVal = this.findEntryMinPrice(n);
        const maxVal = this.findEntryMaxPrice(n);
        if (minVal && i.price.lt(minVal)) {
          i.price = minVal;
          changed = true;
        }
        if (maxVal && i.price.gt(maxVal)) {
          i.price = maxVal;
          changed = true;
        }
      });
      newValue.exits.forEach((i, n) => {
        const minVal = this.findExitMinPrice(n);
        const maxVal = this.findExitMaxPrice(n);
        if (minVal && i.price.lt(minVal)) {
          i.price = minVal;
          changed = true;
        }
        if (maxVal && i.price.gt(maxVal)) {
          i.price = maxVal;
          changed = true;
        }
      });
      const minStop = this.findStopMinPrice();
      const maxStop = this.findStopMaxPrice();
      if (minStop && newValue.stop.price.lt(minStop)) {
        newValue.stop.price = minStop;
        changed = true;
      }
      if (maxStop && newValue.stop.price.gt(maxStop)) {
        newValue.stop.price = maxStop;
        changed = true;
      }
      this.trade = newValue;
      if (changed) {
        this.$emit("input", this.trade);
      }
    },
  },
  computed: {
    priceStep: function() {
      return this.symbol.pf_tick_size;
    },
    quantityStep: function() {
      return this.symbol.ls_step_size;
    },
    startAsk: function() {
      if (this.trade && this.trade.options && this.trade.options.start_price) {
        return new Decimal(this.trade.options.start_price);
      }
      return this.symbol.ticker.ask;
    },
    startBid: function() {
      if (this.trade && this.trade.options && this.trade.options.start_price) {
        return new Decimal(this.trade.options.start_price).minus(
          this.priceStep
        );
      }
      return this.symbol.ticker.bid;
    },
    canAddExit: function() {
      return (
        !this.trade.exits.length ||
        this.trade.exits[this.trade.exits.length - 1].quantity.gte(
          this.percentStep.mul(new Decimal(2))
        )
      );
    },
    percentStep: function() {
      /* 1, 10 or 100 */
      const firstQuantityParts = this.trade.entries[0].quantity.div(
        this.findMinBase(false, this.startAsk).mul(new Decimal(2))
      );
      if (firstQuantityParts.gt(new Decimal(100))) {
        return new Decimal(1);
      }
      if (firstQuantityParts.gt(new Decimal(20))) {
        return new Decimal(5);
      }
      if (firstQuantityParts.gt(new Decimal(10))) {
        return new Decimal(10);
      }
      if (firstQuantityParts.gt(new Decimal(4))) {
        return new Decimal(25);
      }
      if (firstQuantityParts.gt(new Decimal(2))) {
        return new Decimal(50);
      }
      return new Decimal(100);
    },
    canAddEntry: function() {
      return true;
    },
    isLong: function() {
      let entryPrice =
        this.trade.entries[0].type == "market"
          ? this.startAsk
          : this.trade.entries[0].price;
      return entryPrice < this.trade.exits[0].price;
    },
  },
  methods: {
    findMinBase: function(isMarket, price) {
      const minimums = [];
      if (this.symbol.ls_min_qty) {
        minimums.push(this.symbol.ls_min_qty);
      }
      if (this.symbol.ls_step_size) {
        minimums.push(this.symbol.ls_step_size);
      }
      if (isMarket) {
        if (this.symbol.mls_min_qty) {
          minimums.push(this.symbol.mls_min_qty);
        }
        if (this.symbol.mls_step_size) {
          minimums.push(this.symbol.mls_step_size);
        }
      }
      if (this.symbol.mn_min_notional) {
        if (!isMarket || this.symbol.mn_apply_to_market) {
          minimums.push(this.symbol.mn_min_notional.div(price));
        }
      }
      return Decimal.max(...minimums)
        .div(this.quantityStep)
        .ceil()
        .mul(this.quantityStep);
    },
    findMinQuote: function(isMarket, price) {
      return this.findMinBase(isMarket, price).mul(price);
    },
    onHideEditor: function() {
      this.currentEditor = null;
    },
    onShowEditor: function(section, index) {
      this.currentEditor = `${section}${Number.isInteger(index) ? index : ""}`;
    },
    onTradeEdit: function(section, index, key, value) {
      if (Number.isInteger(index)) {
        this.trade = {
          ...this.trade,
          [section]: [
            ...this.trade[section].slice(0, index),
            {
              ...this.trade[section][index],
              [key]: value,
            },
            ...this.trade[section].slice(index + 1, this.trade[section].length),
          ],
        };
      } else {
        this.trade = {
          ...this.trade,
          [section]: {
            ...this.trade[section],
            [key]: value,
          },
        };
      }
      if (section == "exits" && key == "quantity") {
        this.adjustExits();
      }
      this.templateSaved = false;
      this.$emit("input", this.trade);
    },
    adjustExits: function() {
      const newExits = [];
      let sum = new Decimal(0);
      let q = null;
      let counter = 0;
      this.trade.exits.forEach((i) => {
        if (sum && sum.eq(new Decimal(100))) {
          return;
        }
        q = i.quantity
          .div(this.percentStep)
          .floor()
          .mul(this.percentStep);
        if (!q.gt(new Decimal(0))) {
          q = this.percentStep;
        }
        if (
          counter == this.trade.exits.length - 1 ||
          sum.plus(q).gt(new Decimal(100))
        ) {
          q = new Decimal(100).minus(sum);
        }
        newExits.push({
          ...i,
          quantity: q,
        });
        sum = sum.plus(q);
        counter += 1;
      });
      this.trade = {
        ...this.trade,
        exits: newExits,
      };
    },
    onRemoveExit: function(index) {
      this.trade = {
        ...this.trade,
        exits: [
          ...this.trade.exits.slice(0, index),
          ...this.trade.exits.slice(index + 1, this.trade.exits.length),
        ],
      };
      this.adjustExits();
      this.$emit("input", this.trade);
    },
    findExitMinQuantity: function(index) {
      if (this.trade.exits.length == 1) {
        return new Decimal(100);
      }
      if (this.trade.exits.length - 1 == index) {
        return this.findExitRemainingQuantity();
      }
      return new Decimal(1);
    },
    findExitMaxQuantity: function(index) {
      if (this.trade.exits.length == 1) {
        return new Decimal(100);
      }
      if (this.trade.exits.length - 1 == index) {
        return this.findExitRemainingQuantity();
      }
      let used = new Decimal(0);
      let counter = 0;
      this.trade.exits.forEach((i) => {
        if (counter < index) {
          used = used.plus(i.quantity);
        }
        counter += 1;
      });
      return new Decimal(100).minus(used);
    },
    findExitRemainingQuantity: function() {
      let remaining = new Decimal(100);
      this.trade.exits.slice(0, this.trade.exits.length - 1).forEach((i) => {
        remaining = remaining.minus(i.quantity);
      });
      return remaining;
    },
    onSaveTemplate: function() {
      NProgress.start();
      this.$http
        .post(`/api/symbols/${this.symbol.slug}/template`, {
          body: this.dumpTemplate(),
        })
        .then(
          function(response) {
            this.templateSaved = true;
          }.bind(this)
        )
        .catch(
          function(reason) {
            let errors = "Internal server error.";
            if (
              reason.response.status == 422 ||
              reason.response.status == 400
            ) {
              errors = JSON.stringify(reason.response.data, null, 2);
            }

            const h = this.$createElement;
            const $errors = h("pre", {}, errors);

            this.$bvToast.toast([$errors], {
              title: "Error on saving template",
              variant: "danger",
              autoHideDelay: 6000,
              toastClass: "mt-4",
            });
          }.bind(this)
        )
        .finally(
          function() {
            NProgress.done();
          }.bind(this)
        );
    },
    onResetTemplate: function() {
      NProgress.start();
      this.$http
        .delete(`/api/symbols/${this.symbol.slug}/template`)
        .then(
          function(response) {
            this.templateSaved = false;
            this.trade = this.generate();
            this.$emit("input", this.trade);
          }.bind(this)
        )
        .catch(
          function(reason) {
            let errors = "Internal server error.";
            if (
              reason.response.status == 422 ||
              reason.response.status == 400
            ) {
              errors = JSON.stringify(reason.response.data, null, 2);
            }

            const h = this.$createElement;
            const $errors = h("pre", {}, errors);

            this.$bvToast.toast([$errors], {
              title: "Error on remove template",
              variant: "danger",
              autoHideDelay: 6000,
              toastClass: "mt-4",
            });
          }.bind(this)
        )
        .finally(
          function() {
            NProgress.done();
          }.bind(this)
        );
    },
    findEntryOptions: function(index) {
      const options = [
        {
          slug: "limit",
          title: "Limit",
        },
      ];
      if (index == 0) {
        options.push({
          slug: "market",
          title: "Market",
        });
      }
      return options;
    },
    findExitOptions: function(index) {
      return [
        {
          slug: "limit",
          title: "Limit",
        },
      ];
    },
    findEntryMinQuantity: function(index) {
      return this.findMinBase(
        this.trade.entries[index].type == "market",
        this.trade.entries[index].type == "market"
          ? this.startAsk
          : this.trade.entries[index].price
      );
    },
    findEntryMaxQuantity: function(index) {
      let quote = this.symbol.quote_balance["available"].mul(
        this.symbol.leverage
      );
      this.trade.entries.forEach((i, pos) => {
        if (pos != index) {
          quote = quote.minus(
            i.quantity.mul(i.type == "market" ? this.startAsk : i.price)
          );
        }
      });
      return quote
        .div(
          this.trade.entries[index].type == "market"
            ? this.startAsk
            : this.trade.entries[index].price
        )
        .div(this.quantityStep)
        .floor()
        .mul(this.quantityStep);
    },
    findEntryMinPrice: function(index) {
      return this.symbol.pf_min_price;
    },
    findExitMinPrice: function(index) {
      return this.symbol.pf_min_price;
    },
    findStopMinPrice: function() {
      return this.symbol.pf_min_price;
    },
    findStopMinDistance: function() {
      return this.priceStep;
    },
    findEntryMaxPrice: function(index) {
      return this.symbol.pf_max_price;
    },
    findExitMaxPrice: function(index) {
      return this.symbol.pf_max_price;
    },
    findStopMaxPrice: function() {
      return this.symbol.pf_max_price;
    },
    findStopMaxDistance: function() {
      return this.trade.exits[this.trade.exits.length - 1].price.minus(
        this.trade.entries[this.trade.entries.length - 1].price
      );
    },
    generate_v2: function() {
      let entryQuoteQuantity =
        (this.$route.query.quote && new Decimal(this.$route.query.quote)) ||
        this.symbol.quote_balance["available"];
      const entryPrice = new Decimal(this.$route.query.entry);

      const minQuoteQuantity = this.findMinQuote(true, entryPrice).mul(
        new Decimal("3")
      );
      if (entryQuoteQuantity.lt(minQuoteQuantity)) {
        entryQuoteQuantity = minQuoteQuantity;
      }

      const entryBaseQuantity = entryQuoteQuantity
        .div(entryPrice)
        .mul(new Decimal("0.25"))
        .div(this.quantityStep)
        .round()
        .mul(this.quantityStep);

      const exitPrice = new Decimal(this.$route.query.exit)
        .div(this.priceStep)
        .round()
        .mul(this.priceStep);

      const stopPrice = new Decimal(this.$route.query.stop)
        .div(this.priceStep)
        .round()
        .mul(this.priceStep);

      let priceDelta = entryPrice.mul(new Decimal("0.05"));
      if (priceDelta.lt(this.priceStep)) {
        priceDelta = this.priceStep.mul(new Decimal("2"));
      }

      const distance = priceDelta
        .div(new Decimal(2))
        .div(this.priceStep)
        .ceil()
        .mul(this.priceStep);

      const exitPrice1 = entryPrice
        .plus(exitPrice)
        .div(new Decimal(2))
        .div(this.priceStep)
        .round()
        .mul(this.priceStep);

      const entryPrice2 = entryPrice
        .plus(stopPrice)
        .div(new Decimal(2))
        .div(this.priceStep)
        .round()
        .mul(this.priceStep);

      return {
        entries: [
          {
            type: "market",
            quantity: entryBaseQuantity.mul(new Decimal(3)),
            price: entryPrice,
          },
          {
            type: "limit",
            quantity: entryBaseQuantity,
            price: entryPrice2,
          },
        ],
        exits: [
          {
            type: "limit",
            quantity: new Decimal("50"),
            price: exitPrice1,
          },
          {
            type: "limit",
            quantity: new Decimal("50"),
            price: exitPrice,
          },
        ],
        stop: {
          type: "ladder",
          price: stopPrice,
          distance: distance,
        },
        options: {
          close_on_failure: true,
        },
      };
    },
    generate: function() {
      if (this.$route.query.entry) {
        return this.generate_v2();
      }

      /* use 10% or minimum available quote, entry - market, exit - +5%, stop - -5% */
      let entryQuoteQuantity =
        (this.$route.query.quote && new Decimal(this.$route.query.quote)) ||
        this.symbol.quote_balance["available"];
      const entryPrice =
        (this.$route.query.entry && new Decimal(this.$route.query.entry)) ||
        this.startAsk;
      const minQuoteQuantity = this.findMinQuote(true, entryPrice).mul(
        new Decimal("2")
      );
      if (entryQuoteQuantity.lt(minQuoteQuantity)) {
        entryQuoteQuantity = minQuoteQuantity;
      }
      const entryBaseQuantity = entryQuoteQuantity
        .div(entryPrice)
        .div(this.quantityStep)
        .round()
        .mul(this.quantityStep);
      let priceDelta = entryPrice.mul(new Decimal("0.05"));
      if (priceDelta.lt(this.priceStep)) {
        priceDelta = this.priceStep.mul(new Decimal("2"));
      }
      const exitPriceCand =
        (this.$route.query.exit && new Decimal(this.$route.query.exit)) ||
        entryPrice.plus(priceDelta);
      const exitPrice = exitPriceCand
        .div(this.priceStep)
        .round()
        .mul(this.priceStep);
      const stopPriceCand =
        (this.$route.query.stop && new Decimal(this.$route.query.stop)) ||
        entryPrice.minus(priceDelta);
      const stopPrice = stopPriceCand
        .div(this.priceStep)
        .round()
        .mul(this.priceStep);

      const distance = priceDelta
        .div(new Decimal(2))
        .div(this.priceStep)
        .ceil()
        .mul(this.priceStep);

      return {
        entries: [
          {
            type: "limit",
            quantity: entryBaseQuantity,
            price: entryPrice,
          },
        ],
        exits: [
          {
            type: "limit",
            quantity: new Decimal("100"),
            price: exitPrice,
          },
        ],
        stop: {
          type: "ladder",
          price: stopPrice,
          distance: distance,
        },
        options: {
          close_on_failure: true,
        },
      };
    },
    dumpTemplate: function() {
      const entries = this.trade.entries.map((i) => {
        return {
          type: i.type,
          quantity: i.quantity.toFixed(),
          price: i.price.toFixed(),
        };
      });
      const exits = this.trade.exits.map((i) => {
        return {
          type: i.type,
          quantity: i.quantity.toFixed(),
          price: i.price.toFixed(),
        };
      });
      const stop = {
        type: this.trade.stop.type,
        price: this.trade.stop.price.toFixed(),
        distance: this.trade.stop.distance.toFixed(),
      };
      const options = {
        close_on_failure: this.trade.options.close_on_failure,
        start_price:
          (this.trade.options.start_price &&
            new Decimal(this.trade.options.start_price)) ||
          null,
        cancel_price:
          (this.trade.options.cancel_price &&
            new Decimal(this.trade.options.cancel_price)) ||
          null,
      };
      return {
        state: {
          entries,
          exits,
          stop,
          options,
        },
      };
    },
    loadTemplate: function(body) {
      const entries = body.state.entries.map((i) => {
        return {
          type: i.type,
          quantity: new Decimal(i.quantity),
          price: new Decimal(i.price),
        };
      });
      const exits = body.state.exits.map((i) => {
        return {
          type: i.type,
          quantity: new Decimal(i.quantity),
          price: new Decimal(i.price),
        };
      });
      const stop = {
        type: body.state.stop.type,
        price: Decimal(body.state.stop.price),
        distance: Decimal(body.state.stop.distance),
      };
      const options = {
        close_on_failure:
          body.state.options && body.state.options.close_on_failure,
        start_price:
          body.state.options &&
          body.state.options.start_price &&
          new Decimal(body.state.options.start_price),
        cancel_price:
          body.state.options &&
          body.state.options.cancel_price &&
          new Decimal(body.state.options.cancel_price),
      };
      this.trade = {
        entries,
        exits,
        stop,
        options,
      };
    },
    onInsertEntry() {
      /* -5% price, use 2x quantity or max available */
      let priceDelta = this.trade.entries[
        this.trade.entries.length - 1
      ].price.mul(new Decimal(0.05));
      if (priceDelta.lt(this.priceStep)) {
        priceDelta = this.priceStep.mul(new Decimal(2));
      } else {
        priceDelta = priceDelta
          .div(this.priceStep)
          .round()
          .mul(this.priceStep);
      }
      if (this.isLong) {
        priceDelta = priceDelta.mul(new Decimal(-1));
      }

      const price = this.trade.entries[
        this.trade.entries.length - 1
      ].price.plus(priceDelta);
      const quantity = this.findMinBase(false, price).mul(new Decimal(2));
      this.trade = {
        ...this.trade,
        entries: [
          ...this.trade.entries,
          {
            type: "limit",
            quantity: quantity,
            price: price,
          },
        ],
      };
      this.$emit("input", this.trade);
    },
    onInsertExit: function() {
      const lastExit = (this.trade.exits.length &&
        this.trade.exits[this.trade.exits.length - 1]) || {
        price: this.trade.entries[0].price,
        quantity: new Decimal(0),
      };
      let quantity = lastExit.quantity
        .div(new Decimal(2))
        .floor()
        .div(this.percentStep)
        .floor()
        .mul(this.percentStep);
      if (quantity.lt(this.percentStep)) {
        quantity = this.percentStep;
      }

      let priceDelta = lastExit.price.mul(new Decimal(0.05));
      if (priceDelta.lt(this.priceStep)) {
        priceDelta = this.priceStep.mul(new Decimal(2));
      } else {
        priceDelta = priceDelta
          .div(this.priceStep)
          .round()
          .mul(this.priceStep);
      }

      if (!this.isLong) {
        priceDelta = priceDelta.mul(new Decimal("-1"));
      }

      this.trade = {
        ...this.trade,
        exits: this.trade.exits.length
          ? [
              ...this.trade.exits.slice(0, this.trade.exits.length - 1),
              {
                ...lastExit,
                quantity: lastExit.quantity.minus(quantity),
              },
              {
                type: "limit",
                quantity: quantity,
                price: lastExit.price.plus(priceDelta),
              },
            ]
          : [
              {
                type: "limit",
                quantity: new Decimal(100),
                price: lastExit.price.plus(priceDelta),
              },
            ],
      };
      this.$emit("input", this.trade);
    },
    onRemoveEntry(index) {
      this.trade = {
        ...this.trade,
        entries: [
          ...this.trade.entries.slice(0, index),
          ...this.trade.entries.slice(index + 1, this.trade.entries.length),
        ],
      };
      this.$emit("input", this.trade);
    },
    onSubmit: function() {
      const entries = this.trade.entries.map((i) => {
        if (i.type == "market") {
          return {
            type: i.type,
            quantity: i.quantity.toFixed(),
          };
        }
        return {
          type: i.type,
          quantity: i.quantity.toFixed(),
          price: i.price.toFixed(),
        };
      });
      const exits = this.trade.exits.map((i) => {
        return {
          type: i.type,
          quantity: i.quantity.toFixed(),
          price: i.price.toFixed(),
        };
      });
      let stop = null;
      if (exits.length) {
        stop = {
          type: this.trade.stop.type,
          price: this.trade.stop.price.toFixed(),
        };
        if (this.trade.stop.type == "trailing") {
          stop["distance"] = this.trade.stop.distance.toFixed();
        }
      }
      const options = {
        close_on_failure: this.trade.exits.length
          ? this.trade.options.close_on_failure || false
          : false,
        start_price:
          (this.trade.options.start_price &&
            this.trade.options.start_price.toFixed()) ||
          null,
        cancel_price:
          (this.trade.options.cancel_price &&
            this.trade.options.cancel_price.toFixed()) ||
          null,
      };
      this.isSubmitting = true;
      NProgress.start();
      this.$http
        .post(`/api/trades`, {
          symbol: this.symbol.slug,
          state: {
            entries,
            exits,
            stop,
            options,
            is_long: this.isLong,
          },
        })
        .then(
          function(response) {
            this.$router.push(`/trades/${response.data.trade_token}`);
          }.bind(this)
        )
        .catch(
          function(reason) {
            let errors = "Internal server error.";
            if (
              reason.response.status == 422 ||
              reason.response.status == 400
            ) {
              errors = JSON.stringify(reason.response.data, null, 2);
            }

            const h = this.$createElement;
            const $errors = h("pre", {}, errors);

            this.$bvToast.toast([$errors], {
              title: "Error",
              variant: "danger",
              autoHideDelay: 6000,
              toastClass: "mt-4",
            });
          }.bind(this)
        )
        .finally(
          function() {
            this.isSubmitting = false;
            NProgress.done();
          }.bind(this)
        );
    },
    onSwitchCloseOnFailure: function() {
      this.trade = {
        ...this.trade,
        options: {
          ...this.trade.options,
          close_on_failure: !this.trade.options.close_on_failure,
        },
      };
    },
    onEnableStartPrice: function(val) {
      let priceDelta = this.trade.entries[0].price.mul(new Decimal(0.05));
      if (priceDelta.lt(this.priceStep)) {
        priceDelta = this.priceStep.mul(new Decimal(2));
      } else {
        priceDelta = priceDelta
          .div(this.priceStep)
          .round()
          .mul(this.priceStep);
      }
      this.trade = {
        ...this.trade,
        options: {
          ...this.trade.options,
          start_price: val
            ? this.trade.entries[0].price.plus(priceDelta)
            : null,
        },
      };
      this.$emit("input", this.trade);
    },
    onEnableCancelPrice: function(val) {
      const price =
        (this.trade.exits.length && this.trade.exits[0].price) ||
        this.trade.entries[0].price;
      let priceDelta = price.mul(new Decimal(0.05));
      if (priceDelta.lt(this.priceStep)) {
        priceDelta = this.priceStep.mul(new Decimal(2));
      } else {
        priceDelta = priceDelta
          .div(this.priceStep)
          .round()
          .mul(this.priceStep);
      }
      this.trade = {
        ...this.trade,
        options: {
          ...this.trade.options,
          cancel_price: val ? price.plus(priceDelta) : null,
        },
      };
      this.$emit("input", this.trade);
    },
  },
  mounted: function() {
    this.$http
      .get(`/api/symbols/${this.symbol.slug}/template`)
      .then(
        function(response) {
          if (response.data.body) {
            this.loadTemplate(response.data.body);
            this.templateSaved = true;
          } else {
            this.trade = this.value || this.generate();
            this.templateSaved = false;
          }
          this.$emit("input", this.trade);
        }.bind(this)
      )
      .catch(
        function(reason) {
          this.trade = this.value || this.generate();
          this.templateSaved = false;
          this.$emit("input", this.trade);
        }.bind(this)
      );
  },
};
</script>

<style></style>
