<template>
  <div>
    <div class="TVChartContainer" :id="containerId" />
  </div>
</template>

<script>
import Decimal from "decimal.js";
import { widget } from "../../contrib/charting_library.js";

const socketsMap = new Map();

export default {
  name: "TVChartContainer",
  components: {},
  props: {
    symbol: {
      type: Object,
    },
    interval: {
      default: null,
      type: String,
    },
    containerId: {
      default: "tv_chart_container",
      type: String,
    },
    libraryPath: {
      default: "/charting_library/",
      type: String,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    autosize: {
      default: true,
      type: Boolean,
    },
    points: {
      default: () => [],
      type: Array,
    },
    disableSave: {
      type: Boolean,
      default: false,
    },
    isLive: {
      type: Boolean,
      default: true,
    }
  },
  data: function() {
    return {
      currentPoints: {},
      currentInterval: null,
      chart: null,
      resolutions: [
        "1",
        "3",
        "5",
        "15",
        "30",
        "60",
        "120",
        "240",
        "360",
        "480",
        "720",
        "D",
        "3D",
        "1W",
      ],
      resolutionsMap: {
        "1": ["1m", "200"],
        "3": ["3m", "600"],
        "5": ["5m", "1D"],
        "15": ["15m", "2D"],
        "30": ["30m", "5D"],
        "60": ["1h", "10D"],
        "120": ["2h", "20D"],
        "240": ["4h", "40D"],
        "360": ["6h", "2M"],
        "480": ["8h", "3M"],
        "720": ["12h", "4M"],
        D: ["1d", "6M"],
        "1D": ["1d", "6M"],
        "3D": ["3d", "20M"],
        W: ["1w", "50M"],
        "1W": ["1w", "50M"],
        M: ["1M", "50M"],
        "1M": ["1M", "50M"],
      },
      saveTimer: null,
    };
  },
  watch: {
    points: function(newVal, oldVal) {
      if (!this.tvWidget) {
        return;
      }
      this.updatePoints();
    },
  },
  computed: {
    currentIntervalNormalized: function() {
      if (!this.currentInterval) {
        return null;
      }
      return this.translateResolution(this.currentInterval);
    },
  },
  methods: {
    translateResolution: function(res) {
      return this.resolutionsMap[res][0];
    },
    translateResolutionToTV: function(res) {
      for (const [key, value] of Object.entries(this.resolutionsMap)) {
        if (value[0] == res) {
          return key;
        }
      }
    },
    findTimeframe: function(interval) {
      return this.resolutionsMap[interval][1];
    },
    updatePoints: function() {
      if (!this.chart) {
        return;
      }
      /* build new points */
      let newPoints = {};
      this.points.forEach((o) => {
        newPoints[o.type + o.section + o.i] = {
          p: o.price,
          q: o.quantity,
          t: o.title,
          s: o.section,
          i: o.i,
          m: o.market,
          c1: o.color1,
          c2: o.color2,
          c3: o.color3,
          c4: o.color4,
          tp: o.type,
          ts: o.ts,
          dr: o.direction,
        };
      });
      /* remove points */
      Object.keys(this.currentPoints).forEach((key) => {
        if (!(key in newPoints)) {
          if (this.currentPoints[key].obj) {
            this.currentPoints[key].obj.remove();
          }
          delete this.currentPoints[key];
        }
      });
      /* update or create */
      const notifyMoved = (d) => this.$emit("moved", d);
      Object.keys(newPoints).forEach((key) => {
        const newObj = newPoints[key];
        if (newObj.tp == "order") {
          if (key in this.currentPoints) {
            newPoints[key].obj = this.currentPoints[key].obj
              .setText(newObj.t)
              .setPrice(newObj.p)
              .setQuantity(newObj.q)
              .setEditable(!newObj.m);
          } else {
            newPoints[key].obj = this.chart
              .createOrderLine()
              .setText(newObj.t)
              .setPrice(newObj.p)
              .setQuantity(newObj.q)
              .setEditable(!newObj.m)
              .setLineColor(newObj.c1 || "rgb(64,148,232)")
              .setBodyBorderColor(newObj.c1 || "rgb(64,148,232)")
              .setBodyBackgroundColor(newObj.c4 || "rgba(255,255,255,0.75)")
              .setBodyTextColor(newObj.c1 || "rgb(64,148,232)")
              .setQuantityBorderColor(newObj.c1 || "rgb(64,148,232)")
              .setQuantityBackgroundColor(newObj.c2 || "rgba(64,148,232,0.75)")
              .setQuantityTextColor(newObj.c3 || "rgb(255,255,255)")
              .onMove(function() {
                notifyMoved({
                  price: new Decimal(this.getPrice()).toFixed(),
                  section: newObj.s,
                  i: newObj.i,
                });
              });
          }
        } else if (newObj.tp == "position") {
          if (key in this.currentPoints) {
            newPoints[key].obj = this.currentPoints[key].obj
              .setText(newObj.t)
              .setPrice(newObj.p)
              .setQuantity(newObj.q);
          } else {
            newPoints[key].obj = this.chart
              .createPositionLine()
              .setText(newObj.t)
              .setPrice(newObj.p)
              .setLineColor(newObj.c1 || "rgb(64,148,232)")
              .setBodyBorderColor(newObj.c1 || "rgb(64,148,232)")
              .setBodyBackgroundColor(newObj.c4 || "rgba(255,255,255,0.75)")
              .setBodyTextColor(newObj.c1 || "rgb(64,148,232)")
              .setQuantityBorderColor(newObj.c1 || "rgb(64,148,232)")
              .setQuantityBackgroundColor(newObj.c2 || "rgba(64,148,232,0.75)")
              .setQuantityTextColor(newObj.c3 || "rgb(255,255,255)")
              .setQuantity(newObj.q);
          }
        } else if (newObj.tp == "execution") {
          if (key in this.currentPoints) {
            newPoints[key].obj = this.currentPoints[key].obj
              .setText(newObj.t)
              .setPrice(newObj.p)
              .setTime(newObj.ts)
              .setDirection(newObj.d);
          } else {
            newPoints[key].obj = this.chart
              .createExecutionShape()
              .setTextColor("rgb(255, 255, 255)")
              .setText(newObj.t)
              .setPrice(newObj.p)
              .setTime(newObj.ts)
              .setDirection(newObj.d);
          }
        }
      });
      this.currentPoints = newPoints;
    },
    prepareChart: function() {
      this.tvWidget.onChartReady(() => {
        this.chart = this.tvWidget.activeChart();
        this.chart.onIntervalChanged().subscribe(
          null,
          function(interval, timeframeObj) {
            timeframeObj.timeframe = this.findTimeframe(interval);
            this.currentInterval = interval;
            if (!this.disableSave) {
              this.$http
                  .get(
                      `/api/symbols/${this.symbol.slug}/charts/${this.currentIntervalNormalized}`
                  )
                  .then(
                      function (response) {
                        if (response.data.state) {
                          Object.keys(this.currentPoints).forEach((key) => {
                            if (this.currentPoints[key].obj) {
                              this.currentPoints[key].obj.remove();
                            }
                            delete this.currentPoints[key];
                          });
                          this.tvWidget.load(response.data.state);
                          setTimeout(this.updatePoints, 2000);
                        }
                      }.bind(this)
                  )
                  .catch(
                      function (reason) {
                        console.log(reason);
                      }.bind(this)
                  );
            }
          }.bind(this)
        );
        this.currentPoints = {};
        this.updatePoints();
        this.saveChartPeriodically();
      });
    },
    createWidget: function(datafeed, chartState) {
      const widgetOptions = {
        symbol: this.symbol,
        interval: this.currentInterval,
        fullscreen: this.fullscreen,
        container_id: this.containerId,
        library_path: this.libraryPath,
        locale: "en",
        timezone: localStorage.getItem("timezoneStr") || "Etc/UTC",
        autosize: this.autosize,
        datafeed: datafeed,
        disabled_features: [
          "header_symbol_search",
          "symbol_search_hot_key",
          "header_compare",
        ],
        timeframe: this.findTimeframe(this.currentInterval),
        theme: "Dark",
        saved_data: chartState,
        load_last_chart: true,
      };
      const tvWidget = new widget(widgetOptions);

      if (!this.disableSave) {
        tvWidget.headerReady().then(
            function () {
              const saveButton = tvWidget.createButton();
              saveButton.setAttribute("title", "Save chart");
              saveButton.addEventListener("click", this.saveChart);
              saveButton.innerHTML = '<span style="cursor: pointer">Save</span>';
            }.bind(this)
        );
      }

      this.tvWidget = tvWidget;
      this.prepareChart();
    },
    saveChartPeriodically() {
      if (this.saveTimer) {
        clearTimeout(this.saveTimer);
        this.saveTimer = null;
      }
      this.saveTimer = setTimeout(() => {
        this.saveChart();
        this.saveChartPeriodically();
      }, 60000);
    },
    saveChart() {
      if (!this.tvWidget || this.disableSave) {
        return;
      }
      /* https://github.com/tradingview/charting_library/wiki/Widget-Methods#savecallback */
      this.tvWidget.save((i) => {
        this.$http
          .post(
            `/api/symbols/${this.symbol.slug}/charts/${this.currentIntervalNormalized}`,
            {
              state: i,
            }
          )
          .then(
            function(response) {
              console.log("Chart saved");
            }.bind(this)
          )
          .catch(
            function(reason) {
              console.log(reason);
            }.bind(this)
          );
      });
    },
  },
  tvWidget: null,
  mounted() {
    /* load exchanges */
    if (this.interval) {
      this.currentInterval = this.interval;
    }
    const Datafeed = {
      onReady: (callback) => {
        const configurationData = {
          supported_resolutions: this.resolutions,
          exchanges: [],
          symbols_types: [],
        };
        setTimeout(() => callback(configurationData));
      },
      searchSymbols: (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback
      ) => {
        onResultReadyCallback([]);
      },
      resolveSymbol: (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback
      ) => {
        /* https://github.com/tradingview/charting_library/wiki/Symbology#symbolinfo-structure */
        setTimeout(() =>
          onSymbolResolvedCallback({
            name: this.symbol.slug.toUpperCase(),
            ticker: this.symbol.slug.toUpperCase(),
            description: "",
            type: "crypto",
            session: "24x7",
            exchange: "Binance",
            timezone: "Etc/UTC",
            supported_resolutions: this.resolutions,
            pricescale: new Decimal(1).div(this.symbol.pf_tick_size).toNumber(),
            minmov: 1,
            has_intraday: true,
            has_daily: true,
            has_weekly_and_monthly: true,
            intraday_multipliers: [
              "1",
              "3",
              "5",
              "15",
              "30",
              "60",
              "120",
              "240",
              "360",
              "480",
              "720",
            ],
          })
        );
      },
      getBars: (
        symbolInfo,
        resolution,
        from,
        to,
        onHistoryCallback,
        onErrorCallback,
        firstDataRequest
      ) => {
        /*
        from: unix timestamp, leftmost required bar time
        to: unix timestamp, rightmost required bar time
        firstDataRequest: boolean to identify the first call of this method.
        When it is set to true you can ignore to (which depends on browser's Date.now()) and return bars up to the latest bar.
        */
        let q = `/api/symbols/${
          this.symbol.slug
        }/klines?interval=${this.translateResolution(resolution)}`;
        if (from) {
          q = q + `&start_time=${from}`;
        }
        if (to) {
          q = q + `&end_time=${to}`;
        }
        this.$http
          .get(q)
          .then(
            function(response) {
              onHistoryCallback(
                response.data.results.map(function(i) {
                  return {
                    time: i[0] * 1000,
                    open: parseFloat(i[1]),
                    high: parseFloat(i[2]),
                    low: parseFloat(i[3]),
                    close: parseFloat(i[4]),
                    volume: parseFloat(i[5]),
                  };
                }),
                {
                  noData: !response.data.results.length,
                }
              );
            }.bind(this)
          )
          .catch(function(reason) {
            console.log(reason);
            onErrorCallback("Error");
          });
      },
      subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        onResetCacheNeededCallback
      ) => {
        if (!this.isLive) {
          return;
        }
        const token = localStorage.getItem("accessToken");
        const loc = window.location;
        let protocol = "ws:";
        if (loc.protocol === "https:") {
          protocol = "wss:";
        }
        const socket = new WebSocket(
          `${protocol}//${loc.host}/ws/symbols/${this.symbol.slug}/klines`
        );
        socket.addEventListener(
          "message",
          function(event) {
            const data = JSON.parse(event.data);
            onRealtimeCallback({
              time: data[0] * 1000,
              open: parseFloat(data[1]),
              high: parseFloat(data[2]),
              low: parseFloat(data[3]),
              close: parseFloat(data[4]),
              volume: parseFloat(data[5]),
            });
          }.bind(this)
        );
        socket.addEventListener(
          "open",
          function(event) {
            socketsMap[subscribeUID] = socket;
            socket.send(
              JSON.stringify({
                token: token,
                interval: this.translateResolution(resolution),
                symbol: symbolInfo.ticker,
              })
            );
          }.bind(this)
        );
      },
      unsubscribeBars: (subscribeUID) => {
        if (this.isLive) {
          socketsMap[subscribeUID].close();
        }
      },
    };

    if (!this.disableSave) {
      this.$http
          .get(
              `/api/symbols/${this.symbol.slug}/charts/${this
                  .currentIntervalNormalized || "x"}`
          )
          .then(
              function (response) {
                let chartState = null;
                if (response.data.state) {
                  this.currentInterval =
                      this.translateResolutionToTV(response.data.interval) || "D";
                  chartState = response.data.state;
                } else {
                  this.currentInterval = this.currentInterval || "D";
                }
                this.createWidget(Datafeed, chartState);
              }.bind(this)
          )
          .catch(
              function (reason) {
                console.log(reason);
                this.currentInterval = this.currentInterval || "D";
                this.createWidget(Datafeed);
              }.bind(this)
          )
    } else {
      this.currentInterval = this.currentInterval || "D";
      this.createWidget(Datafeed);
    }
  },
  destroyed() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.TVChartContainer {
  height: calc(100vh - 30px);
}
</style>
