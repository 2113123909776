<template>
  <div>
    Trade
    <b-link :to="`/trades/${data.trade_token}`">{{ data.trade_token }}</b-link>
    on symbol
    <b-link :to="`/symbols/${data.symbol_slug}`"
      >{{ data.symbol_base }}{{ data.symbol_quote }}</b-link
    >
    stopped with an error:
    <div class="detail-row">{{ data.data.message }}</div>
  </div>
</template>

<script>
export default {
  name: "TradeErrorActivity",
  props: {
    data: Object,
  }
};
</script>

<style></style>
