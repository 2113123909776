<template>
  <div class="trade-row summary">
    <table>
      <tr>
        <td>Direction</td>
        <td>{{ isLong ? "Long" : "Short" }}</td>
      </tr>
      <tr>
        <td>Max position</td>
        <td>{{ maxPosition.toSD(4) }} {{ symbol.quote }}</td>
      </tr>
      <tr>
        <td>Reward/Risk</td>
        <td v-if="hasRisk">{{ maxProfit.div(lossLimit).toSD(4) }}</td>
        <td v-else>-</td>
      </tr>
      <tr>
        <td>At risk</td>
        <td v-if="hasRisk">{{ lossLimit.toSD(4) }} {{ symbol.quote }}</td>
        <td v-else>-</td>
      </tr>
      <tr>
        <td>Max profit</td>
        <td v-if="maxProfit">{{ maxProfit.toSD(4) }} {{ symbol.quote }}</td>
        <td v-else>-</td>
      </tr>
      <tr>
        <td>Min profit</td>
        <td>{{ minProfit.toSD(4) }} {{ symbol.quote }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import Decimal from "decimal.js";

export default {
  name: "TradeSummary",
  props: {
    state: Object,
    symbol: Object,
    isLong: Boolean,
  },
  computed: {
    startAsk: function() {
      if (this.state && this.state.options && this.state.options.start_price) {
        if (this.isRaw) {
          return new Decimal(this.state.options.start_price);
        }
        return this.state.options.start_price;
      }
      return this.symbol.ticker.ask;
    },
    maxPosition: function() {
      let q = new Decimal(0);
      this.entries.forEach((i) => {
        q = q.plus(i.quantity.mul(i.price || this.symbol.ticker.ask));
      });
      if (q.gt(new Decimal(0))) {
        return q;
      }
    },
    hasRisk: function() {
      if (this.state && this.state.meta && this.state.meta.stopped_at) {
        return false;
      }
      return this.lossLimit.gt(new Decimal("0"));
    },
    isRaw: function() {
      return typeof this.state.entries[0].quantity === "string";
    },
    entries: function() {
      if (this.isRaw) {
        return this.state.entries.map((i) => {
          let price =
            i.type == "market"
              ? this.startAsk
              : i.price && new Decimal(i.price);
          let wasFilled = false;
          if (i.order && i.order.status == "FILLED") {
            price = new Decimal(i.order.filled_quote).div(
              new Decimal(i.order.filled_base)
            );
            wasFilled = true;
          }
          return {
            quantity: new Decimal(i.quantity),
            price: price,
            wasFilled: wasFilled,
          };
        });
      }
      return this.state.entries;
    },
    exits: function() {
      if (this.isRaw) {
        return this.state.exits.map((i) => {
          if (i.order && i.order.status == "FILLED") {
            return {
              quantityBase: new Decimal(i.order.filled_base),
              quantityQuote: new Decimal(i.order.filled_quote),
              quantity: new Decimal(i.quantity),
              price: new Decimal(i.order.filled_quote).div(
                new Decimal(i.order.filled_base)
              ),
              wasFilled: true,
            };
          }
          return {
            quantity: new Decimal(i.quantity),
            price: i.price && new Decimal(i.price),
            wasFilled: false,
          };
        });
      }
      return this.state.exits;
    },
    stop: function() {
      if (!this.isRaw) {
        return {
          ...this.state.stop,
          price: new Decimal(this.state.stop.price),
          distance:
            this.state.stop.distance && new Decimal(this.state.stop.distance),
        };
      }
      return this.state.stop;
    },
    entryCompleted: function() {
      if (this.isRaw) {
        let completed = false;
        this.exits.forEach((i) => {
          if (i.wasFilled) {
            completed = true;
          }
        });
        return completed;
      }
      return false;
    },
    lossLimit: function() {
      /* if exits filled - should count only filled entries, filled exits and stop */
      let quoteSpent = new Decimal(0);
      let baseTotal = new Decimal(0);
      let quoteGot = new Decimal(0);
      if (this.entryCompleted) {
        this.entries.forEach((i) => {
          if (i.wasFilled) {
            quoteSpent = quoteSpent.plus(
              (i.price || this.symbol.ticker.ask).mul(i.quantity)
            );
            baseTotal = baseTotal.plus(i.quantity);
          }
        });
        this.exits.forEach((i) => {
          if (i.wasFilled) {
            quoteGot = quoteGot.plus(i.quantityQuote);
            baseTotal = baseTotal.minus(i.quantityBase);
          }
        });
        quoteGot = quoteGot.plus(
          baseTotal.mul(
            this.state.meta.stop
              ? new Decimal(this.state.meta.stop)
              : this.stop.price
          )
        );
      } else {
        /* All entries filled, sold at stop, plus paid commission */
        this.entries.forEach((i) => {
          quoteSpent = quoteSpent.plus(
            (i.type == "market" ? this.symbol.ticker.ask : i.price).mul(
              i.quantity
            )
          );
          baseTotal = baseTotal.plus(i.quantity);
        });
        quoteGot = baseTotal.mul(this.stop.price);
      }
      const commission = quoteSpent.plus(quoteGot).mul(this.symbol.commission);
      return this.isLong
        ? quoteSpent.minus(quoteGot).plus(commission)
        : quoteGot.minus(quoteSpent).plus(commission);
    },
    maxProfit: function() {
      /* All entries + all exits */
      if (!this.exits || !this.exits.length) {
        return null;
      }

      let quoteSpent = new Decimal(0);
      let baseTotal = new Decimal(0);
      this.entries.forEach((i) => {
        quoteSpent = quoteSpent.plus(
          (i.type == "market" ? this.symbol.ticker.ask : i.price).mul(
            i.quantity
          )
        );
        baseTotal = baseTotal.plus(i.quantity);
      });
      let quoteGot = new Decimal(0);
      this.exits.forEach((i) => {
        quoteGot = quoteGot.plus(
          baseTotal
            .mul(i.quantity)
            .div(new Decimal(100))
            .mul(i.price)
        );
      });
      const commission = quoteSpent.plus(quoteGot).mul(this.symbol.commission);
      return this.isLong
        ? quoteGot.minus(quoteSpent).minus(commission)
        : quoteSpent.minus(quoteGot).minus(commission);
    },
    minProfit: function() {
      /* first entry, first exit + stop */
      const entry = this.entries[0];
      const ex = this.exits[0];
      const entryPrice =
        entry.type == "market" ? this.symbol.ticker.ask : entry.price;
      const quoteSpent = entry.quantity.mul(entryPrice);
      let quoteGot = this.exits[0].quantity
        .mul(entry.quantity)
        .div(new Decimal(100))
        .mul(this.exits[0].price);
      if (this.stop.type == "trailing") {
        /* dynamic stop price */
        quoteGot = quoteGot.plus(
          new Decimal(100)
            .minus(this.exits[0].quantity)
            .mul(entry.quantity)
            .div(new Decimal(100))
            .mul(
              Decimal.max(this.stop.price, ex.price.minus(this.stop.distance))
            )
        );
      } else if (this.stop.type == "ladder") {
        /* First entry, first exit, stop at the first entry */
        quoteGot = quoteGot.plus(
          new Decimal(100)
            .minus(this.exits[0].quantity)
            .mul(entry.quantity)
            .div(new Decimal(100))
            .mul(entryPrice)
        );
      } else {
        /* first entry, first exit, stop */
        quoteGot = quoteGot.plus(
          new Decimal(100)
            .minus(this.exits[0].quantity)
            .mul(entry.quantity)
            .div(new Decimal(100))
            .mul(this.stop.price)
        );
      }
      const commission = quoteSpent.plus(quoteGot).mul(this.symbol.commission);
      return Decimal.max(
        this.isLong
          ? quoteGot.minus(quoteSpent).minus(commission)
          : quoteSpent.minus(quoteGot).minus(commission),
        this.lossLimit.mul(new Decimal("-1"))
      );
    },
  },
};
</script>

<style></style>
