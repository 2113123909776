<template>
  <div class="forms-row">
    <b-card header="General">
      <b-form-group label="Timezone" label-for="b-timezone">
        <b-form-select
          :options="timezoneOptions"
          :value="currentTimezone"
          v-on:change="onTimezoneChange"
          id="b-timezone"
        ></b-form-select>
      </b-form-group>
    </b-card>
    <b-card header="Binance access">
      <spinner v-if="!accessTokensLoaded" />
      <template v-else>
        <b-form-group label="Access key" label-for="b-access-key">
          <b-form-input
            v-model="binanceAccessKey"
            id="b-access-key"
            autocomplete="off"
          ></b-form-input>
          <input-errors :errors="binanceAccessKeyErrors" />
        </b-form-group>
        <b-form-group label="Secret key" label-for="b-secret-key">
          <b-form-input
            v-model="binanceSecretKey"
            id="b-secret-key"
            autocomplete="off"
          ></b-form-input>
          <input-errors :errors="binanceSecretKeyErrors" />
        </b-form-group>
        <b-button
          variant="primary"
          v-on:click.prevent="onUpdateBinanceKeys"
          :disabled="binanceKeysLoading"
          >Update</b-button
        >
      </template>
    </b-card>
    <b-card header="Telegram bot">
      <spinner v-if="!telegramState" />
      <div v-if="telegramState && !telegramState.bot_username">
        <p>
          In order to receive notification on telegram,<br />follow instructions
          below.
        </p>
        <p>
          Create a new bot by messaging "/newbot" to
          <a href="https://t.me/BotFather" target="blank_">@BotFather</a>.
        </p>
        <p>Then submit the bot token:</p>

        <b-form-group label="Bot token" label-for="b-bot-token">
          <b-form-input
            v-model="telegramBotToken"
            id="b-bot-token"
            autocomplete="off"
          ></b-form-input>
          <input-errors :errors="telegramBotTokenErrors" />
        </b-form-group>
        <b-button
          variant="primary"
          v-on:click.prevent="onSubmitTelegramBotToken"
          :disabled="telegramLoading"
          >Next</b-button
        >
      </div>
      <div
        v-if="
          telegramState &&
            telegramState.bot_username &&
            !telegramState.activated
        "
      >
        <p>
          In order to connect your bot,<br />message "activate:{{
            telegramState.sub_token
          }}" to
          <a
            :href="`https://t.me/${telegramState.bot_username}`"
            target="blank_"
            >{{ telegramState.bot_username }}
          </a>
        </p>
        <b-button
          variant="primary"
          v-on:click.prevent="onReloadTelegramBotState"
          :disabled="telegramLoading"
          >Next</b-button
        >
        <b-button
          variant="secondary"
          v-on:click.prevent="onCancelActivateTelegram"
          :disabled="telegramLoading"
          class="ml-2"
          >Cancel</b-button
        >
      </div>
      <div v-if="telegramState && telegramState.activated">
        <b-icon-circle-fill scale="0.8" variant="success" />
        {{ telegramState.bot_username }}
        <span class="inline-button" v-on:click="onTestTelegram">Test</span>
        <span class="inline-button" v-on:click="onDisconnectTelegram"
          >Disconnect</span
        >
        <b-form-group label="Subscriptions:" class="mt-4">
          <b-form-checkbox-group
            id="telegram-subscriptions"
            v-model="telegramState.subscriptions"
            name="telegram-subscriptions"
            stacked
          >
            <b-form-checkbox value="t_done">Trade completed</b-form-checkbox>
            <b-form-checkbox value="o_filled">Order filled</b-form-checkbox>
            <b-form-checkbox value="a_price">Price alert</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
        <b-button
          variant="primary"
          v-on:click.prevent="onSaveTelegramSubscriptions"
          :disabled="telegramLoading"
          >Save</b-button
        >
      </div>
    </b-card>
    <b-card header="Access tokens">
      <spinner v-if="!accessTokensLoaded" />
      <template v-else>
        <b-table :items="accessTokens" :fields="accessTokenFields">
          <template #cell(token_id)="data"> {{ data.value }}:* </template>
          <template #cell(is_api)="data"
            ><b-icon-check v-if="data.value"
          /></template>
          <template #cell(is_current)="data"
            ><span v-if="data.value">(current)</span
            ><b-icon-trash
              v-else
              v-on:click="onRemoveToken(data.item.token_id, data.item.is_api)"
              style="cursor: pointer;"
          /></template>
        </b-table>
        <pre v-if="accessToken">Token: {{ accessToken }}</pre>
        <b-button
          variant="primary"
          v-on:click.prevent="onTokenGenerate"
          :disabled="accessTokenLoading"
          >Generate an API key</b-button
        >
      </template>
    </b-card>
    <b-card header="Change password">
      <b-form-group label="Password" label-for="password1">
        <b-form-input
          v-model="password1"
          id="password1"
          type="password"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Repeat password" label-for="password2">
        <b-form-input
          v-model="password2"
          id="password2"
          type="password"
        ></b-form-input>
        <input-errors :errors="passwordErrors" />
      </b-form-group>
      <b-button
        variant="primary"
        v-on:click.prevent="onChangePassword"
        :disabled="passwordLoading"
        >Change</b-button
      >
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BFormSelect,
} from "bootstrap-vue";
import InputErrors from "../components/lib/InputErrors";
import Spinner from "../components/lib/Spinner";

export default {
  name: "Settings",
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    InputErrors,
    Spinner,
    BFormSelect,
  },
  data: function() {
    return {
      binanceKeysLoading: false,
      binanceAccessKey: null,
      binanceAccessKeyErrors: null,
      binanceSecretKey: null,
      binanceSecretKeyErrors: null,
      binanceLoaded: false,
      accessTokens: [],
      accessTokensLoaded: false,
      password1: null,
      password2: null,
      passwordErrors: null,
      passwordLoading: false,
      accessToken: null,
      accessTokenLoading: false,
      accessTokenFields: [
        { key: "token_id", label: "Token" },
        { key: "created", label: "Created" },
        { key: "is_api", label: "API" },
        { key: "is_current", label: "Remove" },
      ],
      telegramState: null,
      telegramBotToken: "",
      telegramBotTokenErrors: null,
      telegramLoading: false,
      timezones: {
        "Etc/UTC": 0,
        "Africa/Cairo": 2,
        "Africa/Johannesburg": 2,
        "Africa/Lagos": 1,
        "America/Argentina/Buenos_Aires": -3,
        "America/Bogota": -5,
        "America/Caracas": -4,
        "America/Chicago": -5,
        "America/El_Salvador": -6,
        "America/Juneau": -8,
        "America/Lima": -5,
        "America/Los_Angeles": -7,
        "America/Mexico_City": -5,
        "America/New_York": -4,
        "America/Phoenix": -7,
        "America/Santiago": -4,
        "America/Sao_Paulo": -3,
        "America/Toronto": -4,
        "America/Vancouver": -7,
        "Asia/Almaty": +6,
        "Asia/Ashkhabad": +5,
        "Asia/Bahrain": +3,
        "Asia/Bangkok": +7,
        "Asia/Chongqing": +8,
        "Asia/Dubai": +4,
        "Asia/Ho_Chi_Minh": +7,
        "Asia/Hong_Kong": +8,
        "Asia/Jakarta": +7,
        "Asia/Jerusalem": +3,
        "Asia/Kuwait": +3,
        "Asia/Muscat": +4,
        "Asia/Qatar": +3,
        "Asia/Riyadh": +3,
        "Asia/Seoul": +9,
        "Asia/Shanghai": +8,
        "Asia/Singapore": +8,
        "Asia/Taipei": +8,
        "Asia/Tokyo": +9,
        "Atlantic/Reykjavik": 0,
        "Australia/Brisbane": +10,
        "Australia/Perth": +8,
        "Australia/Sydney": +10,
        "Europe/Athens": +3,
        "Europe/Belgrade": +2,
        "Europe/Berlin": +2,
        "Europe/Copenhagen": +2,
        "Europe/Helsinki": +3,
        "Europe/Istanbul": +3,
        "Europe/London": 1,
        "Europe/Luxembourg": +2,
        "Europe/Madrid": 2,
        "Europe/Moscow": +3,
        "Europe/Oslo": +2,
        "Europe/Paris": +2,
        "Europe/Riga": +3,
        "Europe/Rome": 2,
        "Europe/Stockholm": +2,
        "Europe/Tallinn": +3,
        "Europe/Vilnius": +3,
        "Europe/Warsaw": +2,
        "Europe/Zurich": +2,
        "Pacific/Honolulu": -10,
        "Pacific/Norfolk": +11,
      },
      currentTimezone: "Etc/UTC",
    };
  },
  computed: {
    timezoneOptions: function() {
      return Object.entries(this.timezones)
        .sort((a, b) => (a[1] > b[1] ? 1 : a[1] < b[1] ? -1 : 0))
        .map((i) => {
          const sign = i[1] >= 0 ? "+" : "";
          return {
            text: `${i[0]} (${sign}${i[1]})`,
            value: i[0],
          };
        });
    },
  },
  methods: {
    onTokenGenerate: function() {
      if (this.accessTokenLoading) {
        return;
      }
      NProgress.start();
      this.accessTokenLoading = true;
      this.$http
        .post("/api/access-tokens")
        .then(
          function(response) {
            this.accessTokens = [...this.accessTokens, response.data];
            this.accessToken = response.data.token;
          }.bind(this)
        )
        .catch(
          function(reason) {
            this.$bvToast.toast("Server error", {
              title: "Error",
              variant: "danger",
              autoHideDelay: 4000,
              toastClass: "mt-4",
            });
          }.bind(this)
        )
        .finally(
          function() {
            this.accessTokenLoading = false;
            NProgress.done();
          }.bind(this)
        );
    },
    onRemoveToken: function(token_id, is_api) {
      if (!is_api) {
        this.removeToken(token_id);
        return;
      }
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to remove the API token?", {
          size: "sm",
          okVariant: "danger",
          okTitle: "Remove",
          cancelTitle: "Keep",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.removeToken(token_id);
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    removeToken: function(token_id) {
      if (this.accessTokenLoading) {
        return;
      }
      NProgress.start();
      this.accessTokenLoading = true;
      this.$http
        .delete(`/api/access-tokens/${token_id}`)
        .then(
          function(response) {
            this.accessTokens = [
              ...this.accessTokens.filter((i) => i.token_id != token_id),
            ];
          }.bind(this)
        )
        .catch(
          function(reason) {
            this.$bvToast.toast("Server error", {
              title: "Error",
              variant: "danger",
              autoHideDelay: 4000,
              toastClass: "mt-4",
            });
          }.bind(this)
        )
        .finally(
          function() {
            this.accessTokenLoading = false;
            NProgress.done();
          }.bind(this)
        );
    },
    onUpdateBinanceKeys: function() {
      this.binanceKeysLoading = true;
      NProgress.start();
      this.$http
        .put("/api/binance/keys", {
          access_key: this.binanceAccessKey,
          secret_key: this.binanceSecretKey,
        })
        .then(
          function(response) {
            this.binanceSecretKey = "***";
            this.binanceAccessKeyErrors = null;
            this.binanceSecretKeyErrors = null;
          }.bind(this)
        )
        .catch(
          function(reason) {
            if (reason.response.status == 422) {
              this.binanceAccessKeyErrors = reason.response.data.access_key;
              this.binanceSecretKeyErrors = reason.response.data.secret_key;
            } else {
              this.$bvToast.toast("Server error", {
                title: "Error",
                variant: "danger",
                autoHideDelay: 4000,
                toastClass: "mt-4",
              });
              this.binanceAccessKeyErrors = null;
              this.binanceSecretKeyErrors = null;
            }
          }.bind(this)
        )
        .finally(
          function() {
            this.binanceKeysLoading = false;
            NProgress.done();
          }.bind(this)
        );
    },
    onChangePassword: function() {
      if (this.password1 != this.password2) {
        this.passwordErrors = ["Passwords should match."];
        return;
      }
      this.passwordLoading = true;
      NProgress.start();
      this.$http
        .put("/api/password", {
          password: this.password1,
        })
        .then(
          function(response) {
            this.password1 = "";
            this.password2 = "";
            this.passwordErrors = null;
            this.$bvToast.toast("Success", {
              title: "Password was changed",
              variant: "success",
              autoHideDelay: 2000,
              toastClass: "mt-4",
            });
          }.bind(this)
        )
        .catch(
          function(reason) {
            if (reason.response.status == 422) {
              this.passwordErrors = reason.response.data.password;
            } else {
              this.passwordErrors = null;
              this.$bvToast.toast("Server error", {
                title: "Error",
                variant: "danger",
                autoHideDelay: 4000,
                toastClass: "mt-4",
              });
            }
          }.bind(this)
        )
        .finally(
          function() {
            this.passwordLoading = false;
            NProgress.done();
          }.bind(this)
        );
    },
    onSubmitTelegramBotToken: function() {
      if (!this.telegramBotToken || !this.telegramBotToken.length) {
        this.telegramBotTokenErrors = ["Telegram token can not be empty."];
        return;
      }
      this.telegramLoading = true;
      NProgress.start();
      this.$http
        .post("/api/telegram", {
          bot_token: this.telegramBotToken,
        })
        .then(
          function(response) {
            this.telegramState = response.data;
          }.bind(this)
        )
        .catch(
          function(reason) {
            if (reason.response.status == 422) {
              this.telegramBotTokenErrors = reason.response.data.bot_token;
            } else {
              this.passwordErrors = null;
              this.$bvToast.toast("Server error", {
                title: "Error",
                variant: "danger",
                autoHideDelay: 4000,
                toastClass: "mt-4",
              });
            }
          }.bind(this)
        )
        .finally(
          function() {
            this.telegramLoading = false;
            NProgress.done();
          }.bind(this)
        );
    },
    onReloadTelegramBotState: function() {
      this.telegramLoading = true;
      NProgress.start();
      this.$http
        .get("/api/telegram")
        .then(
          function(response) {
            this.telegramState = response.data;
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        )
        .finally(
          function() {
            this.telegramLoading = false;
            NProgress.done();
          }.bind(this)
        );
    },
    onTestTelegram: function() {
      NProgress.start();
      this.$http
        .post("/api/telegram/message", { message: "It works!" })
        .then(function(response) {}.bind(this))
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        )
        .finally(
          function() {
            NProgress.done();
          }.bind(this)
        );
    },
    disconnectTelegramBot: function() {
      this.$http
        .delete("/api/telegram")
        .then(
          function(response) {
            this.telegramState = {
              ...this.telegramState,
              bot_username: null,
              activated: false,
            };
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        );
    },
    onDisconnectTelegram: function() {
      this.$bvModal
        .msgBoxConfirm(
          `${this.telegramState.bot_username} will be disconnected. Message "/deletebot" to BotFather if you would like to delete it.`,
          {
            size: "sm",
            okVariant: "danger",
            okTitle: "Disconnect",
            cancelTitle: "Cancel",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.disconnectTelegramBot();
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    onCancelActivateTelegram: function() {
      this.disconnectTelegramBot();
    },
    onSaveTelegramSubscriptions: function() {
      this.telegramLoading = true;
      NProgress.start();
      this.$http
        .post("/api/telegram", {
          subscriptions: this.telegramState.subscriptions,
        })
        .then(
          function(response) {
            this.telegramState = response.data;
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        )
        .finally(
          function() {
            this.telegramLoading = false;
            NProgress.done();
          }.bind(this)
        );
    },
    onTimezoneChange: function(timezone) {
      localStorage.setItem("timezoneStr", timezone);
      localStorage.setItem("timezoneNum", this.timezones[timezone]);
      this.currentTimezone = timezone;
    },
  },
  mounted: function() {
    this.currentTimezone =
      localStorage.getItem("timezoneStr") || this.currentTimezone;
    this.$http
      .get("/api/binance/keys")
      .then(
        function(response) {
          this.binanceAccessKey = response.data.access_key;
          this.binanceSecretKey = response.data.secret_key;
        }.bind(this)
      )
      .catch(
        function(reason) {
          console.log(reason);
        }.bind(this)
      )
      .finally(
        function() {
          this.binanceLoaded = true;
        }.bind(this)
      );
    this.$http
      .get("/api/access-tokens")
      .then(
        function(response) {
          this.accessTokens = response.data.results.map((i) => {
            return {
              ...i,
              is_current: i.token_id == response.data.token_id,
            };
          });
        }.bind(this)
      )
      .catch(
        function(reason) {
          console.log(reason);
        }.bind(this)
      )
      .finally(
        function() {
          this.accessTokensLoaded = true;
        }.bind(this)
      );
    this.$http
      .get("/api/telegram")
      .then(
        function(response) {
          this.telegramState = response.data;
        }.bind(this)
      )
      .catch(
        function(reason) {
          console.log(reason);
        }.bind(this)
      )
      .finally(function() {}.bind(this));
  },
};
</script>

<style></style>
