<template>
  <div class="pages-wrapper">
    <spinner v-if="!pages" />
    <template v-else>
      <div class="add-page">
        <span v-on:click="onCreatePage">Add page <b-icon-file-plus /></span>
      </div>
      <nothing-found v-if="!this.pages.length" />
      <ul v-else>
        <li v-for="i in this.pages" v-bind:key="i.page_id">
          <b-link :to="`/pages/${i.page_id}`">{{ i.title }}</b-link>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { BFormInput, BLink } from "bootstrap-vue";
import Spinner from "../components/lib/Spinner";
import NothingFound from "../components/lib/NothingFound";

export default {
  name: "Pages",
  data: function() {
    return {
      pages: null,
    };
  },
  components: {
    BFormInput,
    BLink,
    Spinner,
    NothingFound,
  },
  methods: {
    onCreatePage: function() {
      let pageTitle = null;
      this.$bvModal
        .msgBoxConfirm(
          [
            this.$createElement(
              "b-form-group",
              {
                props: {
                  label: "Page title",
                },
              },
              [
                this.$createElement("b-form-input", {
                  on: {
                    change: function(value) {
                      pageTitle = value;
                    },
                  },
                }),
              ]
            ),
          ],
          {
            title: `Create a new page`,
            okTitle: "Create",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value && pageTitle) {
            NProgress.start();
            this.$http
              .post("/api/pages", {
                title: pageTitle,
                text: "",
                weight: 0,
              })
              .then(
                function(response) {
                  this.pages = [...this.pages, response.data];
                }.bind(this)
              )
              .catch(
                function(reason) {
                  let errors = "Internal server error.";
                  if (
                    reason.response.status == 422 ||
                    reason.response.status == 400
                  ) {
                    errors = JSON.stringify(reason.response.data, null, 2);
                  }
                  const h = this.$createElement;
                  const $errors = h("pre", {}, errors);
                  this.$bvToast.toast([$errors], {
                    title: "Error creating a page",
                    variant: "danger",
                    autoHideDelay: 6000,
                    toastClass: "mt-4",
                  });
                }.bind(this)
              )
              .finally(
                function() {
                  NProgress.done();
                }.bind(this)
              );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted: function() {
    this.$http
      .get("/api/pages")
      .then(
        function(response) {
          this.pages = response.data.results;
        }.bind(this)
      )
      .catch(
        function(reason) {
          console.log(reason);
        }.bind(this)
      );
  },
};
</script>

<style></style>
