var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.trade)?_c('div',{staticClass:"trade-form"},[_c('h2',[_vm._v("Entries")]),_vm._l((_vm.trade.state.entries),function(item,index){return _c('div',{key:'en' + index,staticClass:"trade-row"},[(_vm.currentEditor == ("entries" + index + "price"))?_c('div',{staticClass:"form-row-edit"},[_c('div',{staticClass:"edit-header"},[_c('span',{on:{"click":_vm.onHideEditor}},[_vm._v("#"+_vm._s(index + 1)+" Entry - Edit price")]),_c('b-icon-chevron-up',{on:{"click":_vm.onHideEditor}})],1),_c('decimal-input',{attrs:{"title":"Price","value":_vm.currentValue,"unit":((_vm.symbol.quote) + " / " + (_vm.symbol.base)),"min":_vm.findEntryMinPrice(index),"max":_vm.findEntryMaxPrice(index),"step":_vm.priceStep},on:{"input":function($event){return _vm.onTradeEdit('entries', index, 'price', $event)}}}),_c('b-button',{attrs:{"size":"sm"},on:{"click":_vm.onSave}},[_vm._v("Save")])],1):[_c('span',{staticClass:"number"},[_vm._v("#"+_vm._s(index + 1))]),_c('span',{staticClass:"edit-content"},[_vm._v(_vm._s(item.type.toUpperCase())+" "+_vm._s(item.quantity)+" @ "),(item.type == 'market')?_c('span',[_vm._v("market")]):[(_vm.getIsEditable('entries', index, 'price'))?_c('span',{staticClass:"editable",on:{"click":function($event){_vm.onShowEditor(
                'entries',
                index,
                'price',
                _vm.toDecimal(_vm.trade.state.entries[index].price)
              )}}},[_vm._v(_vm._s(item.price))]):_c('span',[_vm._v(_vm._s(item.price))])]],2)]],2)}),(_vm.trade.state.exits.length)?[_c('h2',[_vm._v("Exits")]),_vm._l((_vm.trade.state.exits),function(item,index){return _c('div',{key:'ex' + index,staticClass:"trade-row"},[(_vm.currentEditor == ("exits" + index + "price"))?_c('div',{staticClass:"form-row-edit"},[_c('div',{staticClass:"edit-header"},[_c('span',{on:{"click":_vm.onHideEditor}},[_vm._v("#"+_vm._s(index + 1)+" Exit - Edit price")]),_c('b-icon-chevron-up',{on:{"click":_vm.onHideEditor}})],1),_c('decimal-input',{attrs:{"title":"Price","value":_vm.currentValue,"unit":((_vm.symbol.quote) + " / " + (_vm.symbol.base)),"min":_vm.findExitMinPrice(index),"max":_vm.findExitMaxPrice(index),"step":_vm.priceStep},on:{"input":function($event){return _vm.onTradeEdit('exits', index, 'price', $event)}}}),_c('b-button',{attrs:{"size":"sm"},on:{"click":_vm.onSave}},[_vm._v("Save")])],1):[_c('span',{staticClass:"number"},[_vm._v("#"+_vm._s(index + 1))]),_c('span',{staticClass:"edit-content"},[_vm._v(_vm._s(item.type.toUpperCase())+" "+_vm._s(item.quantity)+"% @ "),(_vm.getIsEditable('exits', index, 'price'))?_c('span',{staticClass:"editable",on:{"click":function($event){_vm.onShowEditor(
                'exits',
                index,
                'price',
                _vm.toDecimal(_vm.trade.state.exits[index].price)
              )}}},[_vm._v(_vm._s(item.price))]):_c('span',[_vm._v(_vm._s(item.price))])])]],2)}),_c('h2',[_vm._v("Stop")]),_c('div',{staticClass:"trade-row"},[(_vm.currentEditor == "stopprice")?_c('div',{staticClass:"form-row-edit"},[_c('div',{staticClass:"edit-header"},[_c('span',{on:{"click":_vm.onHideEditor}},[_vm._v("Stop - Edit price")]),_c('b-icon-chevron-up',{on:{"click":_vm.onHideEditor}})],1),_c('decimal-input',{attrs:{"title":"Price","value":_vm.currentValue,"unit":((_vm.symbol.quote) + " / " + (_vm.symbol.base)),"min":_vm.findStopMinPrice(),"max":_vm.findStopMaxPrice(),"step":_vm.priceStep},on:{"input":function($event){return _vm.onTradeEdit('stop', null, 'price', $event)}}}),_c('b-button',{attrs:{"size":"sm"},on:{"click":_vm.onSave}},[_vm._v("Save")])],1):[_c('span',{staticClass:"left-action"}),_c('span',{staticClass:"edit-content"},[_vm._v(_vm._s(_vm.trade.state.stop.type.toUpperCase())+" @ "),(_vm.getIsEditable('stop', null, 'price'))?_c('span',{staticClass:"editable",on:{"click":function($event){_vm.onShowEditor(
                'stop',
                null,
                'price',
                _vm.toDecimal(_vm.trade.state.stop.price)
              )}}},[_vm._v(_vm._s(_vm.trade.state.stop.price))]):_c('span',[_vm._v(_vm._s(_vm.trade.state.stop.price))])])]],2)]:_vm._e(),_c('h2',[_vm._v("Options")]),_c('div',{staticClass:"trade-row"},[(_vm.currentEditor == "optionsstart_price")?_c('div',{staticClass:"form-row-edit"},[_c('div',{staticClass:"edit-header"},[_c('span',{on:{"click":_vm.onHideEditor}},[_vm._v("Start price")]),_c('b-icon-chevron-up',{on:{"click":_vm.onHideEditor}})],1),_c('decimal-input',{attrs:{"title":"Price","value":_vm.currentValue,"unit":((_vm.symbol.quote) + " / " + (_vm.symbol.base)),"step":_vm.priceStep},on:{"input":function($event){return _vm.onTradeEdit('options', null, 'start_price', $event)}}}),_c('b-button',{attrs:{"size":"sm"},on:{"click":_vm.onSave}},[_vm._v("Save")])],1):[_c('span',{staticClass:"number"}),_c('span',{staticClass:"edit-content"},[_vm._v("Start price: "),(_vm.getIsEditable('options', null, 'start_price'))?_c('span',{staticClass:"editable",on:{"click":function($event){_vm.onShowEditor(
              'options',
              null,
              'start_price',
              _vm.toDecimal(_vm.trade.state.options.start_price)
            )}}},[_vm._v(_vm._s(_vm.trade.state.options.start_price || "-"))]):_c('span',[_vm._v(_vm._s(_vm.trade.state.options.start_price || "-"))])])]],2),_c('div',{staticClass:"trade-row"},[(_vm.currentEditor == "optionscancel_price")?_c('div',{staticClass:"form-row-edit"},[_c('div',{staticClass:"edit-header"},[_c('span',{on:{"click":_vm.onHideEditor}},[_vm._v("Cancel price")]),_c('b-icon-chevron-up',{on:{"click":_vm.onHideEditor}})],1),_c('decimal-input',{attrs:{"title":"Price","value":_vm.currentValue,"unit":((_vm.symbol.quote) + " / " + (_vm.symbol.base)),"step":_vm.priceStep},on:{"input":function($event){return _vm.onTradeEdit('options', null, 'cancel_price', $event)}}}),_c('b-button',{attrs:{"size":"sm"},on:{"click":_vm.onSave}},[_vm._v("Save")])],1):[_c('span',{staticClass:"number"}),_c('span',{staticClass:"edit-content"},[_vm._v("Cancel price: "),(_vm.getIsEditable('options', null, 'cancel_price'))?_c('span',{staticClass:"editable",on:{"click":function($event){_vm.onShowEditor(
              'options',
              null,
              'cancel_price',
              _vm.trade.state.options.cancel_price
                ? _vm.toDecimal(_vm.trade.state.options.cancel_price)
                : _vm.symbol.ticker.ask
            )}}},[_vm._v(_vm._s(_vm.trade.state.options.cancel_price || "-"))]):_c('span',[_vm._v(_vm._s(_vm.trade.state.options.cancel_price || "-"))])])]],2),_c('trade-summary',{attrs:{"state":_vm.currentState,"symbol":_vm.symbol,"is-long":_vm.isLong}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }