<template>
  <div class="item-row">
    <div class="chart-col" v-if="!symbol">
      <spinner />
    </div>
    <div class="chart-col" v-if="symbol && !isMobile">
      <tv-chart
        :symbol="symbol"
        v-show="!isDragging"
        :points="points"
        v-if="symbol && trade"
        :interval="interval"
        v-on:moved="onMoved"
        :disableSave="true"
        :isLive="isActive"
      />
    </div>
    <div
      class="separator-col"
      @mousedown="dragControl"
      v-if="symbol && !isMobile"
    ></div>
    <div
      v-if="symbol"
      class="control-col"
      ref="controlColumn"
      :style="{ width: controlColumnWidth + 'px' }"
    >
      <div class="header-row">
        <div class="header-content">
          {{ trade.trade_token }}

          <b-link :to="`/symbols/${symbol.slug}`"
            >{{ symbol.base }}{{ symbol.quote }}</b-link
          >

          <b-icon
            :icon="trade.has_note ? 'pencil-fill' : 'pencil'"
            class="action-icon"
            v-on:click="onEditNote"
            :scale="1"
          />

          <b-icon-code-slash
            class="action-icon"
            :scale="1.2"
            v-on:click="onShowRaw"
          />
          <b-icon-stop
            :scale="1.2"
            class="action-icon"
            v-if="trade.status == 'active'"
            v-on:click="onCancelTrade"
          />
          <b-icon-trash
            :scale="1.2"
            class="action-icon"
            v-if="trade.status == 'done' || trade.status == 'error'"
            v-on:click="onDeleteTrade"
          />

          <a
            :href="
              `https://www.binance.com/en/trade/${symbol.base}_${symbol.quote}`
            "
            v-if="trade.status == 'active'"
            >B</a
          >
          <a
            :href="
              `https://www.tradingview.com/symbols/${symbol.base}${symbol.quote}/?exchange=BINANCE`
            "
            v-if="trade.status == 'active'"
            >TV</a
          >
        </div>
        <plain-selector v-model="section" :options="sectionOptions" />
      </div>
      <div class="control-content">
        <component
          v-bind:is="getStatusComponent()"
          :trade="trade"
          :symbol="symbol"
          v-if="trade && symbol && section == 'status'"
          style="flex: 1;"
        />
        <component
          v-bind:is="getEditorComponent()"
          :trade="trade"
          :symbol="symbol"
          v-if="trade && symbol && section == 'edit'"
          style="flex: 1;"
          v-model="currentEdit"
        />
        <trade-log
          style="flex: 1;"
          v-if="trade && symbol && section == 'log'"
          :tradeToken="trade.trade_token"
          :symbol="symbol"
        />
      </div>
      <div class="footer-row">
        <trade-line
          v-if="!isDragging"
          :trade="trade"
          class="trade-line-bottom"
          :lineWidth="controlColumnWidth - 10"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Decimal from "decimal.js";
import TVChart from "../components/lib/TVChart";
import PlainSelector from "../components/lib/PlainSelector";
import TradeLine from "../components/lib/TradeLine";
import TradeLog from "../components/lib/TradeLog";
import TradeRaw from "../components/lib/TradeRaw";
import NoteEditor from "../components/lib/NoteEditor";
import { checkMobile } from "../utils";
import TradeEditor from "../components/trade/TradeEditor";
import TradeStatus from "../components/trade/TradeStatus";
import TradeCancel from "../components/trade/TradeCancel";
import Spinner from "../components/lib/Spinner";

export default {
  name: "Trade",
  data: function() {
    return {
      tradeInitial: null,
      symbol: null,
      isDragging: false,
      section: "status",
      controlColumnWidth: 300,
      isMobile: false,
      currentEdit: null,
    };
  },
  components: {
    "tv-chart": TVChart,
    PlainSelector,
    TradeLine,
    TradeLog,
    TradeRaw,
    NoteEditor,
    Spinner,
  },
  computed: {
    sectionOptions: function() {
      let options = [{ slug: "status", title: "Status" }];
      if (this.trade.status == "active") {
        options.push({ slug: "edit", title: "Edit" });
      }
      options.push({ slug: "log", title: "Log" });
      return options;
    },
    trade: function() {
      if (this.tradeInitial) {
        let result = this.tradeInitial;
        const tradeToken = this.tradeInitial.trade_token;
        if (this.$store.state.trader.trades[tradeToken]) {
          result = this.$store.state.trader.trades[tradeToken];
        }
        return result;
      }
    },
    traderName: function() {
      return "Trader";
    },
    stoppedAt: function () {
      return this.trade.state.meta && this.trade.state.meta['stopped_at'];
    },
    tradePeriod: function() {
      /* const endTS = this.stoppedAt || new Date().getTime(); */
      /* can not select past range */
      return (new Date().getTime() - this.trade.created) / 1000;
    },
    candlePeriod: function() {
      const period = this.tradePeriod;
      if (period < 3600) {
        return 60;
      } else if (period < 3600 * 24) {
        return 15 * 60;
      } else if (period < 3600 * 24 * 3) {
        return 60 * 60;
      } else {
        return 24 * 60 * 60;
      }
    },
    interval: function() {
      const period = this.candlePeriod;
      if (period == 60) {
        return "1";
      } else if (period == 15 * 60) {
        return "15";
      } else if (period == 60 * 60) {
        return "60";
      } else {
        return "D";
      }
    },
    isActive: function () {
      return this.trade.status == "active" || this.trade.status == "new";
    },
    points: function() {
      if (!this.trade) {
        return [];
      }
      const state = this.trade.state;
      /* merge edited element when editing */
      let points = [];
      let showLine = true;
      let price = null;
      if (state.meta) {
        if (state.meta.min_bid) {
          points.push({
            type: "execution",
            section: "trade",
            i: 0,
            title: `Start`,
            price: state.meta.min_bid,
            ts: this.trade.created / 1000,
            direction: "buy",
          });
        }
      }
      if (state.entries && state.entries.length) {
        state.entries.forEach((i, n) => {
          showLine = true;
          price = i.price && parseFloat(i.price);
          if (i.order && i.order.status == "FILLED") {
            price =
              parseFloat(i.order.filled_quote) /
              parseFloat(i.order.filled_base);
            points.push({
              type: "execution",
              section: "entries",
              i: n,
              title: `Entry ${n + 1} filled`,
              price: price,
              ts: i.order.updated / 1000,
              direction: "buy",
            });
          } else if (
            this.currentEdit &&
            this.currentEdit.section == "entries" &&
            this.currentEdit.index == n &&
            this.currentEdit.key == "price"
          ) {
            points.push({
              type: "order",
              section: "entries",
              i: n,
              title: `Edit Entry ${n + 1}`,
              quantity: parseFloat(i.quantity),
              price: parseFloat(this.currentEdit.value.toFixed()),
              market: false,
              color1: "rgb(136,135,57)",
              color2: "rgba(136,135,57,0.75)",
            });
            showLine = false;
          }
          if (showLine && price) {
            points.push({
              type: "position",
              section: "entries",
              i: n,
              title: `Entry ${n + 1}`,
              price: price,
              color1: "rgb(136,135,57)",
              color2: "rgba(136,135,57,0.75)",
            });
          }
        });
      }
      if (state.entry) {
        showLine = true;
        price = state.entry.price && parseFloat(state.entry.price);
        if (state.entry.order && state.entry.order.status == "FILLED") {
          price =
            parseFloat(state.entry.order.filled_quote) /
            parseFloat(state.entry.order.filled_base);
          points.push({
            type: "execution",
            section: "entry",
            i: 0,
            title: "Entry filled",
            price: price,
            ts: state.entry.order.updated / 1000,
            direction: "sell",
          });
        } else if (
          this.currentEdit &&
          this.currentEdit.section == "entry" &&
          this.currentEdit.key == "price"
        ) {
          points.push({
            type: "order",
            section: "entry",
            i: 0,
            title: "Edit Entry",
            quantity: parseFloat(state.entry.quantity),
            price: parseFloat(this.currentEdit.value.toFixed()),
            market: false,
            color1: "rgb(136,135,57)",
            color2: "rgba(136,135,57,0.75)",
          });
          showLine = false;
        }
        if (showLine && price) {
          points.push({
            type: "position",
            section: "entry",
            i: 0,
            title: `Entry`,
            price: price,
            color1: "rgb(136,135,57)",
            color2: "rgba(136,135,57,0.75)",
          });
        }
      }
      if (state.exits) {
        state.exits.forEach((i, n) => {
          if (i.type != "inf") {
            showLine = true;
            price = i.price && parseFloat(i.price);

            if (i.order && i.order.status == "FILLED") {
              price =
                parseFloat(i.order.filled_quote) /
                parseFloat(i.order.filled_base);
              points.push({
                type: "execution",
                section: "exits",
                i: n,
                title: `Exit ${n + 1} filled`,
                price: price,
                ts: i.order.updated / 1000,
                direction: "sell",
              });
            } else if (
              this.currentEdit &&
              this.currentEdit.section == "exits" &&
              this.currentEdit.index == n &&
              this.currentEdit.key == "price"
            ) {
              points.push({
                type: "order",
                section: "exits",
                i: n,
                title: `Edit Exit ${n + 1}`,
                quantity: parseFloat(i.quantity),
                price: parseFloat(this.currentEdit.value.toFixed()),
                market: false,
                color1: "rgb(50,102,51)",
                color2: "rgba(50,102,51,0.75)",
              });
              showLine = false;
            }
            if (showLine && price) {
              points.push({
                type: "position",
                section: "exits",
                i: n,
                title: `Exit ${n + 1}`,
                price: price,
                color1: "rgb(50,102,51)",
                color2: "rgba(50,102,51,0.75)",
              });
            }
          }
        });
      }
      if (state.targets) {
        state.targets.forEach((i, n) => {
          if (i.done) {
            points.push({
              type: "execution",
              section: "targets",
              i: n,
              title: `Crossed`,
              price: parseFloat(i.price),
              ts: i.ts / 1000,
              direction: "sell",
            });
          }
          points.push({
            type: "position",
            section: "targets",
            i: n,
            title: `Target ${n + 1}`,
            price: parseFloat(i.price),
          });
        });
      }
      const stop = state.stop;
      if (stop) {
        showLine = true;
        price = state.stop.price && parseFloat(state.stop.price);
        if (stop.order && stop.order.status == "FILLED") {
          price =
            parseFloat(stop.order.filled_quote) /
            parseFloat(stop.order.filled_base);
          points.push({
            type: "execution",
            section: "stop",
            i: 0,
            title: "Stop filled",
            price: price,
            ts: stop.order.updated / 1000,
            direction: "sell",
          });
        } else if (
          this.currentEdit &&
          this.currentEdit.section == "stop" &&
          this.currentEdit.key == "price"
        ) {
          points.push({
            type: "order",
            section: "stop",
            i: 0,
            title: `Edit Stop`,
            quantity: parseFloat("100"),
            price: parseFloat(this.currentEdit.value.toFixed()),
            market: false,
            color1: "rgb(120,52,52)",
            color2: "rgba(120,52,52,0.75)",
          });
          showLine = false;
        }
        const stopPrice = (state.meta && state.meta.stop) || stop.price;
        if (stopPrice && showLine) {
          points.push({
            type: "position",
            section: "stop",
            i: 0,
            title: `Stop`,
            price: parseFloat(stopPrice),
            color1: "rgb(120,52,52)",
            color2: "rgba(120,52,52,0.75)",
          });
        }
      }
      if (
        state.cancel &&
        this.trade.status == "done" &&
        state.meta.stopped_at
      ) {
        points.push({
          type: "execution",
          section: "trade",
          i: 1,
          title: `Cancel`,
          price: state.meta.bid,
          ts: state.meta.stopped_at / 1000,
          direction: "sell",
        });
      } else if (state.meta && state.meta.stopped_at) {
        points.push({
          type: "execution",
          section: "trade",
          i: 1,
          title: `Done`,
          price: state.meta.bid,
          ts: state.meta.stopped_at / 1000,
          direction: "sell",
        });
      }
      if (state.options) {
        if (
          this.currentEdit &&
          this.currentEdit.section == "options" &&
          this.currentEdit.key == "start_price"
        ) {
          points.push({
            type: "order",
            section: "options",
            i: "start_price",
            title: "Edit Start price",
            quantity: 100,
            price: parseFloat(this.currentEdit.value.toFixed()),
            market: false,
          });
        } else if (state.options.start_price) {
          points.push({
            type: "position",
            section: "options",
            i: "start_price",
            title: "Start price",
            price: parseFloat(state.options.start_price),
          });
        }
        if (
          this.currentEdit &&
          this.currentEdit.section == "options" &&
          this.currentEdit.key == "cancel_price"
        ) {
          points.push({
            type: "order",
            section: "options",
            i: "cancel_price",
            title: "Edit Cancel price",
            quantity: 100,
            price: parseFloat(this.currentEdit.value.toFixed()),
            market: false,
          });
        } else if (state.options.cancel_price) {
          points.push({
            type: "position",
            section: "options",
            i: "cancel_price",
            title: "Cancel price",
            price: parseFloat(state.options.cancel_price),
          });
        }
      }
      return points;
    },
  },
  methods: {
    getStatusComponent() {
      return TradeStatus;
    },
    getEditorComponent() {
      return TradeEditor;
    },
    getCancelComponent() {
      return TradeCancel;
    },
    dragControl: function(e) {
      this.isDragging = true;
      let dragX = e.clientX;
      let block = this.$refs.controlColumn;
      document.onmousemove = function onMouseMove(e) {
        block.style.width = block.offsetWidth + dragX - e.clientX + "px";
        dragX = e.clientX;
      };
      document.onmouseup = () => {
        document.onmousemove = document.onmouseup = null;
        this.controlColumnWidth = block.clientWidth;
        this.isDragging = false;
      };
    },
    onResize: function(e) {
      if (this.isMobile) {
        if (e) {
          this.controlColumnWidth = e.target.innerWidth;
        } else {
          this.controlColumnWidth = window.innerWidth;
        }
      }
    },
    onShowRaw: function() {
      this.$bvModal.msgBoxOk(
        [
          this.$createElement("trade-raw", {
            props: {
              trade: this.trade,
            },
          }),
        ],
        {
          title: "Trade raw",
          centered: true,
          size: "lg",
          hideHeaderClose: false,
          okTitle: "Close",
        }
      );
    },
    calculateFilled: function(node) {
      let s = new Decimal(0);
      node.forEach((i) => {
        if (i && i.order && i.order.filled_base) {
          s = s.plus(new Decimal(i.order.filled_base));
        } else if (i && i.type == "use") {
          s = s.plus(new Decimal(i.quantity));
        }
      });
      return s;
    },
    onMoved: function(e) {
      this.currentEdit = {
        ...this.currentEdit,
        value: new Decimal(e.price),
      };
    },
    onCancelTrade: function() {
      let cancelType = "none";
      const nodes = [
        this.$createElement(this.getCancelComponent(), {
          on: {
            type: function(value) {
              cancelType = value;
            },
          },
          props: {
            trade: this.trade,
          },
        }),
      ];
      this.$bvModal
        .msgBoxConfirm(nodes, {
          title: "Cancel trade",
          size: "sm",
          okVariant: "danger",
          okTitle: "Ok",
          cancelTitle: "Do Not Cancel",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$http
              .put(`/api/trades/${this.trade.trade_token}`, {
                section: "cancel",
                key: "type",
                value: cancelType,
              })
              .then(
                function(response) {
                  this.$bvToast.toast(`Cancel request sent`, {
                    title: "Cancel trade",
                    autoHideDelay: 3000,
                    appendToast: true,
                    toastClass: "mt-4",
                  });
                }.bind(this)
              )
              .catch(
                function(error) {
                  console.log(error);
                }.bind(this)
              );
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    onDeleteTrade: function() {
      this.$bvModal
        .msgBoxConfirm(
          `Trade ${this.trade.trade_token} will be permanently removed.`,
          {
            title: "Delete trade",
            size: "sm",
            okVariant: "danger",
            okTitle: "Ok",
            cancelTitle: "Do Not Delete",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$http
              .delete(`/api/trades/${this.trade.trade_token}`)
              .then(() => this.$router.push(`/trades`))
              .catch(
                function(error) {
                  console.log(error);
                }.bind(this)
              );
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    onEditNote: function() {
      const tradeToken = this.trade.trade_token;
      let newNote = null;
      this.$bvModal
        .msgBoxConfirm(
          [
            this.$createElement("note-editor", {
              props: {
                tradeToken: tradeToken,
              },
              on: {
                change: function(value) {
                  newNote = value;
                },
              },
            }),
          ],
          {
            title: `Edit notes for ${tradeToken.toUpperCase()}`,
            okTitle: "Save",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
            id: `trade-note-edit-${tradeToken}`,
          }
        )
        .then((value) => {
          if (value && newNote) {
            NProgress.start();
            this.$http
              .put("/api/notes", newNote)
              .then(
                function(response) {
                  this.tradeInitial = {
                    ...this.tradeInitial,
                    has_note: newNote.text && newNote.text.length,
                  };
                }.bind(this)
              )
              .catch(
                function(reason) {
                  let errors = "Internal server error.";
                  if (
                    reason.response.status == 422 ||
                    reason.response.status == 400
                  ) {
                    errors = JSON.stringify(reason.response.data, null, 2);
                  }
                  const h = this.$createElement;
                  const $errors = h("pre", {}, errors);
                  this.$bvToast.toast([$errors], {
                    title: "Error saving note",
                    variant: "danger",
                    autoHideDelay: 6000,
                    toastClass: "mt-4",
                  });
                }.bind(this)
              )
              .finally(
                function() {
                  NProgress.done();
                }.bind(this)
              );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted: async function() {
    this.isMobile = checkMobile();
    const tradeResponse = await this.$http.get(
      `/api/trades/${this.$route.params.slug}`
    );
    if (tradeResponse.status == 404) {
      this.$bvToast.toast(`Trade ${this.$route.params.slug} not found.`, {
        title: "Error",
        variant: "danger",
        autoHideDelay: 4000,
        toastClass: "mt-4",
      });
      return;
    }
    const symbolResponse = await this.$http.get(
      `/api/symbols/${tradeResponse.data.symbol}`
    );
    this.symbol = {
      ...symbolResponse.data,
      ls_max_qty:
        (symbolResponse.data.ls_max_qty &&
          new Decimal(symbolResponse.data.ls_max_qty)) ||
        null,
      ls_min_qty:
        (symbolResponse.data.ls_min_qty &&
          new Decimal(symbolResponse.data.ls_min_qty)) ||
        null,
      ls_step_size:
        (symbolResponse.data.ls_step_size &&
          new Decimal(symbolResponse.data.ls_step_size)) ||
        null,
      mn_min_notional:
        (symbolResponse.data.mn_min_notional &&
          new Decimal(symbolResponse.data.mn_min_notional)) ||
        null,
      pf_max_price:
        (symbolResponse.data.pf_max_price &&
          new Decimal(symbolResponse.data.pf_max_price)) ||
        null,
      pf_min_price:
        (symbolResponse.data.pf_min_price &&
          new Decimal(symbolResponse.data.pf_min_price)) ||
        null,
      pf_tick_size:
        (symbolResponse.data.pf_tick_size &&
          new Decimal(symbolResponse.data.pf_tick_size)) ||
        null,
      ticker: {
        bid: new Decimal(symbolResponse.data.ticker.bid),
        ask: new Decimal(symbolResponse.data.ticker.ask),
      },
      quote_balance: symbolResponse.data.quote_balance && {
        wallet: new Decimal(symbolResponse.data.quote_balance.wallet),
        available: new Decimal(symbolResponse.data.quote_balance.available),
      },
      bnb_balance: symbolResponse.data.bnb_balance && {
        wallet: new Decimal(symbolResponse.data.bnb_balance.wallet),
        available: new Decimal(symbolResponse.data.bnb_balance.available),
      },
      leverage:
        symbolResponse.data.leverage &&
        new Decimal(symbolResponse.data.leverage),
      commission:
        symbolResponse.data.commission &&
        new Decimal(symbolResponse.data.commission),
    };
    this.tradeInitial = tradeResponse.data;
    this.onResize();
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style></style>
