<template>
  <div>
    {{ trade.symbol.toUpperCase() }} trade {{ trade.trade_token }} will be
    cancelled.
    <b-form-checkbox class="mt-4" switch v-model="doClose" v-if="toClose">
      close remaining {{ toClose.toFixed() }} {{ trade.state.base }}
    </b-form-checkbox>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import Decimal from "decimal.js";

export default {
  name: "TradeCancel",
  props: {
    trade: Object,
  },
  data: function() {
    return {
      doClose: false,
    };
  },
  components: {
    BFormCheckbox,
  },
  watch: {
    doClose: function(newValue, oldValue) {
      this.$emit("type", newValue ? "close" : "none");
    },
  },
  computed: {
    toClose: function() {
      if (!this.trade.state.exits.length) {
        return null;
      }
      const entered = this.calculateFilled(this.trade.state.entries);
      const exited = this.calculateFilled(this.trade.state.exits);
      const to_close = entered.minus(exited);
      if (to_close.gt(new Decimal(0))) {
        return to_close;
      }
    },
  },
  methods: {
    calculateFilled: function(node) {
      let s = new Decimal(0);
      node.forEach((i) => {
        if (i && i.order && i.order.filled_base) {
          s = s.plus(new Decimal(i.order.filled_base));
        } else if (i && i.type == "use") {
          s = s.plus(new Decimal(i.quantity));
        }
      });
      return s;
    },
  },
  mounted: function() {
    this.doClose =
      this.trade.state.options && this.trade.state.options.close_on_failure;
    this.$emit("type", this.doClose ? "close" : "none");
  },
};
</script>

<style></style>
