import Vue from "vue";

export default {
  LOGIN(state) {
    state.auth.loggedIn = true;
  },
  LOGOUT(state) {
    state.auth.loggedIn = false;
  },
  URL_CHANGE(state, url) {
    state.pages.name = url.name;
  },
  MINUTE(state) {
    state.minute.now = new Date();
  },
  /* socket */
  SOCKET_ONOPEN(state, event) {
    Vue.prototype.$socket = event.currentTarget;
    state.socket.isConnected = true;

    const token = localStorage.getItem("accessToken");
    if (token) {
      event.currentTarget.sendObj({ token });
    }
  },
  SOCKET_ONCLOSE(state, event) {
    console.log(event);
    state.socket.isConnected = false;
  },
  SOCKET_ONERROR(state, event) {
    console.error(state, event);
  },
  SOCKET_ONMESSAGE(state, message) {
    if (message.type == "trade") {
      state.trader.trades = {
        ...state.trader.trades,
        [message.data.trade_token]: message.data,
      };
    } else if (message.type == "state") {
      const trade = state.trader.trades[message.data.trade_token];
      if (trade) {
        state.trader.trades = {
          ...state.trader.trades,
          [message.data.trade_token]: {
            ...trade,
            state: message.data,
          },
        };
      }
    } else if (message.type == "log") {
      state.trader.logs = {
        ...state.trader.logs,
        [message.data.trade_token]: message.data,
      };
    }
  },
  SOCKET_RECONNECT(state, count) {
    console.info(state, count);
  },
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true;
  },
};
