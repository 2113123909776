import OrderFilledActivity from "./OrderFilled.vue";
import TradeDoneActivity from "./TradeDone.vue";
import TradeErrorActivity from "./TradeError.vue";
import NoteActivity from "./Note.vue";
import AlertPriceActivity from "./AlertPrice.vue";
import ActiveAlert from "./ActiveAlert";

export default function(activity) {
  if (activity == "note") {
    return NoteActivity;
  } else if (activity == "t_done") {
    return TradeDoneActivity;
  } else if (activity == "t_error") {
    return TradeErrorActivity;
  } else if (activity == "o_filled") {
    return OrderFilledActivity;
  } else if (activity == "a_price") {
    return AlertPriceActivity;
  } else if (activity == "alert") {
    return ActiveAlert;
  }
}
