<template>
  <div class="item-row">
    <div class="chart-col" v-if="!symbol">
      <spinner />
    </div>
    <div class="chart-col" v-if="symbol && !isMobile">
      <tv-chart
        :symbol="symbol"
        v-show="!isDragging"
        :points="points"
        v-on:moved="onMoved"
        :interval="interval"
      />
    </div>
    <div
      class="separator-col"
      @mousedown="dragControl"
      v-if="symbol && !isMobile"
    ></div>
    <div
      v-if="symbol"
      class="control-col"
      ref="controlColumn"
      :style="{ width: isMobile ? '100%' : controlColumnWidth + 'px' }"
    >
      <div class="header-row">
        <div class="header-content">
          {{ symbol.base }}{{ symbol.quote }}
          <b-icon-star
            class="action-icon"
            :scale="1.2"
            v-if="!symbol.favorite"
            v-on:click="onFavorite"
          />
          <b-icon-star-fill
            class="action-icon"
            v-if="symbol.favorite"
            :scale="1.2"
            v-on:click="onUnfavorite"
          />

          <span v-if="symbol.imratio" style="margin-left: 1em"
            >M{{ symbol.imratio }}</span
          >
          <b-icon
            :icon="symbol.has_note ? 'pencil-fill' : 'pencil'"
            class="action-icon"
            v-on:click="onEditNote"
            :scale="1"
          />

          <a
            :href="
              `https://www.binance.com/en/trade/${symbol.base}_${symbol.quote}`
            "
            >B</a
          >
          <a
            :href="
              `https://www.tradingview.com/symbols/${symbol.base}${symbol.quote}/?exchange=BINANCE`
            "
            >TV</a
          >
        </div>

        <plain-selector v-model="tab" :options="tabOptions" />
      </div>
      <div class="control-content">
        <component
          v-bind:is="getCreatorComponent()"
          :symbol="symbol"
          v-on:input="onTrade"
          :value="trade"
          v-bind:key="`creator-${version}`"
          v-if="currentTab"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Decimal from "decimal.js";
import TVChart from "../components/lib/TVChart";
import TradeCreator from "../components/trade/TradeCreator";
import PlainSelector from "../components/lib/PlainSelector";
import NoteEditor from "../components/lib/NoteEditor";
import AlertsCreator from "../components/AlertsCreator";
import Spinner from "../components/lib/Spinner";
import { checkMobile } from "../utils";

export default {
  name: "SymbolPage",
  data: function() {
    return {
      tab: null,
      currentTab: null,
      trade: null,
      symbol: null,
      isDragging: false,
      points: [],
      isMobile: false,
      controlColumnWidth: 300,
      version: 0,
      interval: null,
    };
  },
  components: {
    "tv-chart": TVChart,
    PlainSelector,
    NoteEditor,
    Spinner,
  },
  computed: {
    exchangeConnected: function() {
      console.log(this.symbol);
      return false;
    },
    tabOptions: function() {
      if (!this.symbol) {
        return [];
      }
      if (!this.symbol.exchange_connected) {
        return [{ slug: "alerts", title: "Alerts" }];
      }
      let options = [{ slug: "trade", title: "Trade" }];
      options.push({ slug: "alerts", title: "Alerts" });
      return options;
    },
  },
  watch: {
    tab: function(newValue, oldValue) {
      this.currentTab = newValue;
      this.version = this.version + 1;
      this.trade = null;
      this.points = [];
    },
  },
  methods: {
    getCreatorComponent() {
      if (this.currentTab == "alerts") {
        return AlertsCreator;
      }
      return TradeCreator;
    },
    dragControl: function(e) {
      this.isDragging = true;
      let dragX = e.clientX;
      let block = this.$refs.controlColumn;
      document.onmousemove = function onMouseMove(e) {
        block.style.width = block.offsetWidth + dragX - e.clientX + "px";
        dragX = e.clientX;
      };
      document.onmouseup = () => {
        document.onmousemove = document.onmouseup = null;
        this.controlColumnWidth = block.clientWidth;
        this.isDragging = false;
      };
    },
    onTrade: function(trade) {
      let points = [];
      if (trade.entry) {
        points.push({
          type: "order",
          section: "entry",
          i: null,
          title: `Entry`,
          quantity: parseFloat(trade.entry.quantity),
          price: parseFloat(trade.entry.price),
          market: false,
          color1: "rgb(136,135,57)",
          color2: "rgba(136,135,57,0.75)",
        });
      }
      if (trade.entries) {
        trade.entries.forEach((i, n) => {
          points.push({
            type: "order",
            section: "entries",
            i: n,
            title:
              i.type == "market" ? `Entry ${n + 1} (market)` : `Entry ${n + 1}`,
            quantity: parseFloat(i.quantity),
            price: i.price ? parseFloat(i.price) : symbol.ask.toNumber(),
            market: i.type == "market",
            color1: "rgb(136,135,57)",
            color2: "rgba(136,135,57,0.75)",
          });
        });
      }
      if (trade.exits) {
        trade.exits.forEach((i, n) => {
          if (i.type != "inf") {
            points.push({
              type: "order",
              section: "exits",
              i: n,
              title: `Exit ${n + 1}`,
              quantity: parseFloat(i.quantity),
              price: i.price ? parseFloat(i.price) : this.symbol.bid.toNumber(),
              market: i.type == "market",
              color1: "rgb(50,102,51)",
              color2: "rgba(50,102,51,0.75)",
            });
          }
        });
      }
      if (trade.targets) {
        trade.targets.forEach((i, n) => {
          points.push({
            type: "order",
            section: "targets",
            i: n,
            title: i.editing ? "New target" : `Target ${n + 1}`,
            quantity: n + 1,
            price: parseFloat(i.price),
            market: !i.editing,
          });
        });
      }
      if (trade.exits && trade.exits.length && trade.stop) {
        points.push({
          type: "order",
          section: "stop",
          i: null,
          title: "Stop",
          quantity: "100",
          price: parseFloat(trade.stop.price),
          market: false,
          color1: "rgb(120,52,52)",
          color2: "rgba(120,52,52,0.75)",
        });
      }
      if (trade.options) {
        if (trade.options.start_price) {
          points.push({
            type: "order",
            section: "options",
            i: "start_price",
            title: "Start price",
            quantity: 100,
            price: parseFloat(trade.options.start_price),
            market: false,
          });
        }
        if (trade.options.cancel_price) {
          points.push({
            type: "order",
            section: "options",
            i: "cancel_price",
            title: "Cancel price",
            quantity: 100,
            price: parseFloat(trade.options.cancel_price),
            market: false,
          });
        }
      }
      this.points = points;
      this.trade = trade;
    },
    onMoved: function(e) {
      const isArray = Number.isInteger(e.i);
      const key = isArray ? "price" : e.i || "price";
      let section = {
        ...(isArray ? this.trade[e.section][e.i] : this.trade[e.section]),
        [key]: new Decimal(e.price),
      };
      this.trade = {
        ...this.trade,
        [e.section]: isArray
          ? [
              ...this.trade[e.section].slice(0, e.i),
              section,
              ...this.trade[e.section].slice(
                e.i + 1,
                this.trade[e.section].length
              ),
            ]
          : section,
      };
    },
    onFavorite: function() {
      this.$http
        .post(`/api/symbols/${this.symbol.slug}/favorite`)
        .then(
          function(response) {
            this.symbol = {
              ...this.symbol,
              favorite: true,
            };
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        );
    },
    onUnfavorite: function() {
      this.$http
        .delete(`/api/symbols/${this.symbol.slug}/favorite`)
        .then(
          function(response) {
            this.symbol = {
              ...this.symbol,
              favorite: false,
            };
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        );
    },
    onEditNote: function() {
      let newNote = null;
      this.$bvModal
        .msgBoxConfirm(
          [
            this.$createElement("note-editor", {
              props: {
                symbolSlug: this.symbol.slug,
              },
              on: {
                change: function(value) {
                  newNote = value;
                },
              },
            }),
          ],
          {
            title: `Edit notes for ${this.symbol.base}${this.symbol.quote}`,
            okTitle: "Save",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
            id: `symbol-note-edit-${this.symbol.slug}`,
          }
        )
        .then((value) => {
          if (value && newNote) {
            NProgress.start();
            this.$http
              .put("/api/notes", newNote)
              .then(
                function(response) {
                  this.symbol = {
                    ...this.symbol,
                    has_note: newNote.text && newNote.text.length,
                  };
                }.bind(this)
              )
              .catch(
                function(reason) {
                  let errors = "Internal server error.";
                  if (
                    reason.response.status == 422 ||
                    reason.response.status == 400
                  ) {
                    errors = JSON.stringify(reason.response.data, null, 2);
                  }
                  const h = this.$createElement;
                  const $errors = h("pre", {}, errors);
                  this.$bvToast.toast([$errors], {
                    title: "Error saving note",
                    variant: "danger",
                    autoHideDelay: 6000,
                    toastClass: "mt-4",
                  });
                }.bind(this)
              )
              .finally(
                function() {
                  NProgress.done();
                }.bind(this)
              );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted: function() {
    this.interval = this.$route.query.interval || null;
    this.isMobile = checkMobile();
    this.$http
      .get(`/api/symbols/${this.$route.params.slug}`)
      .then(
        function(response) {
          this.symbol = {
            ...response.data,
            ls_max_qty:
              (response.data.ls_max_qty &&
                new Decimal(response.data.ls_max_qty)) ||
              null,
            ls_min_qty:
              (response.data.ls_min_qty &&
                new Decimal(response.data.ls_min_qty)) ||
              null,
            ls_step_size:
              (response.data.ls_step_size &&
                new Decimal(response.data.ls_step_size)) ||
              null,
            mn_min_notional:
              (response.data.mn_min_notional &&
                new Decimal(response.data.mn_min_notional)) ||
              null,
            pf_max_price:
              (response.data.pf_max_price &&
                new Decimal(response.data.pf_max_price)) ||
              null,
            pf_min_price:
              (response.data.pf_min_price &&
                new Decimal(response.data.pf_min_price)) ||
              null,
            pf_tick_size:
              (response.data.pf_tick_size &&
                new Decimal(response.data.pf_tick_size)) ||
              null,
            ticker: {
              bid: new Decimal(response.data.ticker.bid),
              ask: new Decimal(response.data.ticker.ask),
            },
            quote_balance: response.data.quote_balance && {
              wallet: new Decimal(response.data.quote_balance.wallet),
              available: new Decimal(response.data.quote_balance.available),
            },
            bnb_balance: response.data.bnb_balance && {
              wallet: new Decimal(response.data.bnb_balance.wallet),
              available: new Decimal(response.data.bnb_balance.available),
            },
            leverage:
              response.data.leverage && new Decimal(response.data.leverage),
            commission:
              response.data.commission && new Decimal(response.data.commission),
          };
          this.tab = this.tabOptions[0].slug;
        }.bind(this)
      )
      .catch(
        function(reason) {
          console.log(reason);
        }.bind(this)
      );
  },
};
</script>

<style></style>
