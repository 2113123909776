<template>
  <div class="page-wrapper">
    <spinner v-if="!page" />
    <div class="instruments" v-else>
      <b-link to="/pages" class="mr-1">Pages</b-link>
      <span style="color: #aaa;" class="mr-1">/</span> {{ page.title }}
      <span class="ml-1" style="color: #aaa;">
        <b-icon-cloud-check v-if="isSaved"/><b-icon-cloud-minus v-else
      /></span>
      <span class="action" v-on:click="onEdit"><b-icon-pencil /></span>
      <span class="action" v-on:click="onDelete"><b-icon-trash /></span>
    </div>
    <b-tabs v-if="page" small>
      <b-tab title="View" active
        ><div class="viewer" v-html="compiledMarkdown" v-if="page"></div
      ></b-tab>
      <b-tab title="Edit"
        ><md-editor :value="text" v-on:input="onUpdate" v-on:save="onSave"
      /></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BLink, BTabs, BTab, BFormSpinbutton, BFormGroup } from "bootstrap-vue";
import Spinner from "../components/lib/Spinner";
import MdEditor from "../components/lib/MdEditor";
import marked from "marked";

export default {
  name: "Page",
  data: function() {
    return {
      page: null,
      updateTimer: null,
      saveTimer: null,
      text: null,
      nextText: null,
    };
  },
  components: {
    BLink,
    BTabs,
    BTab,
    BFormSpinbutton,
    BFormGroup,
    Spinner,
    MdEditor,
  },
  computed: {
    compiledMarkdown: function() {
      if (this.text) {
        return marked(this.text, { sanitize: true });
      }
      return "";
    },
    isSaved: function() {
      return this.page.text == this.text;
    },
  },
  methods: {
    onUpdate: function(text) {
      this.nextText = text;
      if (this.updateTimer) {
        clearTimeout(this.updateTimer);
        this.updateTimer = null;
      }
      this.updateTimer = setTimeout(() => {
        this.text = this.nextText;
      }, 300);
      if (this.saveTimer) {
        clearTimeout(this.saveTimer);
        this.saveTimer = null;
      }
      this.saveTimer = setTimeout(() => {
        this.savePage();
      }, 2000);
    },
    onSave: function() {
      if (this.updateTimer) {
        clearTimeout(this.updateTimer);
        this.updateTimer = null;
      }
      this.text = this.nextText;
      if (this.saveTimer) {
        clearTimeout(this.saveTimer);
        this.saveTimer = null;
      }
      this.savePage();
    },
    onEdit: function() {
      let pageTitle = this.page.title;
      let weight = this.page.weight;
      this.$bvModal
        .msgBoxConfirm(
          [
            this.$createElement(
              "b-form-group",
              {
                props: {
                  label: "Page title",
                },
              },
              [
                this.$createElement("b-form-input", {
                  props: {
                    placeholder: "Page title",
                    value: pageTitle,
                  },
                  on: {
                    change: function(value) {
                      pageTitle = value;
                    },
                  },
                }),
              ]
            ),
            this.$createElement(
              "b-form-group",
              {
                props: {
                  label: "Page weight",
                },
              },
              [
                this.$createElement("b-form-spinbutton", {
                  props: {
                    value: weight,
                    min: 0,
                    max: 100,
                    step: 1,
                    inline: true,
                  },
                  on: {
                    change: function(value) {
                      weight = value;
                    },
                  },
                }),
              ]
            ),
          ],
          {
            title: `Edit page`,
            okTitle: "Update",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value && pageTitle) {
            NProgress.start();
            this.$http
              .put(`/api/pages/${this.$route.params.pageId}`, {
                title: pageTitle,
                text: this.page.text,
                weight: weight,
              })
              .then(
                function(response) {
                  this.page = response.data;
                }.bind(this)
              )
              .catch(
                function(reason) {
                  let errors = "Internal server error.";
                  if (
                    reason.response.status == 422 ||
                    reason.response.status == 400
                  ) {
                    errors = JSON.stringify(reason.response.data, null, 2);
                  }
                  const h = this.$createElement;
                  const $errors = h("pre", {}, errors);
                  this.$bvToast.toast([$errors], {
                    title: "Error creating a page",
                    variant: "danger",
                    autoHideDelay: 6000,
                    toastClass: "mt-4",
                  });
                }.bind(this)
              )
              .finally(
                function() {
                  NProgress.done();
                }.bind(this)
              );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onDelete: function() {
      this.$bvModal
        .msgBoxConfirm(`Delete "${this.page.title}" page?`, {
          size: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$http
              .delete(`/api/pages/${this.$route.params.pageId}`)
              .then(
                function(response) {
                  this.$router.push("/pages");
                }.bind(this)
              )
              .catch(
                function(reason) {
                  console.log(reason);
                }.bind(this)
              );
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    savePage: function() {
      this.$http
        .put(`/api/pages/${this.$route.params.pageId}`, {
          text: this.text,
          title: this.page.title,
          weight: this.page.weight,
        })
        .then(
          function(response) {
            this.page = response.data;
            this.text = response.data.text;
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        );
    },
  },
  mounted: function() {
    this.$http
      .get(`/api/pages/${this.$route.params.pageId}`)
      .then(
        function(response) {
          this.page = response.data;
          this.text = response.data.text;
        }.bind(this)
      )
      .catch(
        function(reason) {
          console.log(reason);
        }.bind(this)
      );
  },
};
</script>

<style></style>
