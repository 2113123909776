export default {
  LOGIN: function(context) {
    context.commit("LOGIN");
    this.dispatch("SOCKET_CONNECT");
  },
  LOGOUT: function(context) {
    context.commit("LOGOUT");
    if (context.state.socket.isConnected) {
      this._vm.$disconnect();
    }
  },
  MINUTE: function(context) {
    context.commit("MINUTE");
  },
  /* socket */
  SOCKET_CONNECT: function(context) {
    if (!context.state.socket.isConnected) {
      const token = localStorage.getItem("accessToken");
      if (token) {
        this._vm.$connect();
      }
    }
  },
};
