<template>
  <div class="list-row">
    <div class="list-col">
      <nothing-found v-if="loadedTrades && !trades.length" />
      <spinner v-if="!loadedTrades" />
      <div class="table-wrapper" v-if="loadedTrades && trades.length">
        <b-table
          v-if="trades"
          ref="table"
          id="trades-table"
          class="trades-table"
          :fields="tableFields"
          :items="trades"
          stacked="sm"
          striped
          small
        >
          <template #cell(trade_token)="data">
            <div>
              <b-link :to="`/symbols/${data.item.symbol}`">{{
                data.item.symbol.toUpperCase()
              }}</b-link>
              {{ getTraderName(data.item.trader) }}
            </div>
            <div>
              <b-link :to="`/trades/${data.item.trade_token}`">{{
                data.item.trade_token
              }}</b-link>
            </div>
            <timestamp
              :ts="data.item.updated"
              v-if="['done', 'error'].includes(data.item.status)"
              class="timestamp"
            >
              <template v-slot:default="slotProps">
                <b-icon-box-arrow-right /> {{ slotProps.val.ago }}
                <div class="dt">{{ slotProps.val.dt }}</div>
              </template>
            </timestamp>
            <timestamp :ts="data.item.created" v-else class="timestamp">
              <template v-slot:default="slotProps">
                <b-icon-box-arrow-in-right /> {{ slotProps.val.ago }}
                <div class="dt">{{ slotProps.val.dt }}</div>
              </template>
            </timestamp>
          </template>
          <template #cell(status)="data">
            {{ data.value.toUpperCase() }}
          </template>

          <template #cell(state)="data">
            <trade-line
              :trade="data.item"
              :lineWidth="300"
              v-bind:key="data.item.trade_token"
            />
            <div class="detail-row">
              <span>PnL</span>
              <span>
                {{
                  data.item.state.meta.profit
                    ? data.item.state.meta.profit + " " + data.item.state.quote
                    : "N/A"
                }}
              </span>
            </div>
            <div v-if="data.item.state.meta.reason" class="detail-row">
              <span>Reason</span>
              <span>{{ data.item.state.meta.reason }}</span>
            </div>
            <div class="detail-row">
              <span>Time</span>
              <timestamp
                :ts="data.item.created"
                :endTs="
                  ['done', 'error'].includes(data.item.status)
                    ? data.item.updated
                    : null
                "
              >
                <template v-slot:default="slotProps">
                  {{ slotProps.val.ts }}
                </template>
              </timestamp>
            </div>
          </template>
          <template #cell(has_note)="data">
            <b-icon
              :icon="data.item.has_note ? 'pencil-fill' : 'pencil'"
              class="note-edit"
              v-on:click="onEditNote(data.item.trade_token)"
            />
          </template>
        </b-table>
      </div>
      <div class="pagination-row">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      </div>
    </div>
    <div class="filters-col">
      <div class="header-row">
        Trades
      </div>
      <div class="filters-content">
        <b-form-group label="Status" label-for="i-status">
          <plain-selector :options="statusOptions" v-model="status" />
        </b-form-group>
        <b-form-group label="Symbol" label-for="i-symbol">
          <b-form-input v-model="symbol" id="i-symbol"></b-form-input>
        </b-form-group>
        <b-form-group label="Direction" label-for="i-trader">
          <plain-selector :options="directionOptions" v-model="direction" />
        </b-form-group>
        <b-form-group label="PnL" label-for="i-pnl">
          <plain-selector :options="pnlOptions" v-model="pnlFilter" />
        </b-form-group>
        <b-form-group label="Options">
          <b-form-checkbox
            id="i-created24"
            v-model="created24"
            name="created24"
            switch
          >
            Created 24h
          </b-form-checkbox>
          <b-form-checkbox
            id="i-completed24"
            v-model="completed24"
            name="completed24"
            switch
          >
            Completed 24h
          </b-form-checkbox>
          <b-form-checkbox
            id="i-withoutnotes"
            v-model="withoutNotes"
            name="withoutnotes"
            switch
          >
            Without notes
          </b-form-checkbox>
        </b-form-group>
        <template v-if="loadedTrades">
          <div class="mt-2">Found: {{ totalRows }}</div>
          <div class="mt-2">PnL: {{ pnl ? pnl : "-" }} USD</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Decimal from "decimal.js";
import {
  BFormGroup,
  BTable,
  BPagination,
  BFormCheckbox,
  BFormInput,
} from "bootstrap-vue";
import Timestamp from "../components/lib/Timestamp";
import TradeLine from "../components/lib/TradeLine";
import PlainSelector from "../components/lib/PlainSelector";
import NoteEditor from "../components/lib/NoteEditor";
import NothingFound from "../components/lib/NothingFound";
import Spinner from "../components/lib/Spinner";

export default {
  name: "Trades",
  components: {
    Timestamp,
    TradeLine,
    PlainSelector,
    BFormGroup,
    BTable,
    BPagination,
    NoteEditor,
    NothingFound,
    Spinner,
    BFormCheckbox,
    BFormInput,
  },
  data: function() {
    return {
      loadedTrades: null,
      tableFields: [
        { key: "trade_token", label: "Trade" },
        { key: "status", label: "Status" },
        { key: "state", label: "State" },
        { key: "has_note", label: "Markers" },
      ],
      statusOptions: [
        { slug: "all", title: "All" },
        { slug: "active", title: "Active" },
        { slug: "done", title: "Done" },
        { slug: "error", title: "Error" },
      ],
      directionOptions: [
        { slug: "all", title: "All" },
        { slug: "long", title: "Long" },
        { slug: "short", title: "Short" },
      ],
      pnlOptions: [
        { slug: "all", title: "All" },
        { slug: "na", title: "NA" },
        { slug: "p", title: "Positive" },
        { slug: "n", title: "Negative" },
      ],
      status: "active",
      direction: "all",
      pnlFilter: "all",
      perPage: 10,
      totalRows: null,
      currentPage: 1,
      profit: null,
      symbol: "",
      completed24: false,
      created24: false,
      withoutNotes: false,
      searchTimer: null,
    };
  },
  computed: {
    trades: function() {
      if (this.loadedTrades) {
        const trades = this.loadedTrades.map((i) => {
          if (this.$store.state.trader.trades[i.trade_token]) {
            return this.$store.state.trader.trades[i.trade_token];
          }
          return i;
        });
        return trades.filter(
          (i) => this.status == "all" || i.status == this.status
        );
      }
    },
    pnl: function() {
      if (this.status != "active") {
        return this.profit;
      }
      let s = new Decimal("0");
      Object.entries(this.$store.state.trader.trades).forEach(([trade_token, trade]) => {
        s = s.plus(new Decimal(trade.state.meta["profit"] || "0"));
      });
      return s.toFixed();
    },
  },
  watch: {
    status: function(oldValue, newValue) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      if (this.currentPage == 1) {
        this.reload();
      } else {
        this.currentPage = 1;
      }
      clearTimeout(this.searchTimer);
      this.searchTimer = null;
    },
    symbol: function(oldValue, newValue) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(() => {
        this.reload();
      }, 800);
    },
    currentPage: function(oldValue, newValue) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.reload();
    },
    direction: function(oldValue, newValue) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.reload();
    },
    pnlFilter: function(oldValue, newValue) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.reload();
    },
    completed24: function(oldValue, newValue) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.reload();
    },
    created24: function(oldValue, newValue) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.reload();
    },
    withoutNotes: function(oldValue, newValue) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.reload();
    },
  },
  methods: {
    getTraderName: function(trader) {
      return "Trader";
    },
    reload: function() {
      this.loadedTrades = null;
      this.profit = null;
      let q = `/api/trades?status=${this.status}&per_page=${this.perPage}&page=${this.currentPage}&direction=${this.direction}&pnl=${this.pnlFilter}`;
      if (this.symbol && this.symbol.length) {
        q += `&symbol=${this.symbol}`;
      }
      if (this.created24) {
        q += `&created_from_ts=${new Date() - 24 * 3600 * 1000}`;
      }
      if (this.completed24) {
        q += `&completed_from_ts=${new Date() - 24 * 3600 * 1000}`;
      }
      if (this.withoutNotes) {
        q += `&has_note=false`;
      }
      this.$http
        .get(q)
        .then(
          function(response) {
            this.loadedTrades = response.data.results;
            this.totalRows = response.data.total;
            this.profit = response.data.profit;
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        );
    },
    onEditNote: function(tradeToken) {
      let newNote = null;
      this.$bvModal
        .msgBoxConfirm(
          [
            this.$createElement("note-editor", {
              props: {
                tradeToken: tradeToken,
              },
              on: {
                change: function(value) {
                  newNote = value;
                },
              },
            }),
          ],
          {
            title: `Edit notes for ${tradeToken.toUpperCase()}`,
            okTitle: "Save",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
            id: `trade-note-edit-${tradeToken}`,
          }
        )
        .then((value) => {
          if (value && newNote) {
            NProgress.start();
            this.$http
              .put("/api/notes", newNote)
              .then(
                function(response) {
                  this.loadedTrades = [
                    ...this.loadedTrades.map((i) => {
                      if (i.trade_token == tradeToken) {
                        return {
                          ...i,
                          has_note: newNote.text && newNote.text.length,
                        };
                      } else {
                        return i;
                      }
                    }),
                  ];
                }.bind(this)
              )
              .catch(
                function(reason) {
                  let errors = "Internal server error.";
                  if (
                    reason.response.status == 422 ||
                    reason.response.status == 400
                  ) {
                    errors = JSON.stringify(reason.response.data, null, 2);
                  }
                  const h = this.$createElement;
                  const $errors = h("pre", {}, errors);
                  this.$bvToast.toast([$errors], {
                    title: "Error saving note",
                    variant: "danger",
                    autoHideDelay: 6000,
                    toastClass: "mt-4",
                  });
                }.bind(this)
              )
              .finally(
                function() {
                  NProgress.done();
                }.bind(this)
              );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted: function() {
    this.reload();
  },
};
</script>

<style></style>
