<template>
  <div class="log">
    <div v-for="(entry, i) in logs" v-bind:key="i" class="log-entry">
      <h3 class="log-title">{{ generateTitle(entry) }}</h3>
      <timestamp :ts="entry.ts" />
      <div v-for="(row, j) in generateBody(entry)" v-bind:key="j">
        {{ row }}
      </div>
    </div>
  </div>
</template>

<script>
import Timestamp from "./Timestamp";

export default {
  name: "TradeLog",
  props: {
    tradeToken: String,
    symbol: Object,
  },
  data: function() {
    return {
      logs: [],
      total: null,
    };
  },
  components: {
    Timestamp,
  },
  methods: {
    generateTitle: function(entry) {
      if (entry.event == "t_done") {
        return "Trade completed";
      } else if (entry.event == "t_error") {
        return "Trade error";
      } else if (entry.event == "o_created") {
        return "Order created";
      } else if (entry.event == "o_canceled") {
        return "Order canceled";
      } else if (entry.event == "o_filled") {
        return "Order filled";
      } else if (entry.event == "m_borrow") {
        return "Margin borrow";
      } else if (entry.event == "m_repay") {
        return "Margin repay";
      } else if (entry.event == "m_transfer") {
        return "Margin transfer";
      } else if (entry.event == "m_cleanup") {
        return "Cleanup completed";
      }
      return `${entry.event} event`;
    },
    generateBody: function(entry) {
      if (entry.event == "t_done") {
        return [];
      } else if (entry.event == "t_error") {
        if (entry.data) {
          if (entry.data.rescued) {
            return [`${entry.data.message} (rescued)`];
          }
          return [entry.data.message];
        } else {
          return ["Trade error."];
        }
      } else if (
        entry.event == "o_created" ||
        entry.event == "o_canceled" ||
        entry.event == "o_filled"
      ) {
        let res = [
          `${entry.data.type} ${entry.data.side} ${
            entry.data.quantity
          } @ ${entry.data.price || "market"}`,
        ];
        if (entry.event == "o_filled") {
          res.push(`Filled base: ${entry.data.filled_base}`);
          res.push(`Filled quote: ${entry.data.filled_quote}`);
        }
        res.push(`${entry.data.section} (${entry.data.order_id})`);
        return res;
      } else if (entry.event == "m_borrow" || entry.event == "m_repay") {
        return [`${entry.data.quantity} ${entry.data.asset}`];
      } else if (entry.event == "m_transfer") {
        return [
          `${entry.data.from} -> ${entry.data.to}`,
          `${entry.data.quantity} ${entry.data.asset}`,
        ];
      } else if (entry.event == "m_cleanup") {
        return [];
      } else if (entry.event == "w_cross") {
        return [
          `Price crossed Target ${entry.data.section_id + 1} (${
            entry.data.price
          })`,
        ];
      }
      return [];
    },
  },
  mounted: function() {
    this.$http
      .post("/api/logs", {
        trade_token: this.$route.params.slug,
        per_page: 50,
      })
      .then(
        function(response) {
          this.logs = [...this.logs, ...response.data.results];
          this.total = response.data.total;
        }.bind(this)
      )
      .catch(
        function(reason) {
          console.log(reason);
        }.bind(this)
      );
  },
};
</script>

<style></style>
