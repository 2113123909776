<template>
  <div class="login-row">
    <div class="login-form">
      <b-form @submit.prevent="handleSubmit">
        <b-form-group id="ig-password" label="Password" label-for="i-password">
          <b-form-input
            id="i-password"
            v-model="password"
            type="password"
            required
            autocomplete="current-password"
          ></b-form-input>
          <input-errors :errors="passwordError" />
        </b-form-group>
        <b-button type="submit" variant="primary">Login</b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import { BForm, BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import InputErrors from "../components/lib/InputErrors";

export default {
  name: "Login",
  data() {
    return {
      password: "",
      passwordError: null,
    };
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    InputErrors,
  },
  methods: {
    handleSubmit(e) {
      if (this.password.length > 0) {
        this.$http
          .post("/api/login", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            localStorage.setItem("accessToken", response.data.token);
            if (localStorage.getItem("accessToken") != null) {
              this.password = "";
              if (this.$route.params.nextUrl != null) {
                this.$router.push(this.$route.params.nextUrl, () => {
                  this.$store.dispatch("LOGIN");
                });
              } else {
                this.$router.push({ name: "symbols" }, () => {
                  this.$store.dispatch("LOGIN");
                });
              }
            }
          })
          .catch(
            function(error) {
              if (error.response.status == 422) {
                this.passwordError = error.response.data.password;
              } else {
                console.error(error.response);
              }
            }.bind(this)
          );
      }
    },
  },
};
</script>
