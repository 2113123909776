<template>
  <div>
    <b-link :to="`/symbols/${data.symbol_slug}`"
      >{{ data.symbol_base }}{{ data.symbol_quote }}</b-link
    >
    price crossed {{ data.data.price }}.
  </div>
</template>

<script>
export default {
  name: "AlertPriceActivity",
  props: {
    data: Object,
  },
};
</script>

<style></style>
