<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    :width="size"
    :height="size"
    viewBox="0 0 100 100"
  >
    <defs>
      <clipPath id="shape">
        <polyline points="0,0 70,0 30,60 50,60 90,0 100,0 100,100, 0,100" />
      </clipPath>
    </defs>

    <line
      x1="40"
      y1="20"
      x2="90"
      y2="20"
      style="stroke:#EEE657;stroke-width:16"
      stroke-linecap="round"
    />
    <line
      x1="10"
      y1="50"
      x2="90"
      y2="50"
      style="stroke:#2CC990;stroke-width:16"
      stroke-linecap="round"
    />
    <line
      x1="10"
      y1="20"
      x2="10"
      y2="50"
      style="stroke:#2CC990;stroke-width:16"
      stroke-linecap="round"
    />
    <line
      x1="90"
      y1="50"
      x2="90"
      y2="80"
      style="stroke:#2CC990;stroke-width:16"
      stroke-linecap="round"
    />
    <line
      x1="10"
      y1="80"
      x2="60"
      y2="80"
      style="stroke:#FC6042;stroke-width:16"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "Logo",
  props: {
    size: {
      type: String,
      default: "100px",
    },
  },
};
</script>

<style></style>
