<template>
  <div class="trade-form" v-if="trade">
    <h2>Entries</h2>
    <div
      class="trade-row"
      v-for="(item, index) in trade.state.entries"
      v-bind:key="'en' + index"
    >
      <div v-if="currentEditor == `entries${index}price`" class="form-row-edit">
        <div class="edit-header">
          <span v-on:click="onHideEditor"
            >#{{ index + 1 }} Entry - Edit price</span
          >
          <b-icon-chevron-up v-on:click="onHideEditor" />
        </div>
        <decimal-input
          title="Price"
          :value="currentValue"
          v-on:input="onTradeEdit('entries', index, 'price', $event)"
          :unit="`${symbol.quote} / ${symbol.base}`"
          :min="findEntryMinPrice(index)"
          :max="findEntryMaxPrice(index)"
          :step="priceStep"
        />
        <b-button size="sm" v-on:click="onSave">Save</b-button>
      </div>
      <template v-else>
        <span class="number">#{{ index + 1 }}</span>
        <span class="edit-content"
          >{{ item.type.toUpperCase() }} {{ item.quantity }} @
          <span v-if="item.type == 'market'">market</span
          ><template v-else>
            <span
              class="editable"
              v-if="getIsEditable('entries', index, 'price')"
              v-on:click="
                onShowEditor(
                  'entries',
                  index,
                  'price',
                  toDecimal(trade.state.entries[index].price)
                )
              "
              >{{ item.price }}</span
            ><span v-else>{{ item.price }}</span></template
          ></span
        >
      </template>
    </div>
    <template v-if="trade.state.exits.length">
      <h2>Exits</h2>
      <div
        class="trade-row"
        v-for="(item, index) in trade.state.exits"
        v-bind:key="'ex' + index"
      >
        <div v-if="currentEditor == `exits${index}price`" class="form-row-edit">
          <div class="edit-header">
            <span v-on:click="onHideEditor"
              >#{{ index + 1 }} Exit - Edit price</span
            >
            <b-icon-chevron-up v-on:click="onHideEditor" />
          </div>
          <decimal-input
            title="Price"
            :value="currentValue"
            v-on:input="onTradeEdit('exits', index, 'price', $event)"
            :unit="`${symbol.quote} / ${symbol.base}`"
            :min="findExitMinPrice(index)"
            :max="findExitMaxPrice(index)"
            :step="priceStep"
          />
          <b-button size="sm" v-on:click="onSave">Save</b-button>
        </div>
        <template v-else>
          <span class="number">#{{ index + 1 }}</span>
          <span class="edit-content"
            >{{ item.type.toUpperCase() }} {{ item.quantity }}% @
            <span
              class="editable"
              v-if="getIsEditable('exits', index, 'price')"
              v-on:click="
                onShowEditor(
                  'exits',
                  index,
                  'price',
                  toDecimal(trade.state.exits[index].price)
                )
              "
              >{{ item.price }}</span
            ><span v-else>{{ item.price }}</span></span
          ></template
        >
      </div>
      <h2>Stop</h2>
      <div class="trade-row">
        <div v-if="currentEditor == `stopprice`" class="form-row-edit">
          <div class="edit-header">
            <span v-on:click="onHideEditor">Stop - Edit price</span>
            <b-icon-chevron-up v-on:click="onHideEditor" />
          </div>
          <decimal-input
            title="Price"
            :value="currentValue"
            v-on:input="onTradeEdit('stop', null, 'price', $event)"
            :unit="`${symbol.quote} / ${symbol.base}`"
            :min="findStopMinPrice()"
            :max="findStopMaxPrice()"
            :step="priceStep"
          />
          <b-button size="sm" v-on:click="onSave">Save</b-button>
        </div>
        <template v-else>
          <span class="left-action"></span>
          <span class="edit-content"
            >{{ trade.state.stop.type.toUpperCase() }} @
            <span
              class="editable"
              v-if="getIsEditable('stop', null, 'price')"
              v-on:click="
                onShowEditor(
                  'stop',
                  null,
                  'price',
                  toDecimal(trade.state.stop.price)
                )
              "
              >{{ trade.state.stop.price }}</span
            ><span v-else>{{ trade.state.stop.price }}</span></span
          >
        </template>
      </div>
    </template>
    <h2>Options</h2>
    <div class="trade-row">
      <div v-if="currentEditor == `optionsstart_price`" class="form-row-edit">
        <div class="edit-header">
          <span v-on:click="onHideEditor">Start price</span>
          <b-icon-chevron-up v-on:click="onHideEditor" />
        </div>
        <decimal-input
          title="Price"
          :value="currentValue"
          v-on:input="onTradeEdit('options', null, 'start_price', $event)"
          :unit="`${symbol.quote} / ${symbol.base}`"
          :step="priceStep"
        />
        <b-button size="sm" v-on:click="onSave">Save</b-button>
      </div>
      <template v-else>
        <span class="number"></span>
        <span class="edit-content"
          >Start price:
          <span
            class="editable"
            v-if="getIsEditable('options', null, 'start_price')"
            v-on:click="
              onShowEditor(
                'options',
                null,
                'start_price',
                toDecimal(trade.state.options.start_price)
              )
            "
            >{{ trade.state.options.start_price || "-" }}</span
          ><span v-else>{{
            trade.state.options.start_price || "-"
          }}</span></span
        >
      </template>
    </div>
    <div class="trade-row">
      <div v-if="currentEditor == `optionscancel_price`" class="form-row-edit">
        <div class="edit-header">
          <span v-on:click="onHideEditor">Cancel price</span>
          <b-icon-chevron-up v-on:click="onHideEditor" />
        </div>
        <decimal-input
          title="Price"
          :value="currentValue"
          v-on:input="onTradeEdit('options', null, 'cancel_price', $event)"
          :unit="`${symbol.quote} / ${symbol.base}`"
          :step="priceStep"
        />
        <b-button size="sm" v-on:click="onSave">Save</b-button>
      </div>
      <template v-else>
        <span class="number"></span>
        <span class="edit-content"
          >Cancel price:
          <span
            class="editable"
            v-if="getIsEditable('options', null, 'cancel_price')"
            v-on:click="
              onShowEditor(
                'options',
                null,
                'cancel_price',
                trade.state.options.cancel_price
                  ? toDecimal(trade.state.options.cancel_price)
                  : symbol.ticker.ask
              )
            "
            >{{ trade.state.options.cancel_price || "-" }}</span
          ><span v-else>{{
            trade.state.options.cancel_price || "-"
          }}</span></span
        >
      </template>
    </div>
    <trade-summary :state="currentState" :symbol="symbol" :is-long="isLong" />
  </div>
</template>

<script>
import Decimal from "decimal.js";
import DecimalInput from "../lib/DecimalInput";
import { BButton } from "bootstrap-vue";
import TradeSummary from "./TradeSummary";

export default {
  name: "TradeEditor",
  props: {
    symbol: Object,
    trade: Object,
    value: Object,
  },
  components: {
    DecimalInput,
    BButton,
    TradeSummary,
  },
  data: function() {
    return {
      currentSection: null,
      currentIndex: null,
      currentKey: null,
      currentValue: null,
    };
  },
  computed: {
    isLong: function() {
      return this.trade.state.meta["is_long"];
    },
    priceStep: function() {
      return this.symbol.pf_tick_size;
    },
    currentEditor: function() {
      return `${this.currentSection}${
        Number.isInteger(this.currentIndex) ? this.currentIndex : ""
      }${this.currentKey}`;
    },
    editObj: function() {
      if (!this.currentSection) {
        return null;
      }
      return {
        section: this.currentSection,
        index: this.currentIndex,
        key: this.currentKey,
        value: this.currentValue,
      };
    },
    currentState: function() {
      if (this.currentKey) {
        if (Number.isInteger(this.currentIndex)) {
          return {
            ...this.trade.state,
            [this.currentSection]: [
              ...this.trade.state[this.currentSection].slice(
                0,
                this.currentIndex
              ),
              {
                ...this.trade.state[this.currentSection][this.currentIndex],
                [this.currentKey]: this.currentValue,
              },
              ...this.trade.state[this.currentSection].slice(
                this.currentIndex + 1,
                this.trade.state[this.currentSection].length
              ),
            ],
          };
        } else {
          return {
            ...this.trade.state,
            [this.currentSection]: {
              ...this.trade.state[this.currentSection],
              [this.currentKey]: this.currentValue,
            },
          };
        }
      }
      return this.trade.state;
    },
  },
  watch: {
    value: function(newValue, oldValue) {
      if (!newValue) {
        return;
      }
      let changed = false;
      if (newValue.key == "price") {
        let minVal = null;
        let maxVal = null;
        if (newValue.section == "entries") {
          minVal = this.findEntryMinPrice(newValue.index);
          maxVal = this.findEntryMaxPrice(newValue.index);
        } else if (newValue.section == "exits") {
          minVal = this.findExitMinPrice(newValue.index);
          maxVal = this.findExitMaxPrice(newValue.index);
        } else if (newValue.section == "stop") {
          minVal = this.findStopMinPrice();
          maxVal = this.findStopMaxPrice();
        }
        if (minVal && newValue.value.lt(minVal)) {
          mewValue.value = minVal;
          changed = true;
        }
        if (maxVal && newValue.value.gt(maxVal)) {
          newValue.value = maxVal;
          changed = true;
        }
      }
      this.currentValue = newValue.value;
      if (changed) {
        this.$emit("input", this.editObj);
      }
    },
  },
  methods: {
    toDecimal: function(val) {
      return new Decimal(val);
    },
    onHideEditor: function() {
      this.currentSection = null;
      this.currentIndex = null;
      this.currentKey = null;
      this.currentValue = null;
      this.$emit("input", this.editObj);
    },
    onShowEditor: function(section, index, key, value) {
      this.currentSection = section;
      this.currentIndex = index;
      this.currentKey = key;
      this.currentValue = value;
      this.$emit("input", this.editObj);
    },
    onTradeEdit: function(section, index, key, value) {
      this.currentSection = section;
      this.currentIndex = index;
      this.currentKey = key;
      this.currentValue = value;

      this.$emit("input", this.editObj);
    },
    onSave: function() {
      let data = {
        section: this.currentSection,
        key: this.currentKey,
        value: this.currentValue.toFixed(),
      };
      if (Number.isInteger(this.currentIndex)) {
        data.section_id = this.currentIndex;
      }
      NProgress.start();
      this.$http
        .put(`/api/trades/${this.trade.trade_token}`, data)
        .then(
          function(response) {
            this.$bvToast.toast(`Edit request sent`, {
              title: "Edit trade",
              autoHideDelay: 3000,
              appendToast: true,
              toastClass: "mt-4",
            });
            this.currentSection = null;
            this.currentIndex = null;
            this.currentKey = null;
            this.currentValue = null;
            this.$emit("input", this.editObj);
          }.bind(this)
        )
        .catch(
          function(reason) {
            let errors = "Internal server error.";
            if (
              reason.response.status == 422 ||
              reason.response.status == 400
            ) {
              errors = JSON.stringify(reason.response.data, null, 2);
            }

            const h = this.$createElement;
            const $errors = h("pre", {}, errors);

            this.$bvToast.toast([$errors], {
              title: "Error updating trade",
              variant: "danger",
              autoHideDelay: 6000,
              toastClass: "mt-4",
            });
          }.bind(this)
        )
        .finally(
          function() {
            NProgress.done();
          }.bind(this)
        );
    },
    findEntryMinPrice: function(index) {
      return this.symbol.pf_min_price;
    },
    findEntryMaxPrice: function(index) {
      return this.symbol.pf_max_price;
    },
    findExitMinPrice: function(index) {
      return this.symbol.pf_min_price;
    },
    findExitMaxPrice: function(index) {
      return this.symbol.pf_max_price;
    },
    findStopMaxPrice: function() {
      return this.symbol.pf_max_price;
    },
    findStopMinPrice: function() {
      return this.symbol.pf_min_price;
    },
    getIsEditable: function(section, index, key) {
      /* if price and order is not filled */
      if (key == "start_price") {
        if (this.trade.state.options.start_price) {
          return true;
        }
        return false;
      }
      if (key == "cancel_price") {
        return true;
      }
      let obj = this.trade.state[section];
      if (Number.isInteger(index)) {
        obj = obj[index];
      }
      if (
        key == "price" &&
        (!obj.order ||
          (obj.order.status != "FILLED" &&
            obj.order.status != "PARTIALLY_FILLED"))
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
