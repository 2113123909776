<template>
  <div class="list-row">
    <div class="list-col">
      <spinner v-if="!symbols.length" />
      <nothing-found v-if="symbols.length && !filtered.length" />
      <div class="table-wrapper" v-if="filtered.length">
        <b-table
          ref="table"
          id="symbols-table"
          v-model="currentRows"
          :fields="tableFields"
          :items="filtered"
          :per-page="perPage"
          :current-page="currentPage"
          stacked="sm"
          sort-by="v"
          :sort-desc="true"
          striped
          small
        >
          <template #cell(s)="data">
            <span
              :class="{
                'text-warning': !data.item.t,
                'text-success': data.item.t,
              }"
            >
              &#x25CF;
            </span>
            <b-link :to="`/symbols/${data.item.s}`" class="table-link"
              >{{ data.item.b }}-{{ data.item.q }}</b-link
            >
          </template>
          <template #cell(m)="data">
            <b-icon-star
              class="mr-1"
              v-if="!data.item.f"
              v-on:click="onFavorite(data.item.s)"
              style="cursor: pointer;"
            />
            <b-icon-star-fill
              class="mr-1"
              v-if="data.item.f"
              v-on:click="onUnfavorite(data.item.s)"
              style="cursor: pointer;"
            />
            <b-icon-eye-slash
              class="mr-1"
              v-if="!data.item.h"
              v-on:click="onHide(data.item.s)"
              style="cursor: pointer;"
            />
            <b-icon-eye
              class="mr-1"
              v-if="data.item.h"
              v-on:click="onUnhide(data.item.s)"
              style="cursor: pointer;"
            />
            <template v-if="data.item.r">
              <a
                v-for="(i, n) in data.item.r"
                v-bind:key="n"
                target="_blank"
                :class="`list text-${i[1]}`"
                :href="`/trades/${i[0]}`"
                >T</a
              >
            </template>
            <b-icon
              :icon="data.item.n ? 'pencil-fill' : 'pencil'"
              class="note-edit"
              v-on:click="onEditNote(data.item.s)"
            />
          </template>
          <template #cell(q)="data">
            <mini-chart :lines="currentCharts[data.item.s]" />
          </template>
          <template #cell(g)="data" class="symbol-tags">
            <span v-for="slg in data.value" v-bind:key="slg" class="tag">{{
              slg
            }}</span>
            <b-icon-tag v-on:click="onEditTags(data.item.s, data.item.g)" />
          </template>
          <template #cell(b)="data">
            <a
              class="list"
              :href="
                `https://www.binance.com/en/trade/${data.item.b}_${data.item.q}`
              "
              >B</a
            >
            <a
              class="list"
              :href="
                `https://www.tradingview.com/symbols/${data.item.b}${data.item.q}/?exchange=BINANCE`
              "
              >TV</a
            >
          </template>
        </b-table>
      </div>
      <div class="pagination-row">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="symbols-table"
        ></b-pagination>
      </div>
    </div>
    <div class="filters-col">
      <div class="header-row">
        Symbols
      </div>
      <div class="filters-content">
        <b-form-group label="Search" label-for="i-search">
          <b-form-input
            v-model="keyword"
            placeholder="Search"
            id="i-search"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Quote" label-for="i-quotes">
          <vue-tags-input
            placeholder="Add quote"
            :autocomplete-items="quotes"
            id="i-quotes"
            :add-from-paste="false"
            :add-only-from-autocomplete="true"
            :autocomplete-min-length="1"
            v-on:tags-changed="onQuotesFilterChanged"
            v-model="quote"
          />
        </b-form-group>
        <b-form-group label="Tags" label-for="i-tags">
          <vue-tags-input
            placeholder="Add tag"
            :autocomplete-items="
              existingTags.map((i) => {
                return { text: i };
              })
            "
            id="i-tags"
            :add-from-paste="false"
            :add-only-from-autocomplete="true"
            :autocomplete-min-length="1"
            v-on:tags-changed="onTagsFilterChanged"
            v-model="tag"
          />
        </b-form-group>
        <b-form-group label="Options">
          <b-form-checkbox
            id="i-trading"
            v-model="trading"
            name="trading"
            switch
          >
            Trading only
          </b-form-checkbox>
          <b-form-checkbox
            id="i-favorite"
            v-model="favorite"
            name="favorite"
            switch
            class="mt-1"
          >
            Favorite only
          </b-form-checkbox>
          <b-form-checkbox
            id="i-has-trades"
            v-model="hasTrades"
            name="has-trades"
            switch
            class="mt-1"
          >
            Has active trades only
          </b-form-checkbox>
          <b-form-checkbox
            id="i-hidden"
            v-model="hidden"
            name="hidden"
            switch
            class="mt-1"
          >
            Hidden only
          </b-form-checkbox>
        </b-form-group>

        <b-form-group label="Days per chart line">
          <b-form-radio-group
            v-model="chartDays"
            :options="daysOptions"
            name="cdays"
            plain
          ></b-form-radio-group>
        </b-form-group>

        <div class="mt-2">Found {{ totalRows }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Decimal from "decimal.js";
import {
  BTable,
  BPagination,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormRadioGroup,
} from "bootstrap-vue";
import MiniChart from "../components/lib/MiniChart";
import SymbolTags from "../components/lib/SymbolTags";
import VueTagsInput from "@johmun/vue-tags-input";
import NoteEditor from "../components/lib/NoteEditor";
import Spinner from "../components/lib/Spinner";
import NothingFound from "../components/lib/NothingFound";

export default {
  name: "Symbols",
  data: function() {
    return {
      symbols: [],
      tags: {},
      currentRows: [],
      currentCharts: {},
      perPage: 20,
      currentPage: 1,
      keyword: null,
      favorite: false,
      trading: true,
      hasTrades: false,
      hidden: false,
      tableFields: [
        { key: "s", label: "Symbol", sortable: true },
        {
          key: "v",
          label: "Volume (M)",
          sortable: true,
          sortByFormatted: function(value, key, item) {
            return (value && value.length && parseFloat(value)) || 0;
          },
        },
        { key: "m", label: "Markers" },
        { key: "q", label: "Price" },
        { key: "g", label: "Tags" },
        { key: "b", label: "Links" },
      ],
      chartDays: 1,
      daysOptions: [
        { text: "1", value: 1 },
        { text: "4", value: 4 },
        { text: "7", value: 7 },
      ],
      quotesFilter: [],
      tagsFilter: [],
      quote: "",
      tag: "",
    };
  },
  components: {
    BTable,
    BPagination,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormRadioGroup,
    MiniChart,
    SymbolTags,
    VueTagsInput,
    NoteEditor,
    Spinner,
    NothingFound,
  },
  computed: {
    totalRows: function() {
      return this.filtered.length;
    },
    quotes: function() {
      const quote = this.quote ? this.quote.toLowerCase() : "";
      return [
        ...new Set(
          this.symbols
            .filter((i) => i.q.toLowerCase().includes(quote))
            .map((i) => i.q)
        ),
      ]
        .sort()
        .map((i) => {
          return {
            text: i,
          };
        });
    },
    filtered: function() {
      const keyword =
        this.keyword &&
        (this.keyword.length ? this.keyword.toLowerCase() : null);
      let rows = [...this.symbols];
      if (this.keyword) {
        rows = rows.filter((i) => i.s.includes(keyword));
      }
      if (this.quotesFilter && this.quotesFilter.length) {
        rows = rows.filter((i) => this.quotesFilter.includes(i.q));
      }
      if (this.tagsFilter && this.tagsFilter.length) {
        rows = rows.filter(
          (i) =>
            i.g && i.g.length && this.tagsFilter.every((z) => i.g.includes(z))
        );
      }
      if (this.trading) {
        rows = rows.filter((i) => i.t);
      }
      if (this.favorite) {
        rows = rows.filter((i) => i.f);
      }
      if (this.hidden) {
        rows = rows.filter((i) => i.h);
      } else {
        rows = rows.filter((i) => !i.h);
      }
      if (this.$store.state.trader) {
        const hasTrades = {};
        Object.keys(this.$store.state.trader.trades).forEach((key) => {
          if (this.$store.state.trader.trades[key].status == "active") {
            const symbol = this.$store.state.trader.trades[
              key
            ].symbol.toLowerCase();
            const profit =
              this.$store.state.trader.trades[key].state.meta &&
              this.$store.state.trader.trades[key].state.meta.profit;
            const color = profit
              ? parseFloat(profit) < 0
                ? "danger"
                : "success"
              : "info";
            if (hasTrades[symbol]) {
              hasTrades[symbol].push([key, color]);
            } else {
              hasTrades[symbol] = [[key, color]];
            }
          }
        });
        rows = rows.map((i) => {
          return {
            ...i,
            r: hasTrades[i.s],
          };
        });
        if (this.hasTrades) {
          rows = rows.filter((i) => i.r);
        }
      }
      return rows;
    },
    existingTags: function() {
      let tags = [];
      for (var key in this.tags) {
        tags.push(this.tags[key]);
      }
      return tags.sort();
    },
  },
  watch: {
    chartDays: function(newValue, oldValue) {
      this.currentCharts = {};
      const symbols = [...this.symbols];
      this.symbols = [];
      this.$nextTick(() => {
        this.symbols = symbols;
      });
    },
    currentRows: function(newValue, oldValue) {
      /* look for new values missing in currentCharts, pull them */
      const missing = [...newValue].filter((i) => !(i.s in this.currentCharts));
      if (!missing.length) {
        return;
      }
      const keep = [...newValue].filter((i) => i.s in this.currentCharts);
      this.$http
        .post(`/api/minicharts`, {
          symbols: missing.map((i) => i.s),
          days: this.chartDays,
        })
        .then(
          function(response) {
            const newCharts = Object.assign(
              {},
              ...response.data.results.map((x) => ({ [x.symbol]: x.lines }))
            );
            if (keep.length) {
              keep.forEach((i) => {
                newCharts[i.s] = this.currentCharts[i.s];
              });
            }
            this.currentCharts = newCharts;
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        );
    },
  },
  methods: {
    onFavorite: function(slug) {
      this.$http
        .post(`/api/symbols/${slug}/favorite`)
        .then(
          function(response) {
            this.symbols = [
              ...this.symbols.filter((i) => i.s != slug),
              { ...this.symbols.find((i) => i.s == slug), f: true },
            ];
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        );
    },
    onUnfavorite: function(slug) {
      this.$http
        .delete(`/api/symbols/${slug}/favorite`)
        .then(
          function(response) {
            this.symbols = [
              ...this.symbols.filter((i) => i.s != slug),
              { ...this.symbols.find((i) => i.s == slug), f: false },
            ];
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        );
    },
    onHide: function(slug) {
      this.$http
        .post(`/api/symbols/${slug}/hide`)
        .then(
          function(response) {
            this.symbols = [
              ...this.symbols.filter((i) => i.s != slug),
              { ...this.symbols.find((i) => i.s == slug), h: true },
            ];
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        );
    },
    onUnhide: function(slug) {
      this.$http
        .delete(`/api/symbols/${slug}/hide`)
        .then(
          function(response) {
            this.symbols = [
              ...this.symbols.filter((i) => i.s != slug),
              { ...this.symbols.find((i) => i.s == slug), h: false },
            ];
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        );
    },
    onEditTags: function(symbolSlug, currentTags) {
      let newTags = [];
      this.$bvModal
        .msgBoxConfirm(
          [
            this.$createElement("symbol-tags", {
              props: {
                currentTags: currentTags,
                existingTags: this.existingTags,
              },
              on: {
                change: function(value) {
                  newTags = value;
                },
              },
            }),
          ],
          {
            title: `Edit tags for ${symbolSlug.toUpperCase()}`,
            okTitle: "Update",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
            id: `symbol-tags-edit-${symbolSlug}`,
          }
        )
        .then((value) => {
          if (value) {
            NProgress.start();
            this.$http
              .post(`/api/symbols/${symbolSlug}/tags`, { tags: newTags })
              .then(
                function(response) {
                  this.tags = {
                    ...this.tags,
                    ...response.data.tags,
                  };

                  this.symbols = [
                    ...this.symbols.map((i) => {
                      if (i.s == symbolSlug) {
                        return {
                          ...i,
                          g: newTags,
                        };
                      } else {
                        return i;
                      }
                    }),
                  ];
                }.bind(this)
              )
              .catch(
                function(reason) {
                  let errors = "Internal server error.";
                  if (
                    reason.response.status == 422 ||
                    reason.response.status == 400
                  ) {
                    errors = JSON.stringify(reason.response.data, null, 2);
                  }
                  const h = this.$createElement;
                  const $errors = h("pre", {}, errors);
                  this.$bvToast.toast([$errors], {
                    title: "Error updating tags",
                    variant: "danger",
                    autoHideDelay: 6000,
                    toastClass: "mt-4",
                  });
                }.bind(this)
              )
              .finally(
                function() {
                  NProgress.done();
                }.bind(this)
              );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onQuotesFilterChanged: function(tags) {
      this.quotesFilter = tags.map((i) => i.text);
    },
    onTagsFilterChanged: function(tags) {
      this.tagsFilter = tags.map((i) => i.text);
    },
    onEditNote: function(symbolSlug) {
      let newNote = null;
      this.$bvModal
        .msgBoxConfirm(
          [
            this.$createElement("note-editor", {
              props: {
                symbolSlug: symbolSlug,
              },
              on: {
                change: function(value) {
                  newNote = value;
                },
              },
            }),
          ],
          {
            title: `Edit notes for ${symbolSlug.toUpperCase()}`,
            okTitle: "Save",
            cancelTitle: "Cancel",
            hideHeaderClose: false,
            centered: true,
            id: `symbol-note-edit-${symbolSlug}`,
          }
        )
        .then((value) => {
          if (value && newNote) {
            NProgress.start();
            this.$http
              .put("/api/notes", newNote)
              .then(
                function(response) {
                  this.symbols = [
                    ...this.symbols.map((i) => {
                      if (i.s == symbolSlug) {
                        return {
                          ...i,
                          n: newNote.text && newNote.text.length,
                        };
                      } else {
                        return i;
                      }
                    }),
                  ];
                }.bind(this)
              )
              .catch(
                function(reason) {
                  let errors = "Internal server error.";
                  if (
                    reason.response.status == 422 ||
                    reason.response.status == 400
                  ) {
                    errors = JSON.stringify(reason.response.data, null, 2);
                  }
                  const h = this.$createElement;
                  const $errors = h("pre", {}, errors);
                  this.$bvToast.toast([$errors], {
                    title: "Error saving note",
                    variant: "danger",
                    autoHideDelay: 6000,
                    toastClass: "mt-4",
                  });
                }.bind(this)
              )
              .finally(
                function() {
                  NProgress.done();
                }.bind(this)
              );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted: function() {
    this.$http
      .get("/api/symbols")
      .then(
        function(response) {
          this.tags = response.data.tags;
          this.symbols = response.data.results.map((i) => {
            return {
              ...i,
              g: [...((i.g && i.g.map((j) => this.tags[j])) || [])],
              v:
                i.v &&
                new Decimal(i.v)
                  .div(new Decimal(1000000))
                  .toSD(2)
                  .toFixed(),
            };
          });
        }.bind(this)
      )
      .catch(
        function(reason) {
          console.log(reason);
        }.bind(this)
      );
  },
};
</script>
