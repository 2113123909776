<template>
  <div class="main-nav" ref="mainNav" v-if="loggedIn">
    <div class="group">
      <span class="brand"
        ><logo :size="'20px'" /><span class="large-screen">EES-F</span></span
      >
      <span v-if="currentPage == 'symbols'" class="menu-btn-selected"
        ><b-icon-view-list :scale="1.2" /><span class="large-screen">
          Symbols</span
        ></span
      >
      <router-link :to="{ name: 'symbols' }" v-else class="menu-btn-active"
        ><b-icon-view-list :scale="1" /><span class="large-screen">
          Symbols</span
        ></router-link
      >
      <span v-if="currentPage == 'trades'" class="menu-btn-selected"
        ><b-icon-collection :scale="1.2" /><span class="large-screen">
          Trades</span
        ></span
      >
      <router-link :to="{ name: 'trades' }" v-else class="menu-btn-active"
        ><b-icon-collection :scale="1" /><span class="large-screen">
          Trades</span
        ></router-link
      >
      <span v-if="currentPage == 'activity'" class="menu-btn-selected"
        ><b-icon-flag :scale="1.2" /><span class="large-screen">
          Activity</span
        ></span
      >
      <router-link :to="{ name: 'activity' }" v-else class="menu-btn-active"
        ><b-icon-flag :scale="1" /><span class="large-screen">
          Activity</span
        ></router-link
      >
    </div>
    <div class="group">
      <span v-if="currentPage == 'pages'" class="menu-btn-selected"
        ><b-icon-book :scale="1.2" /><span class="large-screen">
          Pages</span
        ></span
      >
      <router-link :to="{ name: 'pages' }" v-else class="menu-btn-active"
        ><b-icon-book :scale="1" /><span class="large-screen">
          Pages</span
        ></router-link
      >
      <span v-if="currentPage == 'account'" class="menu-btn-selected"
        ><b-icon-bar-chart :scale="1.2" /><span class="large-screen">
          Account</span
        ></span
      >
      <router-link :to="{ name: 'account' }" v-else class="menu-btn-active"
        ><b-icon-bar-chart :scale="1" /><span class="large-screen">
          Account</span
        ></router-link
      >
      <span v-if="currentPage == 'settings'" class="menu-btn-selected"
        ><b-icon-gear :scale="1.2" /><span class="large-screen">
          Settings</span
        ></span
      >
      <router-link :to="{ name: 'settings' }" v-else class="menu-btn-active"
        ><b-icon-gear :scale="1" /><span class="large-screen">
          Settings</span
        ></router-link
      >
      <a @click.prevent="logOut" href="#" class="menu-btn-active"
        ><b-icon-box-arrow-right :scale="1" /><span class="large-screen">
          Logout</span
        ></a
      >
    </div>
  </div>
</template>

<script>
import Logo from "./lib/Logo";

export default {
  name: "MainNav",
  components: {
    Logo,
  },
  computed: {
    notificationsUnseen: function() {
      return this.$store.state.notifications.unseen;
    },
    currentPage: function() {
      return this.$store.state.pages.name;
    },
    loggedIn: function() {
      return this.$store.state.auth.loggedIn;
    },
  },
  methods: {
    async logOut() {
      const response = await this.$http.get("/api/logout");
      localStorage.clear();
      this.$store.dispatch("LOGOUT");
      this.$router.push({ name: "login" }, () => {});
    },
  },
};
</script>
