<template>
  <b-form-invalid-feedback :state="false" v-if="errors && errors.length">
    {{ errors.join(" ") }}
  </b-form-invalid-feedback>
</template>

<script>
import { BFormInvalidFeedback } from "bootstrap-vue";

export default {
  name: "InputErrors",
  props: {
    errors: Array,
  },
  components: {
    BFormInvalidFeedback,
  },
};
</script>

<style></style>
