<template>
  <div class="list-row">
    <div class="list-col">
      <spinner v-if="isLoading" />
      <nothing-found v-if="!isLoading && !activities.length" />
      <div class="table-wrapper" v-if="activities.length">
        <b-table
          v-if="activities"
          ref="table"
          id="activities-table"
          :fields="tableFields"
          :items="activities"
          stacked="sm"
          striped
          small
        >
          <template #cell(log_id)="data">
            <timestamp :ts="data.item.ts" class="timestamp">
              <template v-slot:default="slotProps">
                {{ slotProps.val.ago }}
                <div class="dt">{{ slotProps.val.dt }}</div>
              </template>
            </timestamp>
          </template>
          <template #cell(data)="data">
            <component
              v-bind:is="getActivityComponent(data.item.event)"
              :data="data.item"
              v-on:updateNote="onNoteUpdate"
              v-on:removeAlert="onRemoveAlert"
            />
          </template>
        </b-table>
      </div>
      <div class="pagination-row">
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
        ></b-pagination>
      </div>
    </div>
    <div class="filters-col">
      <div class="header-row">
        <div class="header-content">Activities</div>
        <plain-selector :options="sourceOptions" v-model="source" />
      </div>
      <div class="filters-content">
        <b-form-group
          label="Search"
          label-for="i-search"
          v-if="source == 'notes'"
        >
          <b-form-input
            v-model="query"
            placeholder="Search"
            id="i-search"
            autocomplete="off"
          ></b-form-input>
        </b-form-group>

        <div class="mt-2">Found {{ total }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { BTable, BPagination, BFormGroup, BFormInput } from "bootstrap-vue";
import Timestamp from "../components/lib/Timestamp";
import PlainSelector from "../components/lib/PlainSelector";
import getActivityComponent from "../components/activities";
import Spinner from "../components/lib/Spinner";
import NothingFound from "../components/lib/NothingFound";

export default {
  name: "Activity",
  components: {
    BTable,
    BPagination,
    Timestamp,
    PlainSelector,
    BFormInput,
    BFormGroup,
    Spinner,
    NothingFound,
  },
  data: function() {
    return {
      logs: [],
      notes: [],
      alerts: [],
      totalLogs: null,
      totalNotes: null,
      totalAlerts: null,
      perPage: 20,
      currentPage: 1,
      sourceOptions: [
        { slug: "logs", title: "Logs" },
        { slug: "notes", title: "Notes" },
        { slug: "alerts", title: "Alerts" },
      ],
      source: "logs",
      query: null,
      searchTimer: null,
      isLoading: false,
    };
  },
  computed: {
    tableFields: function() {
      if (this.source == "alerts") {
        return [
          { key: "log_id", label: "Time" },
          { key: "data", label: "Alert" },
        ];
      }
      return [
        { key: "log_id", label: "Time" },
        { key: "data", label: "Activity" },
      ];
    },
    activities: function() {
      if (this.source == "logs") {
        return this.logs;
      } else if (this.source == "notes") {
        return (
          this.notes && [
            ...this.notes.map((i) => {
              return { ...i, event: "note" };
            }),
          ]
        );
      } else if (this.source == "alerts") {
        return this.alerts;
      }
    },
    total: function() {
      if (this.source == "logs") {
        return this.totalLogs;
      } else if (this.source == "notes") {
        return this.totalNotes;
      } else if (this.source == "alerts") {
        return this.totalAlerts;
      } else {
        return 0;
      }
    },
  },
  watch: {
    currentPage: function(oldValue, newValue) {
      this.reload();
    },
    source: function(oldValue, newValue) {
      if (this.currentPage == 1) {
        this.reload();
      } else {
        this.currentPage = 1;
      }
    },
    query: function(oldValue, newValue) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(() => {
        if (this.source == "notes") {
          this.reloadNotes();
        }
      }, 800);
    },
  },
  methods: {
    reloadLogs: function() {
      this.isLoading = true;
      this.$http
        .post("/api/logs", {
          per_page: this.perPage,
          page: this.currentPage,
          events: ["o_filled", "t_done", "t_error", "a_price"],
        })
        .then(
          function(response) {
            this.logs = response.data.results;
            this.totalLogs = response.data.total;
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        )
        .finally(
          function() {
            this.isLoading = false;
          }.bind(this)
        );
    },
    reloadNotes: function() {
      this.isLoading = true;
      this.$http
        .request({
          url: "/api/notes",
          method: "get",
          params: {
            per_page: this.perPage,
            page: this.currentPage,
            query: this.query || null,
          },
        })
        .then(
          function(response) {
            this.notes = response.data.results;
            this.totalNotes = response.data.total;
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        )
        .finally(
          function() {
            this.isLoading = false;
          }.bind(this)
        );
    },
    reloadAlerts: function() {
      this.isLoading = true;
      this.$http
        .get(`/api/alerts?per_page=${this.perPage}&page=${this.currentPage}`)
        .then(
          function(response) {
            this.alerts = response.data.results.map((i) => {
              return {
                ...i,
                event: "alert",
              };
            });
            this.totalAlerts = response.data.total;
          }.bind(this)
        )
        .catch(
          function(reason) {
            console.log(reason);
          }.bind(this)
        )
        .finally(
          function() {
            this.isLoading = false;
          }.bind(this)
        );
    },
    reload: function() {
      if (this.source == "logs") {
        this.reloadLogs();
      } else if (this.source == "notes") {
        this.reloadNotes();
      } else if (this.source == "alerts") {
        this.reloadAlerts();
      }
    },
    getActivityComponent(event) {
      return getActivityComponent(event);
    },
    onNoteUpdate: function(newNote) {
      if (newNote.text.length) {
        this.notes = [
          ...this.notes.map((i) => {
            if (newNote.trade_token && newNote.trade_token == i.trade_token) {
              return {
                ...i,
                text: newNote.text,
                ts: new Date().getTime(),
              };
            } else if (
              newNote.symbol_slug &&
              newNote.symbol_slug == i.symbol_slug
            ) {
              return {
                ...i,
                text: newNote.text,
                ts: new Date().getTime(),
              };
            } else {
              return {
                ...i,
              };
            }
          }),
        ];
      } else {
        this.notes = [
          ...this.notes.filter(
            (i) =>
              i.trade_token != newNote.trade_token &&
              i.symbol_slug != newNote.symbol_slug
          ),
        ];
      }
    },
    onRemoveAlert: function(alertId) {
      this.alerts = [...this.alerts.filter((i) => i.alert_id != alertId)];
    },
  },
  mounted: function() {
    this.reload();
  },
};
</script>

<style></style>
